import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photo-approved-requirement',
  templateUrl: './photo-approved-requirement.component.html',
  styleUrls: ['./photo-approved-requirement.component.css']
})
export class PhotoApprovedRequirementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
