import { Component, OnInit, Inject } from '@angular/core';
import { Profile00Component } from '../profile00.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  review: any,
  // last_name: string,
  // id: number,
  // review_ratings: number,
  // review_comments: string,
  // created_at: any,
  // updated_at: any
}


@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.css']
})
export class ReviewModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
  }

  onClick(): void {
    this.dialogRef.close();
  }

  onRate($event: { oldValue: number, newValue: number}, dataId: number){
    console.log($event);
    console.log("dataId == ", dataId);
    this.data.review.review_ratings = $event.newValue;
  }


}
