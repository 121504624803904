import { Component, OnInit } from '@angular/core';
import { DataSharingService } from 'src/app/services/data.sharing.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-menu-dk',
  templateUrl: './menu-dk.component.html',
  styleUrls: ['../menu/menu.component.css']
})
export class MenuDkComponent implements OnInit {

  public isMenuOpen: boolean = false;
  public isLoggedIn: boolean = false;
  public isUserCustomer:boolean = false;

  constructor(
    public auth: AuthenticationService,
    private dataSharingService: DataSharingService
  ) {
    // Subscribe here, this will automatically update 
        // "isUserLoggedIn" whenever a change to the subject is made.
        this.dataSharingService.isUserLoggedIn.subscribe( value => {
          this.isLoggedIn = value;
      });
  }

  ngOnInit() {
    this.isLoggedIn = this.auth.isUserLoggedIn();
    this.isUserCustomer = this.auth.isUserCustomer();
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  clickMeet(){
    localStorage.setItem('isMeetHelperClick','true');
  }
}

