import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ngb-modal';

@Component({
  selector: 'app-get-helper',
  templateUrl: './get-helper.component.html',
  styleUrls: ['./get-helper.component.css']
})
export class GetHelperComponent implements OnInit {  

  @ViewChild(ModalComponent, {static:false}) ModalComponent;  
  constructor() { }

  ngOnInit() {
  }

}
