
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService, GoogleLoginProvider } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { ValidationService } from '../../../services/validation.service';
import { Constant } from '../../../settings/constants';
import { ApiService } from '../../../services/api.service';
import { AppComponent } from '../../../app.component';
import { PagerService } from 'src/app/services/pagination.service';
import { MatStepper } from '@angular/material/stepper/typings/public-api';
import { trigger, state, style, transition, group, animate } from '@angular/animations';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { duration } from 'moment';
import { moment } from 'fullcalendar';
import { DataSharingService } from 'src/app/services/data.sharing.service';

declare var $: any;
declare function popTwitterSignIn(outhToken, callback): any;
declare function paymentWindow(string: any): any;

@Component({
  selector: 'app-ordre',
  templateUrl: './ordre.component.html',
  styleUrls: ['./ordre.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([
          animate(50, style({ height: 0 })),
          animate('50ms ease-in-out', style({ 'opacity': '0' }))
        ])

      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([
          animate(50, style({ height: '*' })),
          animate('50ms ease-in-out', style({ 'opacity': '1' }))
        ])

      ])
    ])
  ],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: false }
  }]
})
export class OrdreComponent implements OnInit {
  public date = new Date();
  public conditionFormGroup: FormGroup;
  public addressFormGroup: FormGroup;
  public passFormGroup: FormGroup;
  public invoiceAddressGroup: FormGroup;
  public hasSocialLogin = false;
  public twitterEmail = '';

  //Social Auth Form Group
  public fbFormGroup: FormGroup;
  public googleFormGroup: FormGroup;
  public twitterFormGroup: FormGroup;

  public areaFormGroup: FormGroup;
  public contractFormGroup: FormGroup;
  public passwordFormGroup: FormGroup;

  public isStep1Disable: boolean = true;
  public isStep2Disable: boolean = true;
  public isStep3Disable: boolean = true;
  public isStepInvoiceAddressDisable: boolean = false;
  public isStep4Disable: boolean = true;
  public isStep5Disable: boolean = false;
  public isStep6Disable: boolean = true;
  public isStep7Disable: boolean = true;
  public isCprDisable: boolean = false;
  public isCvrDisable: boolean = false;
  public cityName: string = '';
  public fbLoginEnable: boolean = true;
  public smsCode: number;
  public postal_code: string = '';
  public areaDate;
  public areaTime = 0;
  public howOftenSelected = 1;
  public selectedBookie;
  public selectedRadioTime = 'Just Once'
  public manyTimes = [
    { "key": "Just Once", "value": 1 },
    { "key": "Every 2 Week", "value": 2 },
    { "key": "Every 4 Week", "value": 3 }
  ];

  public times = [
    { "key": 2.5, "value": 2.5 },
    { "key": 3, "value": 3 },
    { "key": 4, "value": 4 },
    { "key": 5, "value": 5 },
    { "key": 6, "value": 6 },
    { "key": 7, "value": 7 },
    { "key": 8, "value": 8 }
  ];

  public selectedLanguage: string = 'en'
  public regions: any = [];
  public cities: any = [];
  public areas: any = [];
  public selectedAreas: any = [];

  public selectedData: any = {};
  public isBookieSelected = false;
  public items;
  public allHelpers = [];
  public helper: any;
  public selecetdePrice: any;
  public chooseText = 'CHOOSE';
  public isHideModal = true;
  public option = '';
  isSocialRegistration = false;
  paymentModel: any = {
    customer_id: "",
    helper_id: "",
    cleaning_date: "",
    cleaning_duration: "",
    cleaning_time: "",
    cleaning_frequency: "",
    cleaning_amount: "",
    cleaning_service_fee: "",
    cleaning_admin_fee: "",
    discount_amount: "",
    booking_status: "",
    transaction_id: "",
    order_id: ""
  }
  p: number = 1;
  constructor(
    private _formBuilder: FormBuilder,
    public translate: TranslateService,
    private validationService: ValidationService,
    private apiService: ApiService,
    private toastr: ToastrService,
    public app: AppComponent,
    private authService: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private route: ActivatedRoute,
    private dataSharingService: DataSharingService
  ) {
    this.route.paramMap.subscribe(paramMap => {
      this.option = paramMap.get('option');
      console.log(this.option);
    })


    translate.addLangs(['en', 'dk']);
    //translate.setDefaultLang('dk');
    const browserLang = translate.getBrowserLang();
    console.log('lang', browserLang);
    translate.use(browserLang.match(/en|dk/) ? browserLang : 'en');
  }

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];
  step2Description: any;
  minDate;
  time;
  endTimeDuration: any;
  startTimeDuration: any;
  isMeetHelperClick: any;
  ngOnDestroy() {
    localStorage.removeItem('isMeetHelperClick');
  }
  ngOnInit() {
    // var roleOfUser = localStorage.getItem('role');
    // if(roleOfUser == 'helper'){
    //   $('#wrongUserModal').modal('show');
    //   return;
    // }
    if (localStorage.getItem('isMeetHelperClick') == 'true') {
      this.isMeetHelperClick = true;
    }
    this.date.setDate(this.date.getDate() + 2);
    this.minDate = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };
    this.getBooking(1, this.p);
    this.createJoinForms();
    var socialLogin = localStorage.getItem("isSocialLogin");
    if (socialLogin == "true") {
      this.hasSocialLogin = true;
    }
    else {
      this.hasSocialLogin = false;
    }

    var selected_helper = localStorage.getItem('selected_bookie');
    if (selected_helper) {
      var bookie = JSON.parse(selected_helper);
      if (bookie) {
        this.selectedBookie = bookie;
        this.setHelperDetail(bookie);
      }
    } else {
      this.selectedBookie = {};
      this.setHelperDetailWhenNothing();
    }

    var passFormGroup = localStorage.getItem('passFormGroup');
    if (passFormGroup) {
      var bio = JSON.parse(passFormGroup);
      if (bio) {
        this.passFormGroup.get('step2Description').patchValue(bio.step2Description);
        this.step2Description = bio.step2Description;
      }
    }

    var address_formgroup = localStorage.getItem("address_group");
    if (address_formgroup) {
      var address = JSON.parse(address_formgroup);
      if (address) {
        this.addressFormGroup.get('acceptedTerms').patchValue(address.acceptedTerms);
        this.addressFormGroup.get('address1').patchValue(address.address1);
        this.addressFormGroup.get('address2').patchValue(address.address2);
        this.addressFormGroup.get('city').patchValue(address.city);
        this.addressFormGroup.get('cvr').patchValue(address.cvr);
        this.addressFormGroup.get('email').patchValue(address.email);
        this.addressFormGroup.get('firstname').patchValue(address.firstname);
        this.addressFormGroup.get('lastname').patchValue('');
        this.addressFormGroup.get('password').patchValue('');
        this.addressFormGroup.get('postalCode').patchValue(address.postalCode);
        this.addressFormGroup.get('way').patchValue(address.way);
        this.addressFormGroup.get('yes18').patchValue(address.yes18);
      }
    }

    var invoiceAddress_formGroup = localStorage.getItem("invoiceAddressFormGroup");
    if (invoiceAddress_formGroup) {
      var invoiceAddressFormGroup = JSON.parse(invoiceAddress_formGroup);
      if (invoiceAddressFormGroup) {
        this.invoiceAddressGroup.get('name').patchValue(invoiceAddressFormGroup.name);
        this.invoiceAddressGroup.get('phone_code').patchValue(invoiceAddressFormGroup.phone_code);
        this.invoiceAddressGroup.get('phone_number').patchValue(invoiceAddressFormGroup.phone_number);
        this.invoiceAddressGroup.get('cvr_number').patchValue(invoiceAddressFormGroup.cvr_number);
        this.invoiceAddressGroup.get('street_address').patchValue(invoiceAddressFormGroup.street_address);
        this.invoiceAddressGroup.get('nr').patchValue(invoiceAddressFormGroup.nr);
        this.invoiceAddressGroup.get('floor').patchValue(invoiceAddressFormGroup.floor);
        this.invoiceAddressGroup.get('postcode').patchValue(invoiceAddressFormGroup.postcode);
        this.invoiceAddressGroup.get('city').patchValue(invoiceAddressFormGroup.city);
        this.invoiceAddressGroup.get('email').patchValue(invoiceAddressFormGroup.email);
      }
    }
  }

  openPaymentWindow() {
    var amount = this.selectedBookie.totalInvoice.toFixed(0).toString();
    paymentWindow(amount);
  }
  logoutWrongUser() {
    localStorage.removeItem('authToken');
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("role");
    localStorage.removeItem("isSocialLogin");
    localStorage.removeItem("selected_bookie");
    localStorage.removeItem("passFormGroup");
    localStorage.removeItem("address_group");
    localStorage.removeItem("condition_group");
    localStorage.removeItem("invoiceAddressFormGroup");
    localStorage.removeItem("selected_areas");
    localStorage.removeItem("selected_helper");
    $('#wrongUserModal').modal('hide');
    this.isSocialRegistration = false;
    location.reload(true);
  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    // get pager object from service
    this.pager = this.pagerService.getPager(this.items.length, page);
  }

  increementTime() {
    this.modalTime = moment(this.modalTime).add(30, "minutes").toDate();
    this.time = moment(this.modalTime).format("HH:mm");
    if (this.time > "08:00") {
      this.time = "08:00";
    }
    else {
      this.endTimeDuration = moment(this.endTimeDuration).add(30, "minutes").toDate();
      this.endTime = moment(this.endTimeDuration).format("HH:mm");
      this.startEndTime = this.startTime + '-' + this.endTime;
    }
  }

  decreementTime() {
    this.modalTime = moment(this.modalTime).subtract(30, "minutes").toDate();
    this.time = moment(this.modalTime).format("HH:mm");
    if (this.time < "02:30") {
      this.modalTime.setHours(2);
      this.modalTime.setMinutes(30);
      this.time = moment(this.modalTime).format("HH:mm");
    }
    else {
      this.endTimeDuration = moment(this.endTimeDuration).subtract(30, "minutes").toDate();
      this.endTime = moment(this.endTimeDuration).format("HH:mm");
      this.startEndTime = this.startTime + '-' + this.endTime;
    }
    if (this.time > "08:00") {
      this.modalTime.setHours(7);
      this.modalTime.setMinutes(30);
      this.time = moment(this.modalTime).format("HH:mm");
    }
  }

  showDuration() {
    this.showTimeValue = !this.showTimeValue;
  }

  showStartEndTimeFun() {
    this.showStartEndTime = !this.showStartEndTime
  }

  howOftenClick() {
    this.ishowOftenClick = !this.ishowOftenClick;
  }

  endLoadHelpers = 6;
  loadAllHelpers = false;
  totalHelpers;
  searchData = [];
  showTimeValue = false;
  showStartEndTime = false
  timeValue; ''
  modalTime: any;
  ishowOftenClick = false;
  firstProfile = {
    HelperBio: "We find a Helper from your local area who we believe is the best for your particular job. At this election, the hourly rate is 180 DKK (about 135 after deduction).",
    HelperReviewCount: 0,
    helperdesc: "We find a Helper from your local area who we believe is the best for your particular job. At this election, the hourly rate is 180 DKK (about 135 after deduction).",
    helperemail: '',
    helperhourlyrate: 180,
    helpername: "We choose for you",
    helperpic: "/assets/images/profil-we-choose.jpg",
    tax: 0,
    userId: 0
  };
  getBooking(page?: number, pageNo?: any) {
    this.p = pageNo;
    console.log("Booking");
    var areaModel: any = JSON.parse(localStorage.getItem("AreaDetails"));
    if (!areaModel) {
      debugger
      areaModel = {};
      areaModel.time = 2.5;
      areaModel.date = this.date;

      if (areaModel.date && areaModel.date.day) {
        // areaModel.date = areaModel.date.month + '-' + areaModel.date.day + '-' + areaModel.date.year;
        areaModel.date = areaModel.date.month + '/' + areaModel.date.day + '/' + areaModel.date.year;
      } else {
        var dt = new Date(areaModel.date);
        var actDate = { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() };
        this.areaDate = actDate;
      }

      if (!this.time) {
        this.time = "02:30"
      }
      let date = new Date(areaModel.date);
      let time = this.time.split(":");
      date.setHours(time[0]);
      date.setMinutes(time[1]);
      this.modalTime = date;
      this.time = moment(date).format("HH:mm");


      let startTime = new Date(areaModel.date);
      let starttimeSplit = this.startTime.split(":");
      startTime.setHours(starttimeSplit[0]);
      startTime.setMinutes(starttimeSplit[1]);
      this.startTimeDuration = startTime;

      let endTime = new Date(areaModel.date);
      this.endTimeDuration = endTime;
      this.endTimeDuration = moment(this.startTimeDuration).add(time[0], "hours").toDate();
      this.endTimeDuration.setMinutes(time[1]);
      this.endTime = moment(this.endTimeDuration).format("HH:mm");

      var datePipe = new DatePipe('en-US');
      const cleanFormattedDate = datePipe.transform(areaModel.date, 'EEE dd/LL');
      var dateInDanish = cleanFormattedDate;
      debugger
      this.selectedData.postal_code = areaModel.postal_code;
      this.selectedData.date = dateInDanish;

    }
    this.selectedData.postal_code = '';

    // this.selectedData.date = '';
    this.selectedData.time = '2t 30m';
    this.selectedData.pointtime = this.startEndTime;
    localStorage.setItem("pointtime", this.selectedData.pointtime);
    var howSelected = this.howOftenSelected;
    this.areaTime = 2.5;
    this.selectedData.howoften = this.manyTimes.filter(function (often) {
      if (often.value == howSelected) {
        localStorage.setItem("frequency", often.value.toString());

        return often;
      }
    });

    if (areaModel.time == 2.5) {
      this.time = "2:30";
      this.timeValue = "2:30";
    } else if (areaModel.time == 3) {
      this.time = "3:00";
      this.timeValue = "3:00";
    }
    else if (areaModel.time == 4) {
      this.time = "4:00";
      this.timeValue = "4:00";
    }
    else if (areaModel.time == 5) {
      this.time = "5:00";
      this.timeValue = "5:00";
    }
    else if (areaModel.time == 6) {
      this.time = "6:00";
      this.timeValue = "6:00";
    }
    else if (areaModel.time == 7) {
      this.time = "7:00";
      this.timeValue = "7:00";
    }
    else if (areaModel.time == 8) {
      this.time = "8:00";
      this.timeValue = "8:00";
    }
    else {
      this.time = areaModel.time.replace(".", ":");
      this.timeValue = areaModel.time.replace(".", ":");
    }

    if (areaModel && areaModel.postal_code) {
      areaModel.limit = 10;

      this.postal_code = areaModel.postal_code;
      this.areaDate = areaModel.date;
      this.areaTime = areaModel.time;

      if (areaModel.date && areaModel.date.day) {
        // areaModel.date = areaModel.date.month + '-' + areaModel.date.day + '-' + areaModel.date.year;
        areaModel.date = areaModel.date.month + '/' + areaModel.date.day + '/' + areaModel.date.year;
      } else {
        var dt = new Date(areaModel.date);
        var actDate = { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() };
        this.areaDate = actDate;
      }



      let date = new Date(areaModel.date);
      let time = this.time.split(":");
      date.setHours(time[0]);
      date.setMinutes(time[1]);
      this.modalTime = date;
      this.time = moment(date).format("HH:mm");


      let startTime = new Date(areaModel.date);
      let starttimeSplit = this.startTime.split(":");
      startTime.setHours(starttimeSplit[0]);
      startTime.setMinutes(starttimeSplit[1]);
      this.startTimeDuration = startTime;

      let endTime = new Date(areaModel.date);
      this.endTimeDuration = endTime;
      this.endTimeDuration = moment(this.startTimeDuration).add(time[0], "hours").toDate();
      this.endTimeDuration.setMinutes(time[1]);
      this.endTime = moment(this.endTimeDuration).format("HH:mm");

      var datePipe = new DatePipe('en-US');
      const cleanFormattedDate = datePipe.transform(areaModel.date, 'EEE dd/LL');
      var dateInDanish = cleanFormattedDate;
      if (this.isMeetHelperClick != true) {
      this.selectedData.postal_code = areaModel.postal_code;
    }
      this.selectedData.date = dateInDanish;

      this.selectedData.time = time[0] + 't ' + time[1] + 'm';
      this.selectedData.pointtime = this.startEndTime;
      var howSelected = this.howOftenSelected;
      this.selectedData.howoften = this.manyTimes.filter(function (often) {
        if (often.value == howSelected) {
          return often;
        }
      })

      if (page) {
        areaModel.page = page;
      }

      this.searchData = [];
      this.searchData['postcode'] = areaModel.postal_code;
      this.searchData['date'] = areaModel.date;
      this.searchData['time'] = areaModel.time;
      this.searchData['startTime'] = this.startTime;
      this.searchData['endTime'] = this.endTime;
      this.searchData['often'] = this.howOftenSelected;


      var url = Constant.SLUGS['getAvailableHelpersFromPostcode'] + '?page=' + page;
      if (this.searchData['postcode'] != '') {
        url += "&postcode=" + this.searchData['postcode'];
      }
      if (this.isMeetHelperClick == true) {
        url += "&postcode=";
      }
      if (this.searchData['date'] != "") {
        url += "&date=" + this.searchData['date'];
      }
      if (this.startTime) {
        url += "&starttime=" + this.startTime;
      }
      if (this.endTime != "") {
        url += "&endtime=" + this.endTime;
      }
      this.apiService.getData(url).then(
        items => {
          if (items['resCode'] == 200) {
            this.items = items['data']
            this.items.unshift(this.firstProfile);
            this.allHelpers = items['data'];
            this.setPage(areaModel.page);
            this.loadData();
          }
          else {
            //this.toastr.error(items['resMsg'], 'Error');
            this.helper = "Unfortunately, there are no helpers available. Try if necessary. another date.";
            this.items = [];
            this.allHelpers = [];
            this.loadAllHelpers = true;
            $('.navigation_bottom').hide();
          }
        });
    } else {
      url = Constant.SLUGS['getAvailableHelpersFromPostcode'];
      this.apiService.getData(url).then(
        items => {
          if (items['resCode'] == 200) {
            this.items = items['data']
            this.items.unshift(this.firstProfile);
            this.allHelpers = items['data'];
            this.setPage(areaModel.page);
            this.loadData();
          }
          else {
            this.toastr.error(items['resMsg'], 'Error');
            this.items = [];
            this.allHelpers = [];
            this.loadAllHelpers = true;
            $('.navigation_bottom').hide();
          }
        });
      // this.loadAllHelpers = true;
      // this.items = [];
      // this.allHelpers = [];
    }
  }

  search = '';
  assignCopy() {
    this.items = Object.assign([], this.allHelpers);
  }
  filterItem(value) {
    if (!value) {
      this.assignCopy();
    } // when nothing has typed
    this.items = Object.assign([], this.allHelpers).filter(
      item => item.helpername.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
    this.loadData();
  }

  loadData() {
    this.endLoadHelpers = 6;
    this.loadAllHelpers = false;
    this.totalHelpers = this.items.length;
    if (this.totalHelpers > 0) {
      $('.navigation_bottom').show();
    }
    if (this.totalHelpers == this.endLoadHelpers || this.totalHelpers == 0) {
      this.loadAllHelpers = true;
      if (this.totalHelpers == 0) {
        $('.navigation_bottom').hide();
      } else {
        $('.navigation_bottom').show();
      }
    } else if (this.totalHelpers < this.endLoadHelpers) {
      this.loadAllHelpers = true;
    }
    else {
      $('.navigation_bottom').show();
    }
    this.getBookie(this.items);
  }

  loadMoreHelpers() {
    if (this.totalHelpers >= this.endLoadHelpers) {
      this.endLoadHelpers += 6;
    } else {
      this.loadAllHelpers = true;
    }
  }

  setHelperDetailWhenNothing() {
    this.selectedBookie.serviceFee = 0;
    this.selectedBookie.paymentToUser = 0;
    this.selectedBookie.serviceFeePrice = 0;
    this.selectedBookie.VAT = 0;
    this.selectedBookie.administrationFee = 0;
    this.selectedBookie.totalServiceHelperInvoice = 0;
    this.selectedBookie.totalInvoice = 0;
    this.selectedBookie.duration = '-';
    // this.isBookieSelected = true;
  }
  setHelperDetail(valueItem) {
    valueItem.isBookieSelected = true;
    valueItem.chooseText = 'SELECTED';
    this.selectedBookie = valueItem;
    if (this.howOftenSelected == 1) {
      this.selectedBookie.serviceFee = 25;
    }
    else if (this.howOftenSelected == 2) {
      this.selectedBookie.serviceFee = 15;
    }
    else if (this.howOftenSelected == 3) {
      this.selectedBookie.serviceFee = 10;
    }

    this.selectedBookie.paymentToUser = valueItem.helperhourlyrate * this.areaTime;
    this.selectedBookie.serviceFeePrice = (this.selectedBookie.paymentToUser * this.selectedBookie.serviceFee) / 100;
    this.selectedBookie.VAT = (this.selectedBookie.serviceFeePrice * 25) / 100;
    this.selectedBookie.administrationFee = 5;
    this.selectedBookie.totalServiceHelperInvoice = this.selectedBookie.serviceFeePrice + this.selectedBookie.administrationFee;
    this.selectedBookie.totalInvoice = this.selectedBookie.paymentToUser + this.selectedBookie.totalServiceHelperInvoice;
    if (this.areaTime == 2.5) {
      this.selectedBookie.duration = '2t 30m';
    }
    else if (this.areaTime == 3) {
      this.selectedBookie.duration = '3t 00m';
    }
    else if (this.areaTime == 4) {
      this.selectedBookie.duration = '4t 00m';
    }
    else if (this.areaTime == 5) {
      this.selectedBookie.duration = '5t 00m';
    }
    else if (this.areaTime == 6) {
      this.selectedBookie.duration = '6t 00m';
    }
    else if (this.areaTime == 7) {
      this.selectedBookie.duration = '7t 00m';
    }
    else if (this.areaTime == 8) {
      this.selectedBookie.duration = '8t 00m';
    } else {
      var time = this.time.split(":");
      this.selectedBookie.duration = time[0] + 't ' + time[1] + 'm';
    }

    var deduction_perc_value = ((this.selectedBookie.paymentToUser * 25) / 100);// + this.selectedBookie.totalServiceHelperInvoice;

    this.selectedBookie.deduction_rate = this.selectedBookie.totalInvoice - deduction_perc_value;
  }

  /**
   * name
   */
  chooseBookie(item, isHideModal = true) {
    this.selecetdePrice = item.helperhourlyrate;
    this.isHideModal = isHideModal;
    var setLocal = JSON.stringify(item);

    localStorage.setItem("selected_bookie", setLocal);
    for (var i = 0; i < this.items.length; i++) {
      var valueItem = this.items[i];
      if (item.helperemail == valueItem.helperemail) {
        this.setHelperDetail(valueItem);
      }
      else {
        valueItem.isBookieSelected = false;
        if (valueItem.helpername == "We choose for you") {
          valueItem.chooseText = valueItem.helpername;
        } else {
          valueItem.chooseText = 'CHOOSE ' + valueItem.helpername;
        }

      }
    }
    this.isBookieSelected = true;
    return false;
  }

  totalHelperArea;
  bookieDetail(item) {
    this.helper = {};
    if (item.helpername == "We choose for you") {
      this.helper = {
        Choosed_helper_area: [],
        avgRating: 0,
        deduction_rate: '135.00 kr',
        firstname: 'We choose for you',
        helper_areas: '',
        hourly_rate: 180,
        lastname: '',
        user_bio: "We find a Helper from your local area who we believe is the best for your particular job. At this election, the hourly rate is 180 DKK (about 135 after deduction).",
        helperpic: item.helperpic,
        chooseText: 'SELECTED'
      }

      this.allDataLoaded = true;
      this.chooseBookie(item);
      this.helper.deduction_rate = 135;
    }
    else {
      var url = "get-only-helper-info?id=" + item.userId;
      this.apiService.getData(url).then(
        bookieDetail => {
          if (bookieDetail['resCode'] == 200) {
            this.helper = bookieDetail["data"].helperInfo;
            this.helper.helperpic = item.helperpic;
            this.helper.chooseText = 'SELECTED'
            this.helper.deduction_rate = this.helper.hourly_rate - ((this.helper.hourly_rate * 25) / 100);
            this.totalHelperArea = this.helper.Choosed_helper_area.length;
            this.endLoad = 3;
            if (this.totalHelperArea == this.endLoad || this.totalHelperArea == 0) {
              this.allDataLoaded = true;
            } else {
              this.allDataLoaded = false;
            }
            this.chooseBookie(item);
          }
          else {
            this.toastr.error(bookieDetail['resMsg'], 'Error');
          }

        });
    }
  }

  allDataLoaded = false;
  endLoad = 3;
  loadMoreArea() {
    this.endLoad = this.totalHelperArea;
    this.allDataLoaded = true;
    // if (this.totalHelperArea > this.endLoad) {
    //   this.endLoad += 3;
    // } else {
    //   this.allDataLoaded = true;
    // }
  }

  getBookie(items) {
    for (var i = 0; i < items.length; i++) {
      var valueItem = items[i];
      if (i == 0) {
        valueItem.isBookieSelected = true;
        this.isBookieSelected = true;
        valueItem.chooseText = 'SELECTED';
        this.selectedBookie = valueItem;
        this.selecetdePrice = valueItem.helperhourlyrate;
        if (this.howOftenSelected == 1) {
          this.selectedBookie.serviceFee = 25;
        }
        else if (this.howOftenSelected == 2) {
          this.selectedBookie.serviceFee = 15;
        }
        else if (this.howOftenSelected == 3) {
          this.selectedBookie.serviceFee = 10;
        }

        this.selectedBookie.paymentToUser = valueItem.helperhourlyrate * this.areaTime;
        this.selectedBookie.serviceFeePrice = (this.selectedBookie.paymentToUser * this.selectedBookie.serviceFee) / 100;
        this.selectedBookie.VAT = (this.selectedBookie.serviceFeePrice * 25) / 100;
        this.selectedBookie.administrationFee = 5;
        this.selectedBookie.totalServiceHelperInvoice = this.selectedBookie.serviceFeePrice + this.selectedBookie.administrationFee;
        this.selectedBookie.totalInvoice = this.selectedBookie.paymentToUser + this.selectedBookie.totalServiceHelperInvoice;
        if (this.areaTime == 2.5) {
          this.selectedBookie.duration = '2t 30m';
        }
        else if (this.areaTime == 3) {
          this.selectedBookie.duration = '3t 00m';
        }
        else if (this.areaTime == 4) {
          this.selectedBookie.duration = '4t 00m';
        }
        else if (this.areaTime == 5) {
          this.selectedBookie.duration = '5t 00m';
        }
        else if (this.areaTime == 6) {
          this.selectedBookie.duration = '6t 00m';
        }
        else if (this.areaTime == 7) {
          this.selectedBookie.duration = '7t 00m';
        }
        else if (this.areaTime == 8) {
          this.selectedBookie.duration = '8t 00m';
        } else {
          var time = this.time.split(":");
          this.selectedBookie.duration = time[0] + 't ' + time[1] + 'm';
        }
        this.isBookieSelected = true;

        var deduction_perc_value = ((this.selectedBookie.paymentToUser * 25) / 100); //+ this.selectedBookie.totalServiceHelperInvoice;

        this.selectedBookie.deduction_rate = this.selectedBookie.totalInvoice - deduction_perc_value;
      }
      else {
        valueItem.isBookieSelected = false;
        if (valueItem.helpername == "We choose for you") {
          valueItem.chooseText = valueItem.helpername;
        } else {
          valueItem.chooseText = 'CHOOSE ' + valueItem.helpername;
        }

      }
    }

    return false;
  }

  /**
   * Create join stepper forms
   */
  createJoinForms(): void {
    // Join step 1 form
    this.conditionFormGroup = this._formBuilder.group({
      haveCprNumber: [false, [Validators.required]],
      haveDanishBankAc: [false, [Validators.required]],
      havePermission: [false, [Validators.required]],
      haveCleanRecord: [false, [Validators.required]],
      searchname: ['']
    });

    this.invoiceAddressGroup = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      phone_code: ['+45'],
      phone_number: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      cvr_number: ['', [Validators.minLength(8), Validators.maxLength(8)]],
      street_address: ['', [Validators.required, Validators.minLength(3)]],
      nr: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(4), Validators.pattern('^[a-zA-Z0-9]*$')]],
      floor: ['', [Validators.minLength(1), Validators.maxLength(20)]],
      postcode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      city: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, this.validationService.emailValidator, Validators.maxLength(100)]],
    })

    // Join step 3 form
    this.addressFormGroup = this._formBuilder.group({
      yes18: [false, [Validators.required]],
      postalCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      city: ['', [Validators.required, Validators.minLength(3)]],
      phone_code: ['+45'],
      phone: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30), this.validationService.passwordCriteria()]],
      confirmPassword: ['', [Validators.minLength(8), Validators.maxLength(30)]],
      acceptedTerms: [false, [Validators.required]],
      firstname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      lastname: [''],
      email: ['', [Validators.required, this.validationService.emailValidator, Validators.maxLength(100)]],
      way: ['', [Validators.required, Validators.minLength(5)]],
      address1: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(4), Validators.pattern('^[a-zA-Z0-9]*$')]],
      address2: ['', [Validators.minLength(1), Validators.maxLength(20)]],
      cvr: ['', [Validators.minLength(8), Validators.maxLength(8)]],
    }, {
      validator: this.validationService.matchValue('password', 'confirmPassword')
    });

    this.passwordFormGroup = this._formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30), this.validationService.passwordCriteria()]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]],
      acceptedTerms: [false, [Validators.required]],
    }, {
      validator: this.validationService.matchValue('password', 'confirmPassword')
    });



    // Join step 4 form
    this.passFormGroup = this._formBuilder.group({
      step2Description: [''],
    });
    // Join step 5 form
    this.fbFormGroup = this._formBuilder.group({
      fbUserName: [''],
      fbUserId: [''],
      fbAuthToken: [''],
      fbEmail: [''],
      fbFirstName: [''],
      fbLastName: [''],
      fbPhotoURL: ['']
    });

    this.googleFormGroup = this._formBuilder.group({
      googleUserName: [''],
      googleUserId: [''],
      googleAuthToken: [''],
      googleEmail: [''],
      googleFirstName: [''],
      googleLastName: [''],
      googlePhotoURL: ['']
    });

    this.twitterFormGroup = this._formBuilder.group({
      twitterUserName: [''],
      twitterUserId: [''],
      twitterAuthToken: [''],
      twitterEmail: [''],
      twitterFirstName: [''],
      twitterLastName: [''],
      twitterPhotoURL: ['']
    });

    // Join step 6 form
    this.areaFormGroup = this._formBuilder.group({
      region: ['', [Validators.required]],
      regionCity: ['', [Validators.required]]
    });
    this.contractFormGroup = this._formBuilder.group({
      signedContract: [false, [Validators.required]],
      smsCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
    // Get regions
    this.getRegion();
  }

  /**
   * If all checkboxes are checked so next button will be enabled
   */
  checkValue(): void {
    if (this.conditionFormGroup.get('yes18').value && this.conditionFormGroup.get('haveCprNumber').value && this.conditionFormGroup.get('haveDanishBankAc').value && this.conditionFormGroup.get('havePermission').value && this.conditionFormGroup.get('haveCleanRecord').value && this.conditionFormGroup.get('acceptedTerms').value) {
      this.isStep1Disable = false;
    } else {
      this.isStep1Disable = true;
    }
  }

  /**
   * Check personal info is vaid or not
   */
  personalInfoChange(fieldName: string): void {
    let isEmailValid = true;
    // Check email is existing or not
    if (fieldName == 'email') {
      this.app.spinner.show();
      let email = this.addressFormGroup.get('email').value;
      this.apiService.getData(Constant.SLUGS['isEmailExist'] + '/' + email).then(
        response => {
          this.app.spinner.hide();
          if (response['resCode'] == 200) {
            isEmailValid = false;
            document.getElementsByName('email')[0].parentElement.style.border = '2px solid red';
            document.getElementsByName('email')[0].parentElement.style.borderRadius = '5px';
            this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          }
        },
        err => {
          this.app.spinner.hide();
          console.log('email checking err', err)
        }
      );
    }
  }

  /**
   * Check address info is vaid or not
   */
  addressInfoChange(fieldName: string): void {
    this.validationService.setForm(this.addressFormGroup);
    if (this.validationService.validate()) {
      this.isStep3Disable = false;
    } else {
      this.validationService.removeErrors();
      //this.validationService.displayErrors('stepFrom', fieldName);
      this.isStep3Disable = true;
    }
  }

  invoiceAddressCheckValue(fieldName: string): void {
    this.validationService.setForm(this.invoiceAddressGroup);
    if (this.validationService.validate()) {
      this.validationService.removeError(fieldName);
      this.isStepInvoiceAddressDisable = false;
    } else {
      this.validationService.removeErrors();
      if (this.invoiceAddressGroup.get(fieldName).status == "INVALID") {
        this.validationService.displayErrors('customerStepFrom', fieldName);
      } else {
        this.validationService.removeError(fieldName);
      }
      this.isStepInvoiceAddressDisable = true;
    }
  }

  addressCheckValue(fieldName: string): void {
    if (this.isSocialRegistration) {
      this.addressFormGroup.removeControl('email');
      this.addressFormGroup.removeControl('password');

      this.addressFormGroup.removeControl['confirmPassword'];


    }
    this.validationService.setForm(this.addressFormGroup);

    if (this.validationService.validate()) {
      this.validationService.removeError(fieldName);
      if (fieldName == "email") {
        this.personalInfoChange(fieldName);
      }
      if (!this.addressFormGroup.get('acceptedTerms').value) {
        this.isStep3Disable = true;
        return;
      } else {
        this.isStep3Disable = false;
      }
    } else {
      this.validationService.removeErrors();
      if (this.addressFormGroup.get(fieldName).status == "INVALID") {
        this.validationService.displayErrors('customerStepFrom', fieldName);
      } else {
        this.validationService.removeError(fieldName);
      }
      this.isStep3Disable = true;
    }
  }


  passwordCheckValue(): void {
    if (this.passwordFormGroup.get('password').value
      && this.passwordFormGroup.get('confirmPassword').value
      && this.passwordFormGroup.get('acceptedTerms').value
    ) {
      var pass = this.passwordFormGroup.get('password').value;
      var conPass = this.passwordFormGroup.get('confirmPassword').value;
      if (pass != conPass) {
        this.toastr.error('Password and Confirm Password do not match.', 'Password Error!');
        this.isStep4Disable = true;
      } else {
        this.isStep4Disable = false;
      }

    } else {
      this.isStep4Disable = true;
    }
  }

  onConfirmPasswordChange() {

  }

  /**
   * Check password info is vaid or not
   */
  passInfoChange(fieldName: string): void {
    this.validationService.setForm(this.passFormGroup);
    if (this.validationService.validate()) {
      this.isStep4Disable = false;
    } else {
      this.validationService.removeErrors();
      //this.validationService.displayErrors('stepFrom', fieldName);
      this.isStep4Disable = true;
    }
  }

  /**
   * Check password info is vaid or not
   */
  areaInfoChange(fieldName: string): void {
    this.validationService.setForm(this.areaFormGroup);
    if (this.validationService.validate() && this.selectedAreas.length > 0) {
      this.isStep6Disable = false;
    } else {
      this.validationService.removeErrors();
      //this.validationService.displayErrors('stepFrom', fieldName);
      this.isStep6Disable = true;
    }
  }

  /**
   * Sign contract
   * Send 6 digit sms on phone number
   */
  signContract(): void {

    let phoneNumber = this.addressFormGroup.get('phone_code').value + this.addressFormGroup.get('phone').value;
    // Generate random 6 digits code
    let code = Math.floor(100000 + Math.random() * 900000);
    // Temporary set fixed till integrate sms api
    code = 123456;
    let text = code + " is your code. Thank you, Helper Service.";
    let message = {
      recipients: phoneNumber,
      sender: 'Helper Service',
      message: text
    };
    $('#contractModal').modal('show');
    this.smsCode = code;
    // Integrate sms api
    // this.app.spinner.show();
    // let smsApi = Constant.SMS_API + Constant.SMS_API_KEY;
    // this.apiService.postExternalApi(smsApi, message).then(
    //   response => {
    //     this.app.spinner.hide();
    //     console.log('sms api response', response)
    //   },
    //   err => {
    //     this.app.spinner.hide();
    //     this.contractFormGroup.controls['signedContract'].setValue(false);
    //     this.isStep7Disable = true;
    //     console.log('sms api response', err);
    //     this.toastr.error('Problem in sending the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    //   }
    // );

  }

  /**
   * Resend code on phone number
   */
  resendCode(): void {
    this.contractFormGroup.controls['smsCode'].setValue('');
    // Call to resend code
    this.signContract();
  }

  /**
   * Vefiry sms code which sent to phone number
   */
  verifySMSCode(): void {
    this.validationService.setForm(this.contractFormGroup);
    if (this.validationService.validate()) {
      if (this.smsCode == this.contractFormGroup.get('smsCode').value) {
        this.isStep7Disable = false;
      } else {
        this.isStep7Disable = true;
        document.getElementsByName('smsCode')[0].parentElement.parentElement.style.border = '2px solid #e01e5c';
        document.getElementsByName('smsCode')[0].parentElement.parentElement.style.borderRadius = '5px';
      }
    } else {
      this.validationService.removeErrors();
      //this.validationService.displayErrors('stepFrom', 'smsCode');
      this.isStep7Disable = true;
    }
  }

  /**
   * Close dialog box
   */
  dialogClose(): void {
    // Refresh value
    this.contractFormGroup.controls['signedContract'].setValue(false);
  }

  /**
   * Remove validation errors
   * @param field 
   */
  onChange(field = null): void {
    // disable cvr
    if (field == 'cpr') {
      if (this.addressFormGroup.get('cpr').value != '') {
        this.addressFormGroup.controls['cvr'].clearValidators();
        //this.addressFormGroup.controls['cvr'].setErrors(null);
        this.addressFormGroup.get('cvr').disable();
      } else {
        this.addressFormGroup.get('cvr').enable();
        this.addressFormGroup.controls['cvr'].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]);
        //this.addressFormGroup.controls['cvr'].setErrors({ required: true });
      }
    }
    // disable cpr
    if (field == 'cvr') {
      if (this.addressFormGroup.get('cvr').value != '') {
        this.addressFormGroup.controls['cpr'].clearValidators();
        //this.addressFormGroup.controls['cpr'].setErrors(null);
        this.addressFormGroup.get('cpr').disable();
      } else {
        this.addressFormGroup.get('cpr').enable();
        this.addressFormGroup.controls['cpr'].setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]);
        //this.addressFormGroup.controls['cpr'].setErrors({ required: true });
      }
    }
    this.validationService.removeErrors(field);
  }

  /**
   * Get city name by postal code
   * @param event 
   */
  getCityByPostalCode(event: any): void {
    let postalCode = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getCity'] + '/' + postalCode).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          this.cityName = response['data'];
          this.onChange('city');
        } else {
          this.cityName = '';
          this.isStep3Disable = true;
        }
      },
      err => {
        this.app.spinner.hide();
        this.isStep3Disable = true;
        console.log('get city by postal code err', err)
      }
    );
  }

  /**
   * Get city name by postal code
   * @param event 
   */
  getInvoiceAddressCityByPostalCode(event: any): void {
    let postalCode = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getCity'] + '/' + postalCode).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          var cityName = response['data'];
          this.invoiceAddressGroup.get('city').patchValue(cityName);
        } else {
          this.isStepInvoiceAddressDisable = true;
          this.invoiceAddressGroup.get('city').patchValue('');
        }
      },
      err => {
        this.app.spinner.hide();
        this.isStepInvoiceAddressDisable = true;
        console.log('get city by postal code err', err)
      }
    );
  }

  /**
   * Facebook login
   */
  signInWithFB(matStepper: MatStepper): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (userData) => { // on success
        this.isSocialEmailExists(userData['email'], function (value) {
          if (value) {
            return false;;
          }
        })

        let fbName = userData['name'];
        let fbId = userData['id'];
        this.fbFormGroup.controls['fbUserName'].setValue(fbName);
        this.fbFormGroup.controls['fbUserId'].setValue(fbId);
        this.fbFormGroup.controls['fbAuthToken'].setValue(userData['authToken']);
        this.fbFormGroup.controls['fbEmail'].setValue(userData['email']);
        this.fbFormGroup.controls['fbFirstName'].setValue(userData['firstName']);
        this.fbFormGroup.controls['fbLastName'].setValue(userData['lastName']);
        this.fbFormGroup.controls['fbPhotoURL'].setValue(userData['photoUrl']);

        //20200909
        localStorage.removeItem("userId");
        this.addressFormGroup.controls['yes18'].setValue(false);
        this.addressFormGroup.controls['postalCode'].setValue('');
        this.addressFormGroup.controls['city'].setValue('');
        this.addressFormGroup.controls['phone_code'].setValue('');
        this.addressFormGroup.controls['phone'].setValue('');
        // this.addressFormGroup.controls['password'].setValue('');
        // this.addressFormGroup.controls['confirmPassword'].setValue('');

        this.addressFormGroup.controls['acceptedTerms'].setValue('');
        this.addressFormGroup.controls['firstname'].setValue(userData['firstName']);
        this.addressFormGroup.controls['lastname'].setValue(userData['lastName']);
        //this.addressFormGroup.controls['email'].setValue(userData['email']);

        this.addressFormGroup.controls['way'].setValue('');
        this.addressFormGroup.controls['address1'].setValue('');
        this.addressFormGroup.controls['address2'].setValue('');
        this.addressFormGroup.controls['cvr'].setValue('');
        this.prepareRegistrationData();
        //this.registerUser(true, matStepper);

      }
    );
  }

  twitterStepper: MatStepper;
  signInWithTwitter(matStepper: MatStepper) {

    var oauthToken = '';
    var self = this;
    //To Do to call request_token
    this.apiService.getData('twitter-request-token').then(
      twtRes => {

        this.app.spinner.hide();
        if (twtRes["resCode"] == 200) {

          var twitterData = twtRes["data"];
          oauthToken = twitterData[0].oauth_token;
          popTwitterSignIn(oauthToken, function (response) {
            console.log(response);
            var accessTokenUrl = 'twitter-access-token?oauth_token=' + response.oauth_token + '&oauth_verifier=' + response.oauth_verifier;
            self.apiService.getData(accessTokenUrl).then(
              accessTokenRes => {
                self.app.spinner.hide();
                if (accessTokenRes["resCode"] == 200) {
                  self.twitterFormGroup.controls['twitterUserName'].setValue(accessTokenRes["data"][3].screen_name);
                  self.twitterFormGroup.controls['twitterUserId'].setValue(accessTokenRes["data"][2].user_id);
                  self.twitterFormGroup.controls['twitterAuthToken'].setValue(accessTokenRes["data"][0].oauth_token);
                  self.twitterFormGroup.controls['twitterEmail'].setValue('');
                  self.twitterFormGroup.controls['twitterFirstName'].setValue('');
                  self.twitterFormGroup.controls['twitterLastName'].setValue('');
                  self.twitterFormGroup.controls['twitterPhotoURL'].setValue(accessTokenRes['']);

                  self.twitterStepper = matStepper;

                  //20200909
                  localStorage.removeItem("userId");
                  self.addressFormGroup.controls['yes18'].setValue(false);
                  self.addressFormGroup.controls['postalCode'].setValue('');
                  self.addressFormGroup.controls['city'].setValue('');
                  self.addressFormGroup.controls['phone_code'].setValue('');
                  self.addressFormGroup.controls['phone'].setValue('');

                  self.addressFormGroup.controls['acceptedTerms'].setValue('');
                  self.addressFormGroup.controls['firstname'].setValue(accessTokenRes["data"][3].screen_name);
                  self.addressFormGroup.controls['lastname'].setValue('');

                  self.addressFormGroup.controls['way'].setValue('');
                  self.addressFormGroup.controls['address1'].setValue('');
                  self.addressFormGroup.controls['address2'].setValue('');
                  self.addressFormGroup.controls['cvr'].setValue('');
                  self.prepareRegistrationData();
                  $('#twitterEmailModal').modal('show');

                } else {
                  self.toastr.error('Something went wrong while authenticating your request!', 'Error')
                }
              });
          });
        }
      })
  }

  validateTwitterEmail() {
    if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.twitterEmail)) {
      return true;
    } else {
      this.toastr.error('Please enter valid twitter email', 'Error');
      return false;
    }
  }

  registerTwitterEmail() {
    var isValidEmail = this.validateTwitterEmail();
    if (isValidEmail) {
      this.isSocialEmailExists(this.twitterEmail, function (value) {
        if (value) {
          return false;;
        }
      });

      this.twitterFormGroup.controls['twitterEmail'].setValue(this.twitterEmail);
      $('#twitterEmailModal').modal('hide');
      this.prepareRegistrationData();
    }
  }

  signInWithGoogle(matStepper: MatStepper): void {

    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (userData) => { // on success

        this.isSocialEmailExists(userData['email'], function (value) {
          if (value) {
            return false;;
          }
        });

        // this will return user data from google. What you need is a user token which you will send it to the server
        this.googleFormGroup.controls['googleUserName'].setValue(userData['name']);
        this.googleFormGroup.controls['googleUserId'].setValue(userData['id']);
        this.googleFormGroup.controls['googleAuthToken'].setValue(userData['authToken']);
        this.googleFormGroup.controls['googleEmail'].setValue(userData['email']);
        this.googleFormGroup.controls['googleFirstName'].setValue(userData['firstName']);
        this.googleFormGroup.controls['googleLastName'].setValue(userData['lastName']);
        this.googleFormGroup.controls['googlePhotoURL'].setValue(userData['photoUrl']);

        //20200909
        localStorage.removeItem("userId");
        this.addressFormGroup.controls['yes18'].setValue(false);
        this.addressFormGroup.controls['postalCode'].setValue('');
        this.addressFormGroup.controls['city'].setValue('');
        this.addressFormGroup.controls['phone_code'].setValue('');
        this.addressFormGroup.controls['phone'].setValue('');

        this.addressFormGroup.controls['acceptedTerms'].setValue('');
        this.addressFormGroup.controls['firstname'].setValue(userData['name']);


        this.addressFormGroup.controls['way'].setValue('');
        this.addressFormGroup.controls['address1'].setValue('');
        this.addressFormGroup.controls['address2'].setValue('');
        this.addressFormGroup.controls['cvr'].setValue('');
        this.prepareRegistrationData();
        //this.registerUser(true, matStepper);
      }
    );
  }


  signInWithGoogle1(): void {

    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.authService.authState.subscribe((user) => {

      this.googleFormGroup.controls['googleUserName'].setValue(user['name']);
      this.googleFormGroup.controls['googleUserId'].setValue(user['id']);
      this.googleFormGroup.controls['googleAuthToken'].setValue(user['authToken']);
      this.googleFormGroup.controls['googleEmail'].setValue(user['email']);
      this.googleFormGroup.controls['googleFirstName'].setValue(user['firstName']);
      this.googleFormGroup.controls['googleLastName'].setValue(user['lastName']);
      this.googleFormGroup.controls['googlePhotoURL'].setValue(user['photoUrl']);
    });

  }
  /**
   * Sign out from facebook
   */
  signOutFromFB(): void {
    this.fbFormGroup.controls['fbUserName'].setValue('');
    this.fbFormGroup.controls['fbUserId'].setValue('');
    // Now facebook login enabled
    this.fbLoginEnable = true;
    this.authService.signOut();
  }

  /**
   * Register the user
   */
  registerUser(isSocialUser = false, matStepper: MatStepper) {
    var data = this.prepareRegistrationData();
    this.app.spinner.show();
    this.apiService.postData('customer-register', data).then(
      response => {
        console.log('registration success', response['resMsg']);
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          $('#contractModal').modal('hide');
          if (!isSocialUser) {
            //this.toastr.success(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
            let userName = this.addressFormGroup.get('firstname').value;
            localStorage.setItem("isSocialLogin", "false");

            //this.router.navigateByUrl('/helper/profile00');
          } else {
            localStorage.setItem("isSocialLogin", "true");
          }

          var isInvoiceOnOtherAddress = this.addressFormGroup.get('yes18').value;
          var invoiceName = this.invoiceAddressGroup.get('name').value;
          if (isInvoiceOnOtherAddress && !invoiceName) {
            this.invoiceAddressGroup.get('name').patchValue(this.addressFormGroup.get("firstname").value);
            this.invoiceAddressGroup.get('phone_code').patchValue("+45");
            this.invoiceAddressGroup.get('phone_number').patchValue(this.addressFormGroup.get('phone').value);
            this.invoiceAddressGroup.get('cvr_number').patchValue(this.addressFormGroup.get('cvr').value);
            this.invoiceAddressGroup.get('street_address').patchValue(this.addressFormGroup.get('way').value);
            this.invoiceAddressGroup.get('nr').patchValue(this.addressFormGroup.get('address1').value);
            this.invoiceAddressGroup.get('floor').patchValue(this.addressFormGroup.get('address2').value);
            this.invoiceAddressGroup.get('postcode').patchValue(this.addressFormGroup.get('postalCode').value);
            this.invoiceAddressGroup.get('city').patchValue(this.addressFormGroup.get('city').value);
            this.invoiceAddressGroup.get('email').patchValue(this.addressFormGroup.get('email').value);
          }

          localStorage.setItem('authToken', response['data']['token']);
          localStorage.setItem("userId", response['data']['userId']);
          localStorage.setItem("userName", response['data']['name']);
          localStorage.setItem("role", "client");
          matStepper.selectedIndex = matStepper.selectedIndex + 1;
          this.dataSharingService.isUserLoggedIn.next(true);

        } else {
          this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
        }
      },
      err => {
        console.log('register err', err);
        this.app.spinner.hide();
        this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
      }
    );
  }

  isSocialEmailExist = false;
  isSocialEmailExists(emailAddress, callback) {
    let isEmailValid = true;
    this.isSocialEmailExist = false;
    // Check email is existing or not
    this.app.spinner.show();
    let email = emailAddress;
    this.apiService.getData(Constant.SLUGS['isEmailExist'] + '/' + email).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          isEmailValid = false;
          this.isSocialEmailExist = true;
          ////this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          callback(true);
        } else {
          this.isSocialEmailExist = false;
          callback(false);
        }
      },
      err => {
        this.app.spinner.hide();
        this.isSocialEmailExist = false;
        this.toastr.error("Something went wrong", "Error")
      }
    );
  }

  isInvoiceAddressSelected = false;
  prepareRegistrationData() {

    let cvr, phoneNumber, way, nr, floor;
    let socialUserDetail: any = {};
    var userId = localStorage.getItem("userId");
    if (!userId) {
      userId = "0";
    }

    if (this.fbFormGroup.get('fbUserId').value) {
      socialUserDetail.socialName = this.fbFormGroup.get('fbUserName').value;
      socialUserDetail.socialId = this.fbFormGroup.get('fbUserId').value;
      socialUserDetail.socialToken = this.fbFormGroup.get('fbAuthToken').value;
      socialUserDetail.socialEmail = this.fbFormGroup.get('fbEmail').value;
      socialUserDetail.socialFirstName = this.fbFormGroup.get('fbFirstName').value;
      socialUserDetail.socialLastName = this.fbFormGroup.get('fbLastName').value;
      socialUserDetail.socialPhotoURL = this.fbFormGroup.get('fbPhotoURL').value;
      socialUserDetail.socialTye = "Facebook";
    }
    else if (this.googleFormGroup.get('googleUserId').value) {
      socialUserDetail.socialName = this.googleFormGroup.get('googleUserName').value;
      socialUserDetail.socialId = this.googleFormGroup.get('googleUserId').value;
      socialUserDetail.socialToken = this.googleFormGroup.get('googleAuthToken').value;
      socialUserDetail.socialEmail = this.googleFormGroup.get('googleEmail').value;
      socialUserDetail.socialFirstName = this.googleFormGroup.get('googleFirstName').value;
      socialUserDetail.socialLastName = this.googleFormGroup.get('googleLastName').value;
      socialUserDetail.socialPhotoURL = this.googleFormGroup.get('googlePhotoURL').value;
      socialUserDetail.socialTye = "Google";
    }
    else if (this.twitterFormGroup.get('twitterUserId').value) {
      socialUserDetail.socialName = this.twitterFormGroup.get('twitterUserName').value;
      socialUserDetail.socialId = this.twitterFormGroup.get('twitterUserId').value;
      socialUserDetail.socialToken = this.twitterFormGroup.get('twitterAuthToken').value;
      socialUserDetail.socialEmail = this.twitterFormGroup.get('twitterEmail').value;
      socialUserDetail.socialFirstName = this.twitterFormGroup.get('twitterFirstName').value;
      socialUserDetail.socialLastName = this.twitterFormGroup.get('twitterLastName').value;
      socialUserDetail.socialPhotoURL = this.twitterFormGroup.get('twitterPhotoURL').value;
      socialUserDetail.socialTye = "Twitter";
    }

    this.selectedBookie.postalCode = this.postal_code;
    this.selectedBookie.areaDate = this.areaDate.day + '/' + this.areaDate.month + '/' + this.areaDate.year;
    this.selectedBookie.areaTime = this.areaTime;

    var isInvoiceAddressSelected = this.addressFormGroup.get('yes18').value;
    this.isInvoiceAddressSelected = isInvoiceAddressSelected;
    var invoiceAddressInfo: any = {};
    if (isInvoiceAddressSelected && this.invoiceAddressGroup.get('name').value) {
      invoiceAddressInfo.name = this.invoiceAddressGroup.get('name').value;
      invoiceAddressInfo.phone_number = this.invoiceAddressGroup.get('phone_code').value + this.invoiceAddressGroup.get('phone_number').value;
      invoiceAddressInfo.cvr_number = this.invoiceAddressGroup.get('cvr_number').value;
      invoiceAddressInfo.street_address = this.invoiceAddressGroup.get('street_address').value;
      invoiceAddressInfo.nr = this.invoiceAddressGroup.get('nr').value;
      invoiceAddressInfo.floor = this.invoiceAddressGroup.get('floor').value;
      invoiceAddressInfo.postcode = this.invoiceAddressGroup.get('postcode').value;
      invoiceAddressInfo.city = this.invoiceAddressGroup.get('city').value;
      invoiceAddressInfo.email = this.invoiceAddressGroup.get('email').value;
    }

    cvr = (this.addressFormGroup.get('cvr').value) ? this.addressFormGroup.get('cvr').value : '';
    // Concate phone code
    phoneNumber = this.addressFormGroup.get('phone_code').value + this.addressFormGroup.get('phone').value;



    way = (this.addressFormGroup.get('way').value) ? this.addressFormGroup.get('way').value : '';
    nr = (this.addressFormGroup.get('address1').value) ? this.addressFormGroup.get('address1').value : '';
    floor = (this.addressFormGroup.get('address2').value) ? this.addressFormGroup.get('address2').value : '';
    if (this.fbFormGroup.get('fbUserId').value || this.googleFormGroup.get('googleUserId').value || this.twitterFormGroup.get('twitterUserId').value) {
      this.isSocialRegistration = true;
      socialUserDetail.phone = phoneNumber;
      socialUserDetail.phone_code = this.addressFormGroup.get('phone_code').value;
      socialUserDetail.user_bio = (this.passFormGroup.get('step2Description').value) ? this.passFormGroup.get('step2Description').value : '';
      // socialUserDetail.password = this.addressFormGroup.get('password').value;
      // socialUserDetail.confirm_password = this.addressFormGroup.get('confirmPassword').value;
      socialUserDetail.postal_code = this.addressFormGroup.get('postalCode').value;
      socialUserDetail.city = this.addressFormGroup.get('city').value;
      socialUserDetail.role = 'client';
      socialUserDetail.userId = userId;
      socialUserDetail.payment_info = invoiceAddressInfo;
      socialUserDetail.cvr = cvr;
      socialUserDetail.way = way;
      socialUserDetail.nr = nr;
      socialUserDetail.floor = floor;
    }

    var data = {}
    if (this.fbFormGroup.get('fbUserId').value || this.googleFormGroup.get('googleUserId').value || this.twitterFormGroup.get('twitterUserId').value) {
      data = {
        firstname: '',// this.addressFormGroup.get('firstname').value,
        lastname: '',
        email: socialUserDetail['socialEmail'],// this.addressFormGroup.get('email').value,
        phone: '',//phoneNumber,
        phone_code: '',//this.addressFormGroup.get('phone_code').value,
        user_bio: '',//this.passFormGroup.get('step2Description').value,
        password: '',// this.addressFormGroup.get('password').value,
        confirm_password: '',//  this.addressFormGroup.get('confirmPassword').value,
        socialUserDetail: socialUserDetail,
        postal_code: '',// this.addressFormGroup.get('postalCode').value,
        city: '',//this.addressFormGroup.get('city').value,
        role: 'client',
        userId: userId,
        payment_info: invoiceAddressInfo

        // phone_number: this.addressFormGroup.get('phone').value,
        // time:this.startTime
        // ,
        // selected_helper:this.selectedBookie,
        //cvr: cvr,

        // yes_18: this.addressFormGroup.get('yes18').value,
        // accepted_terms: this.addressFormGroup.get('acceptedTerms').value,
        // signed_contract: this.contractFormGroup.get('signedContract').value
      }
    }
    else {
      data = {
        firstname: this.addressFormGroup.get('firstname').value,
        lastname: '',
        email: this.addressFormGroup.get('email').value,
        phone: phoneNumber,
        phone_code: this.addressFormGroup.get('phone_code').value,
        user_bio: this.passFormGroup.get('step2Description').value,
        password: this.addressFormGroup.get('password').value,
        confirm_password: this.addressFormGroup.get('confirmPassword').value,
        socialUserDetail: socialUserDetail,
        postal_code: this.addressFormGroup.get('postalCode').value,
        city: this.addressFormGroup.get('city').value,
        role: 'client',
        userId: userId,
        payment_info: invoiceAddressInfo,
        // phone_number: this.addressFormGroup.get('phone').value,
        // time:this.startTime
        // ,
        // selected_helper:this.selectedBookie,
        cvr: cvr,
        way: way,
        nr: nr,
        floor: floor

        // yes_18: this.addressFormGroup.get('yes18').value,
        // accepted_terms: this.addressFormGroup.get('acceptedTerms').value,
        // signed_contract: this.contractFormGroup.get('signedContract').value
      }
    }
    //20200909

    localStorage.setItem("condition_group", JSON.stringify(this.conditionFormGroup.value));
    localStorage.setItem("address_group", JSON.stringify(this.addressFormGroup.value));
    localStorage.setItem("selected_areas", JSON.stringify(this.selectedAreas));
    localStorage.setItem("selected_helper", JSON.stringify(this.selectedBookie));
    localStorage.setItem("passFormGroup", JSON.stringify(this.passFormGroup.value));
    localStorage.setItem("invoiceAddressFormGroup", JSON.stringify(this.invoiceAddressGroup.value));

    return data;
  }

  /**
   * Get areas of city
   * @param event 
   */
  getAreaName(event: any): void {
    let cityName = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getAreas'] + '/' + cityName).then(
      response => {
        this.app.spinner.hide();
        console.log('area res', response)
        if (response['resCode'] == 200) {
          this.areas = response['data'];
          console.log('areas', this.areas)
        }
      },
      err => {
        this.app.spinner.hide();
        console.log('area err', err)
      }
    );
  }


  /**
   * Change language
   */
  changeLanguage() {
    this.selectedLanguage = (this.selectedLanguage == 'en') ? 'dk' : 'en';
    this.translate.use(this.selectedLanguage);
  }

  /**
   * Get regions
   */
  getRegion(): void {
    // Empty selected areas
    this.selectedAreas = [];
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getRegions']).then(
      response => {
        this.app.spinner.hide();
        this.regions = response;
      },
      err => {
        this.app.spinner.hide();
        console.log('region err', err)
      }
    );
  }

  /**
   * Get cities of region
   * @param event 
   */
  getCities(event: any): void {
    // Empty selected areas
    this.selectedAreas = [];
    this.areas = [];
    let regionName = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getCities'] + '/' + regionName).then(
      response => {
        this.app.spinner.hide();
        this.cities = response;
      },
      err => {
        this.app.spinner.hide();
        console.log('cities err', err)
      }
    );
  }

  /**
   * Choose all areas of city
   */
  chooseAllAreas(): void {
    // Empty selected areas
    this.selectedAreas = [];
    this.areas.forEach(item => {
      this.selectedAreas.push(item);
    });
    console.log('selected area', this.selectedAreas)
    this.isStep6Disable = false;
  }

  /**
   * Remove all areas of city
   */
  removeAllAreas(): void {
    this.selectedAreas = [];
    this.isStep6Disable = true;
  }
  /**
   * Choose area
   * @param area 
   */
  addArea(area): void {
    if (this.selectedAreas.length > 0) {
      let keepContinue = true;
      this.selectedAreas.forEach(item => {
        if (keepContinue) {
          // If area has alread added
          if (item == area) {
            keepContinue = false;
          } else {
            this.selectedAreas.push(area);
            keepContinue = false;
          }
        }
      });
    } else {
      this.selectedAreas.push(area);
    }
    this.isStep6Disable = false;
    console.log('added area', this.selectedAreas)
  }

  /**
   * Remove area from selection
   * @param area 
   */
  removeArea(area): void {
    this.selectedAreas.forEach((item, index) => {
      let keepContinue = true;
      if (keepContinue) {
        // Remove selected area
        if (item == area) {
          this.selectedAreas.splice(index, 1);
          keepContinue = false;
        }
      }
    });
    this.isStep6Disable = (this.selectedAreas.length > 0) ? false : true;
    console.log('remove area', this.selectedAreas)
  }

  /**
   * Check is area already selected or not
   * @param area 
   */
  isAreaChoosed(area): boolean {
    let keepContinue = false;
    this.selectedAreas.forEach(item => {
      if (!keepContinue) {
        if (item == area) {
          keepContinue = true;
        }
      }
    });
    return keepContinue;
  }

  openModal(stepper: MatStepper) {
    stepper.selectedIndex = 0;
    $('#priceModal').modal('show');
  }

  filterData() {
    this.isMeetHelperClick = false;
    var tm = [];;
    if (this.time) {
      this.areaTime = this.time.replace(":", ".");
    }
    // if (this.areaDate.day) {
    //   this.areaDate.day = (Array(Math.max(2 - String(this.areaDate.day).length + 1, 0)).join("0") + this.areaDate.day).toString();
    // }
    // if (this.areaDate.month) {
    //   this.areaDate.month = (Array(Math.max(2 - String(this.areaDate.month).length + 1, 0)).join("0") + this.areaDate.month).toString();
    // }
    var areaModel = {
      postal_code: this.postal_code,
      date: this.areaDate,
      time: this.areaTime,
      // offset:10
    }

    var areaDetail = JSON.stringify(areaModel);
    console.log("FilterData");
    localStorage.setItem("AreaDetails", areaDetail);
    this.p = 1;
    this.getBooking(1, this.p);
  }

  shown = false;

  toggel() {
    this.shown = !this.shown;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  specifications = false;
  toggleSpecifications() {
    this.specifications = !this.specifications;
  }

  startTime: any = "08:00";
  endTime: any = "10:30";
  startEndTime = this.startTime + '-' + this.endTime;
  add15Minutes() {

    function D(J) { return (J < 10 ? '0' : '') + J; };
    var startPiece: any = this.startTime.split(':');
    var startMins = startPiece[0] * 60 + +startPiece[1] + +30;

    var endPiece: any = this.endTime.split(':');
    var endMins = endPiece[0] * 60 + +endPiece[1] + +30;

    this.startTime = D(startMins % (24 * 60) / 60 | 0) + ':' + D(startMins % 60);
    this.endTime = D(endMins % (24 * 60) / 60 | 0) + ':' + D(endMins % 60);
    this.startEndTime = this.startTime + '-' + this.endTime;
  }

  subtract15Minutes() {
    function D(J) { return (J < 10 ? '0' : '') + J; };
    if (this.startTime != "00:00") {
      var startPiece: any = this.startTime.split(':');
      var startMins = startPiece[0] * 60 + +startPiece[1] + -30;

      var endPiece: any = this.endTime.split(':');

      if (this.endTime == "00:00") {
        this.endTime = "24:00";
        endPiece = this.endTime.split(':');
      } else {
        var endPiece: any = this.endTime.split(':');
      }

      var endMins = endPiece[0] * 60 + +endPiece[1] + -30;


      this.startTime = D(startMins % (24 * 60) / 60 | 0) + ':' + D(startMins % 60);
      this.endTime = D(endMins % (24 * 60) / 60 | 0) + ':' + D(endMins % 60);
      this.startEndTime = this.startTime + '-' + this.endTime;
    } else {
      this.startTime = "08:00";
      this.endTime = "11:00"
      this.startEndTime = this.startTime + '-' + this.endTime;
    }

  }

  areaTimeValueChange() {

    function D(J) { return (J < 10 ? '0' : '') + J; };
    var endPiece: any = this.endTime.split(':');
    var endMins = endPiece[0] * 60 + +endPiece[1] + + (this.areaTime * 60);
    this.endTime = D(endMins % (24 * 60) / 60 | 0) + ':' + D(endMins % 60);

    this.startEndTime = this.startTime + '-' + this.endTime;
  }
  getPage(page: number) {
    this.p = page;
    this.getBooking(1, this.p);
  }
}

