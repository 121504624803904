import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Time } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/services/api.service';
import { NativeDateAdapter, MatDateFormats, MatDatepickerInputEvent } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export class AppDateAdapter extends NativeDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Søn','Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør' ];
  }
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }

    var daysOfWeek = { 'Sun': 'Søn', 'Mon': 'Man', 'Tue': 'Tir', 'Wed': 'Ons', 'Thu': 'Tor', 'Fri': 'Fre', 'Sat': 'Lør' };
    //A table whose properties are Danish names of months and whose values are English names.
    var months = { 'Jan': 'Jan', 'Feb': 'Feb', 'Mar': 'Mar', 'Apr': 'Apr', 'May': 'Maj', 'Jun': 'Jun', 'Jul': 'Jul', 'Aug': 'Aug', 'Sep': 'Sep', 'Oct': 'Okt', 'Nov': 'Nov', 'Dec': 'Dec' };
    var tokens = date.toDateString().split(' '); //Split the Danish string by spaces
    var dow = tokens[0]; //This is the Danish day of week
    var month = tokens[1]; // and this is the Danish month 

    tokens[0] = daysOfWeek[dow]; //Get the English day of week from the table
    tokens[1] = months[month]; //Get the English month from the table
    //this.date = tokens.join(' '); //Join all tokens again
    // return new Date(english_date); 
    return tokens.join(' ');//date.toDateString();
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};
@Component({
  selector: "app-home-dk",
  templateUrl: "./home-dk.component.html",
  styleUrls: ["../home/home.component.css"],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})


export class HomeDkComponent implements OnInit {
  date = new Date(); //new FormControl("2019-08-22T23:00:00");

  public isMenuOpen = false;
  public items: any = [];
  public areaName: string = '';
  public areaDate: Date = new Date();
  public areaTime: any = '';
  public disabledButton = true;
  public times = [
    { "key": 'Antal', "value": '' },
    { "key": 2.5, "value": 2.5 },
    { "key": 3, "value": 3 },
    { "key": 4, "value": 4 },
    { "key": 5, "value": 5 },
    { "key": 6, "value": 6 },
    { "key": 7, "value": 7 },
    { "key": 8, "value": 8 }
  ];

  oggSrc = '';
  mp4Src = '';
  webmSrc = ''

  ngOnInit() {
    this.oggSrc = 'assets/images/big_buck_bunny.ogv';
    this.mp4Src = 'assets/images/big_buck_bunny.mp4';
    this.webmSrc = 'assets/images/big_buck_bunny.webm';
    this.date.setDate(this.date.getDate() + 2);
    this.areaDate.setDate(this.areaDate.getDate() + 2);

  }
  videoTag;
  // // modalRef: BsModalRef;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    public app: AppComponent,
    private apiService: ApiService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private sanitizer: DomSanitizer
  ) {
    this.videoTag = this.getVideoTag();
    translate.use('dk');
    translate.setDefaultLang('dk');
    this.dateAdapter.setLocale('dk');
  }
  player: any;
  safeSrc: SafeResourceUrl;
  private getVideoTag() {
    //this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/garden-003.mp4?autoplay=1&loop=1&controls=0&rel=0&modestbranding=1&showinfo=0');
    return this.sanitizer.bypassSecurityTrustHtml(
      `<video muted loop autoplay="autoplay" poster="img/videoframe.jpg" class="fullscreen-bg__video" >
      <source src="assets/images/garden-003.mp4" type="video/mp4">
      <source src="assets/images/garden-003.webm" type="video/webm">    
      <source src="assets/images/garden-003.ogv" type="video/ogg">
     </video>`
    );
   
  }
  setBookingHelpers() {
    if (this.areaTime < 2.5 || this.areaTime > 8) {
      this.toastr.error("Minimum 2.5 hours and Maximum 8 hours is allowed.");
      return false;
    }

    this.app.spinner.show();
    this.apiService.getData("helper-get-city" + '/' + this.areaName).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          var areaModel = {
            postal_code: this.areaName,
            date: this.areaDate,
            time: this.areaTime,
            // offset:10
          }

          var areaDetail = JSON.stringify(areaModel);
          localStorage.setItem("AreaDetails", areaDetail);
          this.router.navigateByUrl("/customer/order-dk");
        } else if (response['resCode'] == 400) {
          $('#NoZipCodeModal').modal('show');
        }
      },
      err => {
        this.app.spinner.hide();
        $('#NoZipCodeModal').modal('show');
      }
    );


  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  setValue() {
    if (this.areaName && this.areaName.length == 4) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
  }
}
