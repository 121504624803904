import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { HomeComponent } from './front-module/home/home.component';
import { HomeDkComponent } from './front-module/home-dk/home-dk.component';
// shared-module
import { LoginComponent } from './front-module/login/login.component';
import { LoginDkComponent } from './front-module/login-dk/login-dk.component';
import { ForgotPasswordComponent } from './shared-module/forgot-password/forgot-password.component';
import { ForgotPasswordDkComponent } from './shared-module/forgot-password-dk/forgot-password-dk.component';
import { ResetPasswordComponent } from './shared-module/reset-password/reset-password.component';
import { ResetPasswordDkComponent } from './shared-module/reset-password-dk/reset-password-dk.component';
import { LogoutComponent } from './front-module/logout/logout.component';
import { FaqComponent } from './shared-module/support/faq/faq.component';
import { FaqDkComponent } from './shared-module/support/faq-dk/faq-dk.component';
import { SupportComponent } from './shared-module/support/support/support.component';
import { SupportDkComponent } from './shared-module/support/support-dk/support-dk.component';
import { CreateTicketComponent } from './shared-module/support/create-ticket/create-ticket.component';
import { CreateTicketDkComponent } from './shared-module/support/create-ticket-dk/create-ticket-dk.component';
// helper profile view
import { IntroductionComponent } from './front-module/helper/introduction/introduction.component';
import { IntroductionDkComponent } from './front-module/helper/introduction-dk/introduction-dk.component';
import { Profile00Component } from './front-module/helper/profile00/profile00.component';
import { Profile00DkComponent } from './front-module/helper/profile00-dk/profile00-dk.component';
// helper profile signup
import { Signup01JoinComponent } from './front-module/helper/signup01-join/signup01-join.component';
import { Signup01JoinDkComponent } from './front-module/helper/signup01-join-dk/signup01-join-dk.component';
//Upload image introduction
import { UploadImageComponent } from './front-module/helper/upload-image/upload-image.component';
import { AddPhotoComponent } from './front-module/helper/add-photo/add-photo.component';
import { PhotoApprovedRequirementComponent } from './shared-module/support/photo-approved-requirement/photo-approved-requirement.component';
import { PhotoApprovedRequirementDKComponent } from './shared-module/support/photo-approved-requirement-dk/photo-approved-requirement-dk.component';
// customer
import { ProfileC00Component } from './front-module/customer/profile-c00/profile-c00.component';
import { ProfileC00DkComponent } from './front-module/customer/profile-c00-dk/profile-c00-dk.component';
import { OrdreComponent } from './front-module/customer/ordre/ordre.component';
import { OrdreDkComponent } from './front-module/customer/ordre-dk/ordre-dk.component';
import { ThankyouComponent } from './front-module/customer/thankyou/thankyou.component';
import { CustomerCheckinComponent } from './front-module/customer/customer-checkin/customer-checkin.component';
import { CustomerCheckoutComponent } from './front-module/customer/customer-checkout/customer-checkout.component';
// Auth Guard
import { AuthGuard } from './auth/auth.guard';
// booking/address component
import { Component } from 'fullcalendar';
import { BookingPaymentComponent } from './front-module/customer/booking-payment/booking-payment.component';
import { BookingPaymentDkComponent } from './front-module/customer/booking-payment-dk/booking-payment-dk.component';
import { ThankyouDkComponent } from './front-module/customer/thankyou-dk/thankyou-dk.component';

export const appRoutes: Routes = [
	{
		path: '', children:
			[
				{ path: '', redirectTo: 'dk', pathMatch: 'full' },
				{ path: 'en', component: HomeComponent },
				{ path: 'dk', component: HomeDkComponent },
			]
	},

	{ path: 'login', component: LoginComponent },
	{ path: 'login-dk', component: LoginDkComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'forgot-password-dk', component: ForgotPasswordDkComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'reset-password-dk', component: ResetPasswordDkComponent },
	{ path: 'logout', component: LogoutComponent },
	{
		path: 'helper', children:
			[
				{ path: 'signup', component: IntroductionComponent },
				{ path: 'signup-dk', component: IntroductionDkComponent },
				{ path: 'profile00', component: Profile00Component, canActivate: [AuthGuard] },
				{ path: 'profile00-dk', component: Profile00DkComponent, canActivate: [AuthGuard] },
				{ path: 'signup01-join', component: Signup01JoinComponent },
				{ path: 'signup01-join-dk', component: Signup01JoinDkComponent },
				{ path: 'upload-image', component: UploadImageComponent },
				{ path: 'upload-image/add-photo', component: AddPhotoComponent },
			]
	},
	{
		path: 'customer', children:
			[
				{ path: 'profileC00', component: ProfileC00Component, canActivate: [AuthGuard] },
				{ path: 'profileC00-dk', component: ProfileC00DkComponent, canActivate: [AuthGuard] },
				{ path: 'ordre', component: OrdreComponent },
				{ path: 'booking-payment', component: BookingPaymentComponent },
				{ path: 'booking-payment-dk', component: BookingPaymentDkComponent },
				{ path: 'ordre/:option', component: OrdreComponent },
				//{ path: 'order/:txnid/:orderid/:amount/:currency/:date/:time/:txnfee/:paymenttype/:cardno/:hash', component: OrdreComponent },
				{ path: 'order-dk', component: OrdreDkComponent },
				{ path: 'order-dk/:option', component: OrdreDkComponent },
				{ path: 'thankyou', component: ThankyouComponent },
				{ path: 'thankyou-dk', component: ThankyouDkComponent },
				{ path: 'checkin/:userid/:bookingid', component: CustomerCheckinComponent },
				{ path: 'checkout/:userid/:bookingid', component: CustomerCheckoutComponent }
			]
	},

	{
		path: 'support', children:
			[
				{ path: 'create-ticket', component: CreateTicketComponent },
				{ path: 'create-ticket-dk', component: CreateTicketDkComponent },
				{ path: 'support', component: SupportComponent },
				{ path: 'support-dk', component: SupportDkComponent },
				{ path: 'faq', component: FaqComponent },
				{ path: 'faq-dk', component: FaqDkComponent },
				{ path: 'imagerequirements', component: PhotoApprovedRequirementComponent },
				{ path: 'imagerequirements-dk', component: PhotoApprovedRequirementDKComponent }

			]
	},
	{ path: '**', redirectTo: '/' },

];