import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-danish',
  templateUrl: './terms-danish.component.html',
  styleUrls: ['./terms-danish.component.css']
})
export class TermsDanishComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
