import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-english',
  templateUrl: './terms-english.component.html',
  styleUrls: ['./terms-english.component.css']
})
export class TermsEnglishComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
