import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  public form: FormGroup;
  public controlErrors: ValidationErrors;
  public errors: Array<any> = new Array();

  constructor() { }

  public setForm(form: FormGroup) {
    this.form = form;
  }

  public validate(errors?: boolean) {

    /* 
    validation states for formGroup 
        1. VALID, 
        2. INVALID, 
        3. PENDING, or 
        4. DISABLED 
    */
    if (errors == undefined) {
      errors = true;
    }

    let status = this.form.status;

    switch (status) {
      case "VALID": return true;

      case "INVALID":
        if (errors) {
          this.getFormValidationErrors();
        }
        return false;

      case "PENDING": return false;

      case "DISABLED": return false;

    }
  }

  /**
   * Get each form control error
   */
  protected getFormValidationErrors() {
    Object.keys(this.form.controls).forEach(key => {
      this.controlErrors = this.form.get(key).errors;
      if (this.controlErrors != null) {
        Object.keys(this.controlErrors).forEach(keyError => {
          let error = {
            'key': key,
            'keyError': keyError,
            'message': '',
            'controlError': this.controlErrors
          }

          error.message = this.messages(error);
          this.errors.push(error);
        });
      }
      var arrControl = (<any>this.form.get(key)).controls;
      if (arrControl) {
        Object.keys(arrControl).forEach(arrKey => {
          Object.keys(arrControl[arrKey].controls).forEach(controlKey => {
            this.controlErrors = arrControl[arrKey].get(controlKey).errors;
            if (this.controlErrors != null) {
              Object.keys(this.controlErrors).forEach(arrKeyError => {
                let error = {
                  'key': controlKey,
                  'keyError': arrKeyError,
                  'message': '',
                  'controlError': this.controlErrors
                }

                error.message = this.messages(error);
                this.errors.push(error);
              });
            }
          });
        });
      }
    });
  }

  /**
   * Display error
   */
  public displayErrors(formId = '', fieldName = '') {
    this.errors.forEach((error, index) => {      
      let currentElement: any = document.getElementsByName(error.key)[0];
      if(currentElement){
        if (formId == 'stepFrom') {
          if (error.key == fieldName) {
            currentElement.classList.add('has-error');
            // Set only for phone of step 3
            if (fieldName == 'phone') {
              $('.parent-phone-code-div').css('border', '2px solid #e01e5c');
            } else {
              currentElement.parentElement.parentElement.style.border = '2px solid #e01e5c';
              currentElement.parentElement.parentElement.style.borderRadius = '5px';
            }
          }
        } else if (formId == 'customerStepFrom') {
          if (error.key == fieldName) {
            currentElement.classList.add('has-error');
            // Set only for phone of step 3
            currentElement.parentElement.style.border = '2px solid red';
            currentElement.parentElement.style.borderRadius = '5px';
          }
        }
        else if (formId == 'signupform') {
          if (error.key == fieldName) {
            currentElement.classList.add('has-error');
            if (fieldName == 'phone') {
              $('.parent-phone-code-div').css('border', '2px solid #e01e5c');
            }else{
              // Set only for phone of step 3
              currentElement.style.border = '2px solid red';
              currentElement.style.borderRadius = '5px';
            }
            
          }
        } else if (formId == 'customerProfileFrom') {
          if (error.key == fieldName) {
            currentElement.classList.add('has-error');
            // Set only for phone of step 3
            currentElement.style.border = '2px solid red';
            currentElement.style.borderRadius = '5px';
          }
        }else if (formId == 'helperProfileFrom') {
          if (error.key == fieldName) {
            currentElement.classList.add('has-error');
            // Set only for phone of step 3
            currentElement.style.border = '2px solid red';
            currentElement.style.borderRadius = '5px';
          }
        }else if (formId == 'profileFrom') {
          currentElement.classList.add('has-error');
          currentElement.parentElement.parentElement.style.border = '2px solid #e01e5c';
          currentElement.parentElement.parentElement.style.borderRadius = '5px';
        } else {
          // currentElement.style.border = '2px solid #e01e5c !important';
        }
      }
    });    
    // this.removeValidationErrors();
  }

  removeError(fieldName){
    this.errors.forEach((error, index) => {      
      let currentElement: any = document.getElementsByName(error.key)[0];
      if(currentElement){
        currentElement.classList.remove('has-error');
        if (error.key == fieldName) {
          currentElement.parentElement.style.border = '';
          currentElement.parentElement.style.borderRadius = '';

          currentElement.style.border = '';
          currentElement.style.borderRadius = '';
        }
      }
    });
  }

  /**
   * 
   * @param container 
   */
  public alertErrors(container: string) {
    let ele: Element = document.getElementById('error-option');
    this.errors.forEach((error, index) => {
      let div;
      div = document.createElement('div');
      div.className = 'error';
      div.innerHTML = "<span>" + error.message + "</span>";
      ele.appendChild(div);

    });
    this.removeValidationErrors();
  }

  /**
   * Push error
   * @param container 
   */
  public pushErrors(container: string) {

    let ele: Element = document.getElementById(container);
    this.errors.forEach((error, index) => {
      ele.innerHTML = "<span class='error'>" + error.message + "</span><br/>";
    });
    this.removeValidationErrors();
  }

  /**
   * Set validation message
   * @param error 
   */
  public messages(error: any) {
    let keyError = error.keyError;
    let key = this.ucWord(error.key);

    switch (keyError) {
      case "email": return "Enter proper email address";

      case "required":
        if (key == "Options") {
          return "All options are Mendatory";
        } else {
          return this.ucWord(error.key) + " is required";
        }

      case "validateEmail": return "Enter proper email address";

      case "passwordVal": return "Enter password as per criteria";

      case "minlength": return "Minimum " + error.controlError.minlength['requiredLength'] + " characters are required";

      case "maxlength": return "Maximum " + error.controlError.maxlength['requiredLength'] + " characters are allowed";

      case "max": return "Value should be less than " + error.controlError.max['max'];

      case "whiteSpace": return "Value is only whitespace";

      case "notEquivalent":
        if (error.controlError.notEquivalent['actualLength'] == 0) {
          return this.ucWord(error.key) + " is required";
        } else {
          return this.ucWord(error.controlError.notEquivalent['matchAgainst']) + ' & ' + this.ucWord(error.key) + " should be same.";
        }

      case "bigImage": return "Select image with size less than " + error.controlError.bigImage['maxSize'];

      default: return 'Please enter proper value';
    }
  }

  /**
   * 
   * @param str 
   */
  public ucWord(str: string) {
    return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    }).replace(/_/g, ' ');
  }

  /**
   * Remove element error
   * @param name 
   */
  public removeErrors(name = null) {
    if (name) {
      var input = $('[name="' + name + '"]');

      if (input.next('.error').length > 0) {
        input.next('.error')[0].remove();
      } else if (input.next().next('.error').length > 0) {
        if (input.next().next().next('.error').length > 0) {
          input.next().next().next('.error')[0].remove();
        }
        input.next().next('.error')[0].remove();
      }
      if (input.hasClass('has-error')) {
        input[0].classList.remove('has-error')
      }
      input[0].parentElement.parentElement.style.border = 'none';
      if (name != 'phone' && name != 'userBio' && name != "regNo" && name != "acNo" && name != "smsCode" && name != "city") {
        input[0].style.border = '2px solid #5dc5ba';
      }
      
      // Set only for phone of step 3
      if (name == 'phone') {
        $('.parent-phone-code-div').css('border', '2px solid #5dc5ba');
      }
    } else {
      let ele: any = document.getElementsByClassName('error');
      while (ele.length > 0) {
        ele[0].remove();
      }

      let eleError: any = document.getElementsByClassName('has-error');
      for (let i = 0; i < eleError.length; i++) {
        eleError[i].classList.remove('has-error');
      }
    }
  }

  /**
   * Remove all errors
   */
  public removeValidationErrors() {
    this.errors = [];
  }

  /**
   * Remove space
   * @param control 
   */
  public whitespaceValidator(control) {
    // RFC 2822 compliant regex
    if (control.value != '' && control.value != null) {
      let isWhitespace = (control.value).trim().length === 0;
      let isValid = !isWhitespace;
      return isValid ? null : { 'whiteSpace': true }
    }
  }

  /**
   * Proper email validation
   * @param control 
   */
  public emailValidator(control) {
    // RFC 2822 compliant regex
    if (control.value != '') {
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(control.value)) {
        return null;
      } else {
        return { 'validateEmail': true };
      }
    }
  }

  /**
   * Removed white space blank
   * @param control 
   */
  public noWhitespaceValidator(control) {
    if (control.value != '') {
      const isWhitespace = (control.value).trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whiteSpace': true };
    }
  }

  /**
   * Check password matching value
   * @param controlName 
   * @param matchingControlName 
   */
  public matchValue(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup): ValidationErrors => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control && control.value !== matchingControl.value) {
        if (control.value != '' && matchingControl.value != '') {
          matchingControl.setErrors({ notEquivalent: { matchAgainst: controlName, actualLength: formGroup.controls[matchingControlName].value.length } });
        }
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  /**
   * Check password criteria
   * @param controlName
   */
  public passwordCriteria(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isDigit = false;
      let isCapitalCase = false;
      let isSmallCase = false;

      // password must contain digits
      if ((!/\d/.test(control.value))) {
        isDigit = false;
      } else {
        isDigit = true;
      }
      // password must contain uppercase letter
      if (!/[A-Z]/.test(control.value)) {
        // console.log('password must contain uppercase letter');
        isCapitalCase = false;
      } else {
        isCapitalCase = true;
      }
      // password must contain lowercase letter
      if (!/[a-z]/.test(control.value)) {
        // console.log('password must contain uppercase letter');
        isSmallCase = false;
      } else {
        isSmallCase = true;
      }
      if (isDigit && isCapitalCase && isSmallCase) {
        return null;
      } else {
        if (control.value) {
          return { passwordVal: true };
        } else {
          return null;
        }
      }
    }
  }

  /**
   * Set validation error focus
   */
  setFoucsOfForm(formControls) {
    let keepGoing = true;
    Object.keys(formControls).forEach(field => {
      if (keepGoing) {
        if ($('[name="' + field + '"]').hasClass('ng-invalid')) {
          $('[name="' + field + '"]').focus();
          keepGoing = false;
        }
      }
    });
  }

  /**
   * set validation for time for booked helpers seraching
   */

  checkTimeDuration(time) {
    if (time < 2.5 || time > 8) {
      return true;
    } else {
      return false;
    }
  }

}
