import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataSharingService } from 'src/app/services/data.sharing.service';

@Component({
  selector: 'app-support-dk',
  templateUrl: './support-dk.component.html',
  styleUrls: ['../support/support.component.css']
})
export class SupportDkComponent implements OnInit {

  public isMenuOpen: boolean = false;
  public isLoggedIn: boolean = false;
  public isUserCustomer:boolean = false;

  constructor(public auth: AuthenticationService,private dataSharingService: DataSharingService) {
    // Subscribe here, this will automatically update 
        // "isUserLoggedIn" whenever a change to the subject is made.
        this.dataSharingService.isUserLoggedIn.subscribe( value => {
          this.isLoggedIn = value;
      });
   }

  ngOnInit() {
    this.isLoggedIn = this.auth.isUserLoggedIn();
    this.isUserCustomer = this.auth.isUserCustomer();
  }

}