import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      // MerchantNumber: "60004771"
      // ShopPlatform: "YourpayTestBetaling-ShopPlatform"
      // amount: "1"
      // autocapture: "1"
      // cardid: "4"
      // ccrg: "3012.6540.7402"
      // checksum: "03c051fb9406fc867ec624f7655f03beabdb6feb"
      // currency: "208"
      // cvc: ""
      // date: "20200604"
      // expmonth: ""
      // expyear: ""
      // orderid: "9wSNY96sL1Xq"
      // split: "0"
      // tcardno: "510000XXXXXX0511"
      // tchecksum: "03c051fb9406fc867ec624f7655f03beabdb6feb"
      // tid: "2579996"
      // time: "1591264609"
      // uxtime: "1591264609" 
  });
   }

  ngOnInit() {
  }

}
