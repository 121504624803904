// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl:'https://garden.servicehelper.dk/backend/api/',// 'https://viram.servicehelper.dk/backend/api/',
  facebookProviderId: '426955964472458',//'262432418289079',//'426955964472458',
  googleProvideId: '568735101150-q7i02oc0ke2gsi1bjv7ql812ocf7faqs.apps.googleusercontent.com',
  twitterProviderId:'R6tBdD1jsrSGgYfFQH4knDIbg' , //'Zx3wGobtqNEWtKVzF7dIUeE2X',
  smsApiKey: '5557ac25b7c4545e59b7690004c526ea3b2f605a570467b9fa47a8e982453483',
  smsApi: 'http://api.zumosms.com/v2/message.json?apikey=',
  twitterAPIKey:'R6tBdD1jsrSGgYfFQH4knDIbg' , //'Zx3wGobtqNEWtKVzF7dIUeE2X',
  twitterAPISecretKey:'jCD8EIQV63MXKBuiXRJw0066F6FrQWNRuSWEzvex1Ze8mIbxfu',//'MGWugzVAJTte0tJGpRpVdeaAZX5asZAi9O3TSLx2PyAp30sxVs',
  twitterAccessToken:'1281206991894044673-azglOo8J67EOEyYqr1vfTYj7LNw8Sh',//'3500746279-UKw2CAMcJ1ftRRzFgRiCAEtkWfgdpqvlqbv5yLJ',
  twitterAccessTokenSecret:'F569zaTgAn97ISI3txLrSAc7jYwT7Qacl76LPvPovlxV6',//'0J6yD7o1V2OV5gRQTVwC10XfiaZrrNS2GuM0DoVbOgDTn'
  // twitterAPIKey:'QnKIVvRqQrgGshQdoWGWletfh',
  // twitterAPISecretKey:'fxbb9WI0DrQyCpOCD1CgZtYjTquweRbVSzUx65oRudlii7JWsj',
  // twitterAccessToken:'3526660340-bmu2Nqg5khpkYi0Og9dkpdRpT1d1MQwxUqFygMF',
  // twitterAccessTokenSecret:'3VFbnzFES1O6YLFV0WfeLNQG3DciVEqEdx6JqIoUx2Tsz'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
