import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Input,
  NgModule,
  Renderer2,
  AfterViewInit
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Constant } from "../../../settings/constants";
import { ApiService } from "../../../services/api.service";
import { ValidationService } from "../../../services/validation.service";
import { AppComponent } from "../../../app.component";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Router } from "@angular/router";
import { empty, Observable, Observer } from "rxjs";
import 'rxjs/add/observable/interval';
import { StarRatingComponent } from "ng-starrating";
import { PopoverContentComponent } from 'ngx-smart-popover';
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
declare function popTwitterSignIn(outhToken, callback): any;
import {
  NgbDateStruct,
  NgbCalendar,
  NgbModal,
  ModalDismissReasons,
  NgbTimepicker,
  NgbDate,
  NgbDatepickerConfig,
  NgbTimeStruct
} from "@ng-bootstrap/ng-bootstrap";

import * as $ from "jquery";
import "fullcalendar";
import * as moment from "moment";
import { MatDatepicker, MatCalendar, MatTabGroup } from "@angular/material";
import { utc } from 'moment';
import { WebcamImage } from 'ngx-webcam';
import { Dimensions, ImageCroppedEvent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
const now = new Date();
import { DatePipe } from '@angular/common';
declare var jQuery: any;
declare function download(): any;
declare function initMap(callback): any;
declare function initTooltip(): any;
declare function removeFeatureFromMap(callback): any;

import { NativeDateAdapter, MatDateFormats, MatDatepickerInputEvent } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { any } from 'underscore';
import { DomSanitizer } from '@angular/platform-browser';
import * as jspdf from 'jspdf';

export class AppDateAdapter extends NativeDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'];
  }
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }

    var daysOfWeek = { 'Sun': 'Søn', 'Mon': 'Man', 'Tue': 'Tir', 'Wed': 'Ons', 'Thu': 'Tor', 'Fri': 'Fre', 'Sat': 'Lør' };
    //A table whose properties are Danish names of months and whose values are English names.
    var months = { 'Jan': 'Jan', 'Feb': 'Feb', 'Mar': 'Mar', 'Apr': 'Apr', 'May': 'Maj', 'Jun': 'Jun', 'Jul': 'Jul', 'Aug': 'Aug', 'Sep': 'Sep', 'Oct': 'Okt', 'Nov': 'Nov', 'Dec': 'Dec' };
    var tokens = date.toDateString().split(' '); //Split the Danish string by spaces
    var dow = tokens[0]; //This is the Danish day of week
    var month = tokens[1]; // and this is the Danish month 

    tokens[0] = daysOfWeek[dow]; //Get the English day of week from the table
    tokens[1] = months[month]; //Get the English month from the table
    //this.date = tokens.join(' '); //Join all tokens again
    // return new Date(english_date); 
    return tokens.join(' ');//date.toDateString();
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};
@Component({
  selector: "app-profile00-dk",
  templateUrl: "./profile00-dk.component.html",
  styleUrls: ["../profile00/profile00.component.css"],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class Profile00DkComponent implements OnInit, AfterViewInit {
  @ViewChild("contractData", { static: false }) contractData: ElementRef;
  @ViewChild("content", { static: true }) content: ElementRef;
  @ViewChild('myPopover', { static: true }) myPopover: PopoverContentComponent;
  @ViewChild("answerblock", { static: false }) divMessages: ElementRef;
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;
  @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;

  chatDate = utc;
  public months = [];

  public isShowMapDetail = false;
  public totalAmount: any;
  public selectedMonth = new Date().getMonth() + 1;
  public helperName: string;
  public isEnglish: boolean = false;
  public chatOrderDetail: Array<any> = [];
  public messages: any = [];
  public showStatement: boolean = false;
  public data: any = [];
  public profileFormGroup: FormGroup;
  public calendarForm: FormGroup;
  public status: number;
  public seniorstatus;
  public cleanerstatus;
  public statusMsg: string = "";
  public statusDisable: boolean = false;
  public repeatSwitch: boolean = false;
  public allDaySwitch: boolean = false;
  public isCalendarAdd: boolean = true;
  public calendarEventid: number = 0;
  public statementButton: boolean = false;
  public pastEvent: boolean = true;
  public fileToUpload: File = null;
  public imageErr: boolean = false;
  public eventClickData: any;
  public dbEventStartTime: any = { hour: 0, minute: 0, second: 0 };
  public dbEventEndTime: any = { hour: 0, minute: 0, second: 0 };
  public selectedAreas: any = [];
  public selectedDbAreas: any = [];
  public dbAreas: any = [];
  public regions: any = [];
  public cities: any = [];
  public areas: any = [];
  public isExtraAreaDisplay: boolean = false;
  public noBookingData: boolean = false;
  public noBookingOrder: boolean = false;
  public eventPopOver: boolean = true;
  public monthSelected: string;
  public bookingData: any;
  public bookingOrder: any;
  public monthDropdown: any;
  public reviews: any = [];
  public model: NgbDateStruct;
  public eventData: any;
  public datePickerDate: any;
  public showStartTime = false;
  public showEndTime = false;
  closeResult: string;
  message: any;
  @Input() eventAllData: any;
  @Input() selectedIndex: number | null


  public webcamImage: WebcamImage = null;
  public webcamImageSenior: WebcamImage = null;
  public webcamImageCleaner: WebcamImage = null;

  constructor(
    private _formBuilder: FormBuilder,
    private validationService: ValidationService,
    private apiService: ApiService,
    private toastr: ToastrService,
    public app: AppComponent,
    private router: Router,
    private ngCalendar: NgbCalendar,
    private modalService: NgbModal,
    private datePickerConfig: NgbDatepickerConfig,
    private rendererr: Renderer2,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
    private _sanitizer: DomSanitizer,
    private authService: AuthService
  ) {
    translate.use('de');
    translate.setDefaultLang('dk');
    this.dateAdapter.setLocale('de');
    this.rendererr.listen('window', 'click', (e: any) => {
      if ($(e.target)[0].offsetParent && $(e.target)[0].offsetParent.id != "startTimerContent") {
        this.showStartTime = false;
      }

      if ($(e.target)[0].offsetParent && $(e.target)[0].offsetParent.id != "endTimerContent") {
        this.showEndTime = false;
      }

      if (e.target.classList.contains('popover_times')) {
        jQuery(this.calendarEle).popover('hide');
      }

      if (e.target.classList.contains('edit_event')) {
        jQuery(this.calendarEle).popover('hide');
        this.editEventModal();
      }

      // console.log(e.target);
    });

    this.rendererr.listen('body', 'click', (e: any) => {
      $('.popover').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.popover').has(e.target).length === 0) {
          jQuery(this).popover('hide');
        }
      });
    })


    this.selectedIndex = parseInt(localStorage.getItem('selectedIndex'));
  }

  selectedTab = 0;
  isMessage: any
  ngOnInit() {
    this.selectedTab = 0;
    this.showHideStatementButton();
    var sub = Observable.interval(3000)
      .subscribe((val) => {
        if (this.selectedTab == 0) {
          this.getChatMessages(true);
        }
      });


    var isEnglishLanguage = localStorage.getItem('Language');
    if (isEnglishLanguage == "false") {
      this.router.navigateByUrl('/helper/profile00-dk');
    }

    this.helperName = localStorage.getItem("userName");
    this.months = [];
    var currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= currentYear - 1; i--) {
      //yearDuration.push({ text: i.toString(), value: i });
      var items = [
        { "Id": 12, "Group": i, "Name": "December" },
        { "Id": 11, "Group": i, "Name": "November" },
        { "Id": 10, "Group": i, "Name": "Oktober" },
        { "Id": 9, "Group": i, "Name": "September" },
        { "Id": 8, "Group": i, "Name": "August" },
        { "Id": 7, "Group": i, "Name": "Juli" },
        { "Id": 6, "Group": i, "Name": "Juni" },
        { "Id": 5, "Group": i, "Name": "Maj" },
        { "Id": 4, "Group": i, "Name": "April" },
        { "Id": 3, "Group": i, "Name": "Marts" },
        { "Id": 2, "Group": i, "Name": "Februar" },
        { "Id": 1, "Group": i, "Name": "Januar" },
      ]

      var obj = {
        "group": i,
        "items": items
      }
      this.months.push(obj);
    }
    this.getBookingOrderDetail();
    // Call to get profile
    this.getProfile();
    // Create profile form
    this.createProfileForms();
    this.getRegion();
    this.bookingOrderInfo();
    this.getUserReviews();
    this.getAllEvents();
    this.getChatMessages();
    this.getHelpersCustomer();
    var self = this;
  }

  ngAfterViewInit() {
    if (this.selectedIndex == 5) {
      // $('#calendarTab').trigger("click");
      this.getAllEvents();
      this.tabGroup.selectedIndex = 5;
      // setTimeout(() => {
      //   this.tabClick(this.selectedIndex);
      // }, 2000);

    }
  }

  startDates = [];
  endDates = [];
  getAllEvents() {
    this.apiService.getData(Constant.SLUGS["getAllEvents"]).then(
      response => {
        if (response["resCode"] == 200) {
          this.eventAllData = response["data"];
          for (var d = 0; d < this.eventAllData.length; d++) {
            this.eventAllData[d].backgroundColor = "black";
            this.eventAllData[d].className = ["event", "greenEvent"];
            var event = this.eventAllData[d];
            var sDate = new Date(event.start);
            var eDate = new Date(event.end);
            var startDate = sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear();
            var endDate = eDate.getDate() + "/" + (eDate.getMonth() + 1) + "/" + eDate.getFullYear();

            this.startDates.push(startDate);
            this.endDates.push(endDate);
          }
          localStorage.removeItem('selectedIndex');
          this.model = this.ngCalendar.getToday();
          this.loadFullCalendar();
          this.createCalendarForm();
          // console.log("dgf", this.eventAllData);
        }
      },
      err => {
        this.toastr.error("Problem in fetching events. Please try again", "", {
          timeOut: Constant.ERROR_TIMEOUT,
          positionClass: Constant.MESSAGE_POSITION,
          closeButton: true
        });
      }
    );
  }

  /**
   * Create form
   */
  notification = "0";
  reminder = "2";
  createProfileForms(): void {
    this.profileFormGroup = this._formBuilder.group(
      {
        //reminderEmail: [false],
        //reminderSms: [false],
        // notificationEmail:[false],
        // notificationSms:[false],
        reminder:["2"],
        notification: ["0"],
        status: [""],
        hourlyRate: [
          "",
          [
            Validators.required,
            Validators.minLength(2),
            Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')
          ]
        ],
        cpr: ["", [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
        cvr: ["", [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
        regNo: [
          "",
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')
          ]
        ],
        acNo: [
          "",
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(10),
            Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')
          ]
        ],
        streetLine: ["", [Validators.required, Validators.minLength(3)]],
        addressNear: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(4), Validators.pattern('^[a-zA-Z0-9]*$')]],
        addressFloor: ["", [Validators.minLength(1), Validators.maxLength(20)]],
        email: ['', [Validators.required, this.validationService.emailValidator, Validators.maxLength(100)]],
        postalCode: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
            Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')
          ]
        ],

        city: ["", [Validators.required, Validators.minLength(3)]],
        phone_code: ["+45"],
        phone: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(8),
            Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')
          ]
        ],
        userBio: [""],
        firstName: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30)
          ]
        ],
        lastName: [
          ""
        ],
        oldPassword: ['', [Validators.minLength(8), Validators.maxLength(30), this.validationService.passwordCriteria()]],
        password: ['', [Validators.minLength(8), Validators.maxLength(30), this.validationService.passwordCriteria()]],
        confirmPassword: ['', [Validators.minLength(8), Validators.maxLength(30)]],
        image: [""],
        seniorimage: [""],
        senioruserBio: [""],
        seniorstatus: [""],
        cleanerstatus: [""],
        cleaneruserBio: [''],
        cleanerimage: [""],
      },
      {
        validator: this.validationService.matchValue(
          "password",
          "confirmPassword"
        )
      }
    );
  }

  /**
   * Get city name by postal code
   * @param event 
   */
  cityName = '';
  getCityByPostalCode(event: any): void {
    let postalCode = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getCity'] + '/' + postalCode).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          this.cityName = response['data'];
          this.onChange('city');
        } else {
          this.cityName = '';
        }
      },
      err => {
        this.app.spinner.hide();
        console.log('get city by postal code err', err)
      }
    );
  }


  /**
   * Get profile
   */
  userBioforCunt: any;
  seniorBioforCunt: any;
  cleanerBioforCunt: any;
  less20userBio: boolean;
  less20seniorBio: boolean;
  less20cleanerBio: boolean;
  userRank = 0;
  getProfile(): void {
    this.app.spinner.show();
    let userId = localStorage.getItem("userId");
    this.apiService.getData(Constant.SLUGS["getProfile"] + "/" + userId).then(
      response => {
        this.app.spinner.hide();
        if (response["resCode"] == 200) {
          // console.log("profile ", response);
          this.data = response["data"];
          // Choose areas
          if (this.data.chooseAreas.length > 0) {
            // this.chosenAreas = this.data.chooseAreas;
            this.dbAreas = this.data.chooseAreas;
          }
          // Set helper name
          this.helperName = this.data.first_name;

          this.userRank = this.data.avgReview;
          // Set status message
          this.status = this.data.status;
          if (this.status == 0) {
            this.statusMsg = "Your profile is not approved yet.";
            this.statusDisable = true;
          } else if (this.status == 3) {
            this.statusMsg = "Your profile is rejected now.";
            this.statusDisable = true;
          }
          // Check reminders value
          // if (this.data.reminder_by == 0) {
          //   this.profileFormGroup.get("reminderEmail").patchValue(true);
          // } else if (this.data.reminder_by == 1) {
          //   this.profileFormGroup.get("reminderSms").patchValue(true);
          // } else {
          //   this.profileFormGroup.get("reminderEmail").patchValue(true);
          //   this.profileFormGroup.get("reminderSms").patchValue(true);
          // }
          //this.profileFormGroup.get("notification").patchValue("2");

          this.profileFormGroup.get("email").patchValue(this.data.email);
          this.notification = this.data.notification_by;

          this.profileFormGroup.get("notification").patchValue(this.data.notification_by)

          this.reminder = this.data.reminder_by;
          this.profileFormGroup.get("reminder").patchValue(this.data.reminder_by)
          // Patch form control value
          this.profileFormGroup.get("hourlyRate").patchValue(this.data.hourly_rate);
          this.profileFormGroup.get("cpr").patchValue(this.data.cpr);
          this.profileFormGroup.get("cvr").patchValue(this.data.cvr);
          this.profileFormGroup.get("regNo").patchValue(this.data.reg_no);
          this.profileFormGroup.get("acNo").patchValue(this.data.ac_no);
          this.profileFormGroup.get("streetLine").patchValue(this.data.street_line);
          this.profileFormGroup.get("addressNear").patchValue(this.data.address_near);
          this.profileFormGroup.get("addressFloor").patchValue(this.data.address_floor);
          this.profileFormGroup.get("city").patchValue(this.data.city);

          
          this.profileFormGroup.get("userBio").patchValue(this.data.user_bio);
          this.userBioforCunt = this.data.user_bio;
          //if (this.data.user_bio.length > 20) { this.less20userBio = true }

         
          this.profileFormGroup.get("senioruserBio").patchValue(this.data.helping_seniros_description);
          this.seniorstatus = this.data.helping_seniors_status;
          this.seniorBioforCunt = this.data.helping_seniros_description;
          // if (this.data.helping_seniros_description.length > 20) { this.less20seniorBio = true }

          
          this.profileFormGroup.get("cleaneruserBio").patchValue(this.data.helping_cleaner_description);
          this.cleanerstatus = this.data.helping_cleaner_status;
          this.cleanerBioforCunt = this.data.helping_cleaner_description;
          //if (this.data.helping_cleaner_description.length > 20) { this.less20cleanerBio = true }

          // Remove country code
          let phone = this.data.phone.substr(3);
          this.profileFormGroup.get("phone").patchValue(phone);

          //this.isMessage = this.data.user_bio.length;
          this.profileFormGroup.get("firstName").patchValue(this.data.first_name);
          // this.profileFormGroup.get("lastName").patchValue(this.data.last_name);
          this.profileFormGroup.get("postalCode").patchValue(this.data.postal_code);

          this.saveFormDisabled = false;
          
         
        } else {
          this.toastr.warning(response["resMsg"], "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        }
        this.imgURL = this.data.imageUrl;
        this.seniorimgURL = this.data.helping_seniors_photo;
        this.cleanerimgURL = this.data.helper_cleaner_photo;
      },
      err => {
        this.app.spinner.hide();
        // console.log("get profile err", err);
        this.toastr.warning(
          "Problem in fetching the data. Please try again.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
      }
    );
  }

  saveFormDisabled = true;
  personalInformationChange(fieldName: string): void {
    this.validationService.setForm(this.profileFormGroup);
    if (this.validationService.validate()) {
      this.saveFormDisabled = false;
      this.validationService.removeError(fieldName);
    } else {
      this.validationService.removeErrors();
      if (this.profileFormGroup.get(fieldName).status == "INVALID") {
        this.validationService.displayErrors('helperProfileFrom', fieldName);
      } else {
        this.validationService.removeError(fieldName);
      }
      this.saveFormDisabled = true;
    }
  }

  updateBankAccountInfo() {
    var regNo = this.profileFormGroup.get("regNo").value;
    var acNo = this.profileFormGroup.get("acNo").value;
    if (!regNo || !acNo) {
      this.toastr.error("Please enter Account number or reg no.", "Account Information");
      return;
    }

    var data = {
      reg_no: regNo,
      ac_no: acNo
    }
    var URL = "update-bank-account?token=" + localStorage.getItem("authToken");
    this.app.spinner.show();
    this.apiService.postData(URL, data).then(
      response => {
        this.app.spinner.hide();
        if (response["resCode"] == 200) {
          this.toastr.success("Successfully updated bank info.", "Bank Info");
        } else {
          this.toastr.warning(response["resMsg"], "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        }
      },
      err => {
        console.log("back update error", err);
        this.app.spinner.hide();
        this.toastr.error(
          "Problem in updating the data. Please try again.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
      }
    );
  }

  /**
   * Submit profile form
   */
  onSubmit(): void {
    this.validationService.setForm(this.profileFormGroup);
    if (this.validationService.validate()) {
      this.onChange();
      // console.log("valid");
      if (this.imageErr) {
        this.toastr.warning(
          "Please select image with size less than 2MB.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
      } else {
        let cpr, cvr, phoneNumber;

        let notification_email = "false";
        let notification_sms = "false";

        if (this.profileFormGroup.get("notification").value == '0') {
          notification_email = "true";
          notification_sms = "false";
        }
        else if (this.profileFormGroup.get("notification").value == '1') {
          notification_email = "false";
          notification_sms = "true";
        }

        cpr = this.profileFormGroup.get("cpr").value ? this.profileFormGroup.get("cpr").value : "";
        cvr = this.profileFormGroup.get("cvr").value ? this.profileFormGroup.get("cvr").value : "";

        let reminder_email = "false";
        let reminder_sms = "false";
        let reminder_nothanks = "false";

        if (this.profileFormGroup.get("reminder").value == '0') {
          reminder_email = "true";
          reminder_sms = "false";
          reminder_nothanks = "false";
        }
        else if (this.profileFormGroup.get("reminder").value == '1') {
          reminder_email = "false";
          reminder_sms = "true";
          reminder_nothanks = "false";
        }
        else if (this.profileFormGroup.get("reminder").value == '2') {
          reminder_email = "false";
          reminder_sms = "false";
          reminder_nothanks = "true";
        }
        // Concate phone code
        phoneNumber = this.profileFormGroup.get("phone_code").value + this.profileFormGroup.get("phone").value;
        // Build object
        let fileData: FormData = new FormData();
        fileData.append("first_name", this.profileFormGroup.get("firstName").value);
        fileData.append("last_name", '');
        fileData.append("street_line", this.profileFormGroup.get("streetLine").value);
        fileData.append("addressNear", this.profileFormGroup.get("addressNear").value); // new field
        fileData.append("addressFloor", this.profileFormGroup.get("addressFloor").value); // new field

        fileData.append("postal_code", this.profileFormGroup.get("postalCode").value);
        fileData.append("city", this.profileFormGroup.get("city").value);
        fileData.append("email", this.profileFormGroup.get("email").value);
        fileData.append("phone", phoneNumber);
        fileData.append("hourly_rate", this.profileFormGroup.get("hourlyRate").value);
        fileData.append("cpr", cpr);
        fileData.append("cvr", cvr);
        fileData.append("old_password", this.profileFormGroup.get("oldPassword").value);
        fileData.append("password", this.profileFormGroup.get("password").value);
        fileData.append("confirm_password", this.profileFormGroup.get("confirmPassword").value);
        fileData.append("reg_no", this.profileFormGroup.get("regNo").value);
        fileData.append("ac_no", this.profileFormGroup.get("acNo").value);
        fileData.append("status", this.status.toString());
        //  / fileData.append("image", this.imgURL);
        fileData.append("user_bio", this.profileFormGroup.get("userBio").value);
        // fileData.append("reminder_email", this.profileFormGroup.get("reminderEmail").value);
        // fileData.append("reminder_sms", this.profileFormGroup.get("reminderSms").value);
        fileData.append("reminder_email", reminder_email); 
        fileData.append("reminder_sms", reminder_sms);
        fileData.append("reminder_nothanks", reminder_nothanks); // new field

        fileData.append("notification_email", notification_email); // new field
        fileData.append("notification_sms", notification_sms); // new field
        fileData.append('helping_seniors_status', this.seniorstatus);
        fileData.append('helping_seniros_description', this.profileFormGroup.get('senioruserBio').value);
        fileData.append('helping_cleaner_status', this.cleanerstatus);
        fileData.append('helping_cleaner_description', this.profileFormGroup.get('cleaneruserBio').value);
        fileData.append("helping_seniors_photo", this.seniorimgURL);
        fileData.append("helper_cleaner_photo", this.cleanerimgURL);
        this.app.spinner.show();
        this.apiService
          .postDataWithAttachment(Constant.SLUGS["updateProfile"], fileData)
          .then(
            response => {
              this.app.spinner.hide();
              if (response["resCode"] == 200) {

                if (this.isUserImage == true) {
                  this.uploadImage();
                }
                this.isUserImage = false;
                if (this.isSeniorImage == true) {
                  this.uploadSeniorImage();
                }
                this.isSeniorImage == false;
                if (this.isCleanerImage == true) {
                  this.uploadCleanerImage();
                }
                this.isCleanerImage = false;
                this.profileFormGroup.reset();
                this.profileFormGroup.get("phone_code").patchValue("+45");
                this.toastr.success(response["resMsg"], "", {
                  timeOut: Constant.ERROR_TIMEOUT,
                  positionClass: Constant.MESSAGE_POSITION,
                  closeButton: true
                });
                this.getProfile();
                 this.ngOnInit();
              } else {
                this.toastr.warning(response["resMsg"], "", {
                  timeOut: Constant.ERROR_TIMEOUT,
                  positionClass: Constant.MESSAGE_POSITION,
                  closeButton: true
                });
              }
            },
            err => {
              // console.log("update profile err", err);
              this.app.spinner.hide();
              this.toastr.error(
                "Problem in fetching the data. Please try again.",
                "",
                {
                  timeOut: Constant.ERROR_TIMEOUT,
                  positionClass: Constant.MESSAGE_POSITION,
                  closeButton: true
                }
              );
            }
          );
      }
    } else {
      // console.log("invalid");
      this.validationService.removeErrors();
      this.validationService.displayErrors("profileFrom");
      this.validationService.setFoucsOfForm(this.profileFormGroup.controls);
    }
  }

  public seniorimgURL: any;
  public seniorFileToUpload: File = null;
  public seniorImageErr: boolean = false;
  handleSeniorFileInput(files: FileList) {
    if (files.item(0)) {
      this.onChange("seniorimage");
      if (files.item(0).size < 5242880) {
        this.profileFormGroup.controls["seniorimage"].setErrors(null);
        this.seniorFileToUpload = files.item(0);
        this.seniorImageErr = false;
        var reader = new FileReader();
        reader.readAsDataURL(this.seniorFileToUpload);
        reader.onload = (_event) => {
          this.seniorimgURL = reader.result;
        }
      } else {
        this.profileFormGroup.controls["seniorimage"].setErrors({
          bigImage: { maxSize: "2MB" }
        });
        this.toastr.warning(
          "Please select image with size less than 2MB.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
        this.seniorImageErr = true;
      }
    } else {
      this.profileFormGroup.controls["seniorimage"].setErrors(null);
      this.seniorImageErr = false;
    }
  }

  public cleanerimgURL: any;
  public cleanerFileToUpload: File = null;
  public cleanerImageErr: boolean = false;
  handleCleanerFileInput(files: FileList) {
    if (files.item(0)) {
      this.onChange("cleanerimage");
      if (files.item(0).size < 5242880) {
        this.profileFormGroup.controls["cleanerimage"].setErrors(null);
        this.cleanerFileToUpload = files.item(0);
        this.cleanerImageErr = false;
        var reader = new FileReader();
        reader.readAsDataURL(this.cleanerFileToUpload);
        reader.onload = (_event) => {
          this.cleanerimgURL = reader.result;
        }
      } else {
        this.profileFormGroup.controls["cleanerimage"].setErrors({
          bigImage: { maxSize: "2MB" }
        });
        this.toastr.warning(
          "Please select image with size less than 2MB.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
        this.cleanerimgURL = true;
      }
    } else {
      this.profileFormGroup.controls["cleanerimage"].setErrors(null);
      this.cleanerImageErr = false;
    }
  }

  /**
   * Handle uploading photo
   * @param files
   */
  public imgURL: any;
  handleFileInput(files: FileList) {
    if (files.item(0)) {
      this.onChange("image");
      if (files.item(0).size < 5242880) {
        this.profileFormGroup.controls["image"].setErrors(null);
        this.fileToUpload = files.item(0);
        this.imageErr = false;
        var reader = new FileReader();
        reader.readAsDataURL(this.fileToUpload);

        reader.onload = (_event) => {
          this.imgURL = reader.result;
        }
      } else {
        this.profileFormGroup.controls["image"].setErrors({
          bigImage: { maxSize: "2MB" }
        });
        this.toastr.warning(
          "Please select image with size less than 2MB.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
        this.imageErr = true;
      }
    } else {
      this.profileFormGroup.controls["image"].setErrors(null);
      this.imageErr = false;
    }
  }

  /**
   * Set password validaton if password will change
   */
  passwordValidation(): void {
    if (this.profileFormGroup.controls["oldPassword"].value != "") {
      // Password
      if (this.profileFormGroup.controls["password"].value == "") {
        this.profileFormGroup.controls["password"].setValidators([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          this.validationService.passwordCriteria()
        ]);
        this.profileFormGroup.controls["password"].setErrors({
          required: true
        });
      }
      // Confirm password
      if (this.profileFormGroup.controls["confirmPassword"].value == "") {
        this.profileFormGroup.controls["confirmPassword"].setValidators([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30)
        ]);
        this.profileFormGroup.controls["confirmPassword"].setErrors({
          required: true
        });
      }
    } else {
      this.profileFormGroup.controls["password"].clearValidators();
      this.profileFormGroup.controls["password"].setErrors(null);
      this.onChange("password");
      this.profileFormGroup.controls["confirmPassword"].clearValidators();
      this.profileFormGroup.controls["confirmPassword"].setErrors(null);
      this.onChange("confirmPassword");
    }
  }

  downloadContractPdf() {
    download()
  }

  /**
   * Remove validation errors
   * @param field
   */
  onChange(field = null): void {
    //disable cvr
    if (field == 'cpr') {
      if (this.profileFormGroup.get('cpr').value != '') {
        this.profileFormGroup.controls['cvr'].clearValidators();
        //this.profileFormGroup.controls['cvr'].setErrors(null);
        this.profileFormGroup.get('cvr').disable();
      } else {
        this.profileFormGroup.get('cvr').enable();
        this.profileFormGroup.controls['cvr'].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]);
        //this.profileFormGroup.controls['cvr'].setErrors({ required: true });
      }
    }
    //disable cpr
    if (field == 'cvr') {
      if (this.profileFormGroup.get('cvr').value != '') {
        this.profileFormGroup.controls['cpr'].clearValidators();
        //this.profileFormGroup.controls['cpr'].setErrors(null);
        this.profileFormGroup.get('cpr').disable();
      } else {
        this.profileFormGroup.get('cpr').enable();
        this.profileFormGroup.controls['cpr'].setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]);
        //this.profileFormGroup.controls['cpr'].setErrors({ required: true });
      }
    }
    this.validationService.removeErrors(field);
  }

  /**
   * Get regions
   */
  getRegion(): void {
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS["getRegions"]).then(
      response => {
        this.app.spinner.hide();
        this.regions = response;
      },
      err => {
        this.app.spinner.hide();
        // console.log("region err", err);
      }
    );
  }

  /**
   * Get cities of region
   * @param event
   */
  selectedRegion = '';
  selectedCity = '';
  getCities(event: any): void {
    // this.areas = [];
    let regionName = event.target.value;
    this.app.spinner.show();
    this.apiService
      .getData(Constant.SLUGS["getCities"] + "/" + regionName)
      .then(
        response => {
          this.app.spinner.hide();
          this.cities = response;
        },
        err => {
          this.app.spinner.hide();
          console.log("cities err", err);
        }
      );
  }

  /**
   * Get areas of city
   * @param event
   */
  getAreaName(event: any): void {
    this.selectedDbAreas = this.selectedAreas;
    let cityName = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS["getAreas"] + "/" + cityName).then(
      response => {
        this.app.spinner.hide();
        console.log("area res", response);
        if (response["resCode"] == 200) {
          this.areas = response["data"];
          console.log("areas", this.areas);
        }
      },
      err => {
        this.app.spinner.hide();
        console.log("area err", err);
      }
    );
  }

  /**
   * Check is area already selected or not
   * @param area
   */
  isAreaChoosed(area): boolean {
    let keepContinue = false;
    this.selectedAreas.forEach(item => {
      if (!keepContinue) {
        if (item.area_name == area.area_name) {
          keepContinue = true;
        }
      }
    });
    this.dbAreas.forEach(item => {
      if (!keepContinue) {
        if (item.area_name == area.area_name) {
          keepContinue = true;
        }
      }
    });
    return keepContinue;
  }

  /**
   * Check all the area slected or not.
   * @param boolean
   */
  isAllAreaSelected(area): boolean {
    var selectedAreaNames = [];
    for (var i = 0; i < area.length; i++) {
      selectedAreaNames.push(area[i]["area_name"]);
    }
    var allAreaNames = [];
    for (var i = 0; i < this.selectedAreas.length; i++) {
      allAreaNames.push(this.selectedAreas[i]["area_name"]);
    }
    for (var i = 0; i < this.dbAreas.length; i++) {
      allAreaNames.push(this.dbAreas[i]["area_name"]);
    }
    var containsAll = selectedAreaNames.every(i => allAreaNames.includes(i));
    return containsAll;
  }

  toggleExtraAreas(status) {
    if (status) {
      this.isExtraAreaDisplay = false;
    } else {
      this.isExtraAreaDisplay = true;
    }
  }

  mapAreas = []
  initalizationMap() {
    var self = this;
    self.mapAreas = [];
    setTimeout(function () {
      initMap(function (res) {
        var arr = self.mapAreas.filter(function (resposne) {
          return resposne.postal_code == res.postal_code;
        });
        if (arr.length == 0) {
          self.mapAreas.push(res);
        } else {
          $.each(self.mapAreas, function (i) {
            if (self.mapAreas[i] === arr[0]) {
              self.mapAreas.splice(i, 1);
              return false;
            }
          });
        }
      });
    }, 500)

  }
  removeAreaFromMap(mapArea) {
    removeFeatureFromMap(mapArea.feature);
    var self = this;
    $.each(this.mapAreas, function (i) {
      if (this.postal_code === mapArea.postal_code) {
        self.mapAreas.splice(i, 1);
        return false;
      }
    });
  }

  isMapIntialize = false;
  chooseMoreAreas() {
    this.isShowMapDetail = !this.isShowMapDetail
    var self = this;
    if (!this.isMapIntialize) {
      this.isMapIntialize = true;
      this.initalizationMap();
    }

  }

  /**
   * Choose area
   * @param area
   */
  addArea(area): void {
    if (this.selectedAreas.length > 0) {
      let keepContinue = true;
      this.selectedAreas.forEach(item => {
        if (keepContinue) {
          // If area has alread added
          if (item == area) {
            keepContinue = false;
          } else {
            this.selectedAreas.push(area);
            keepContinue = false;
          }
        }
      });
    } else {
      this.selectedAreas.push(area);
    }
  }

  /**
   * Remove area from selection
   * @param area
   */
  removeArea(area): void {
    let server = this.dbAreas.find(x => x.area_name === area.area_name);
    if (server === undefined) {
      this.selectedAreas.forEach((item, index) => {
        let keepContinue = true;
        if (keepContinue) {
          // Remove selected area
          if (item.area_name == area.area_name) {
            this.selectedAreas.splice(index, 1);
            keepContinue = false;
          }
        }
      });
      console.log("remove area", this.selectedAreas);
    } else {
      this.dbAreas.forEach((item, index) => {
        let keepContinue = true;
        if (keepContinue) {
          // Remove selected area
          if (item.area_name == area.area_name) {
            this.dbAreas.splice(index, 1);
            var data = area;
            this.app.spinner.show();
            this.apiService.postData(Constant.SLUGS["removeArea"], data).then(
              response => {
                this.app.spinner.hide();
                if (response["resCode"] == 200) {
                  this.dbAreas = [];
                  this.dbAreas = response["data"];
                  keepContinue = false;
                } else {
                  this.toastr.warning(response["resMsg"], "", {
                    timeOut: Constant.ERROR_TIMEOUT,
                    positionClass: Constant.MESSAGE_POSITION,
                    closeButton: true
                  });
                }
              },
              err => {
                console.log("register err", err);
                this.app.spinner.hide();
                this.toastr.error(
                  "Problem in fetching the data. Please try again.",
                  "",
                  {
                    timeOut: Constant.ERROR_TIMEOUT,
                    positionClass: Constant.MESSAGE_POSITION,
                    closeButton: true
                  }
                );
              }
            );
          }
        }
      });
    }
  }

  /**
   * remove areas of city from database
   */
  removeAreaDb(area) {
    this.dbAreas.forEach((item, index) => {
      let keepContinue = true;
      if (keepContinue) {
        // Remove selected area
        if (item.area_name == area.area_name) {
          this.dbAreas.splice(index, 1);
          var data = area;
          this.app.spinner.show();
          this.apiService.postData(Constant.SLUGS["removeArea"], data).then(
            response => {
              this.app.spinner.hide();
              if (response["resCode"] == 200) {
                this.dbAreas = [];
                this.dbAreas = response["data"];
                keepContinue = false;
              } else {
                this.toastr.warning(response["resMsg"], "", {
                  timeOut: Constant.ERROR_TIMEOUT,
                  positionClass: Constant.MESSAGE_POSITION,
                  closeButton: true
                });
              }
            },
            err => {
              console.log("register err", err);
              this.app.spinner.hide();
              this.toastr.error(
                "Problem in fetching the data. Please try again.",
                "",
                {
                  timeOut: Constant.ERROR_TIMEOUT,
                  positionClass: Constant.MESSAGE_POSITION,
                  closeButton: true
                }
              );
            }
          );
        }
      }
    });
  }

  /**
   * Choose all areas of city
   */
  chooseAllAreas(): void {
    this.selectedAreas = [];
    this.areas.forEach(item => {
      let server = this.dbAreas.find(x => x.area_name === item.area_name);
      if (server === undefined) {
        this.selectedAreas.push(item);
      }
    });
    console.log("selected area", this.selectedAreas);
  }

  /**
   * Remove all areas of city
   */
  removeAllAreas(): void {
    this.selectedAreas = [];
    this.selectedDbAreas.forEach(item => {
      if (item.user_id) {
        this.selectedAreas.push(item);
      }
    });
    this.selectedDbAreas = [];
    this.selectedDbAreas = this.selectedAreas;
  }

  /**
   *  Update user selected areas.
   */
  updateAreas(): void {
    for (var m = 0; m < this.mapAreas.length; m++) {
      var mapArea = this.mapAreas[m];
      var alreadyData = this.selectedAreas.filter(function (resposne) {
        return resposne.postal_code == mapArea.postal_code;
      });

      if (alreadyData.length == 0) {
        var obj = {
          area_name: mapArea.area_name,
          id: 0,
          postal_code: mapArea.postal_code
        }
        this.selectedAreas.push(obj);
      }
    }
    if (this.selectedAreas[0]) {
      var localdata = this.selectedAreas;
      for (var i = 0; i < this.dbAreas.length; i++) {
        localdata.push(this.dbAreas[i]);
      }

      var data = {
        id: localStorage.getItem("userId"),
        data: localdata
      };
      this.app.spinner.show();
      this.apiService.postData(Constant.SLUGS["updateAreas"], data).then(
        response => {
          this.app.spinner.hide();
          if (response["resCode"] == 200) {
            this.areas = [];
            this.selectedAreas = [];
            this.isExtraAreaDisplay = false;
            this.dbAreas = [];
            this.mapAreas = [];
            this.selectedRegion = '';
            this.selectedCity = '';
            this.initalizationMap();
            this.dbAreas = response["data"];
            //this.router.navigateByUrl('/helper/profile00');
            this.toastr.success(response["resMsg"], "", {
              timeOut: Constant.ERROR_TIMEOUT,
              positionClass: Constant.MESSAGE_POSITION,
              closeButton: true
            });
          } else {
            this.toastr.warning(response["resMsg"], "", {
              timeOut: Constant.ERROR_TIMEOUT,
              positionClass: Constant.MESSAGE_POSITION,
              closeButton: true
            });
          }
        },
        err => {
          console.log("register err", err);
          this.app.spinner.hide();
          this.toastr.error(
            "Problem in fetching the data. Please try again.",
            "",
            {
              timeOut: Constant.ERROR_TIMEOUT,
              positionClass: Constant.MESSAGE_POSITION,
              closeButton: true
            }
          );
        }
      );
      return;
    } else {
      this.toastr.error("No more areas were selected.", "", {
        timeOut: Constant.ERROR_TIMEOUT,
        positionClass: Constant.MESSAGE_POSITION,
        closeButton: true
      });
    }
  }

  /**
   * Get user reviews
   */
  public customer_reviewes = [];
  public user_reviewes = [];
  getUserReviews(): void {
    this.apiService.getData(Constant.SLUGS["userReviews"]).then(
      response => {
        if (response["user_reviews"]) {
          if(response["user_reviews"].length > 0 ){
            this.customer_reviewes = response["user_reviews"];
          }
        } 
        if (response["customer_reviews"]) {
          if(response["customer_reviews"].length > 0){
            this.customer_reviewes = response["customer_reviews"];
          }
        }
        else {
          this.customer_reviewes = [];
          this.user_reviewes = [];
          // this.toastr.error(response["resMsg"], 'Reviews');
        }
        console.log(response);
      },
      err => {
        this.customer_reviewes = [];
        this.user_reviewes = [];
        this.toastr.error(err.message, "", {
          timeOut: Constant.ERROR_TIMEOUT,
          positionClass: Constant.MESSAGE_POSITION,
          closeButton: true
        });
        console.log("region err", err);
      }
    );
  }

  review_ratings = 0;
  onRate($event: { oldValue: number, newValue: number }) {
    console.log($event);
    this.review_ratings = $event.newValue;
  }

  // onRate(
  //   $event: {
  //     oldValue: number;
  //     newValue: number;
  //     starRating: StarRatingComponent;
  //   },
  //   dataId: number
  // ) {
  //   var data = {
  //     id: dataId,
  //     review: `${$event.newValue}`
  //   };
  //   this.app.spinner.show();
  //   this.apiService.postData(Constant.SLUGS["updateReviews"], data).then(
  //     response => {
  //       this.app.spinner.hide();
  //       if (response["resCode"] == 200) {
  //         this.toastr.success(response["resMsg"], "", {
  //           timeOut: Constant.ERROR_TIMEOUT,
  //           positionClass: Constant.MESSAGE_POSITION,
  //           closeButton: true
  //         });
  //       } else {
  //         this.toastr.warning(response["resMsg"], "", {
  //           timeOut: Constant.ERROR_TIMEOUT,
  //           positionClass: Constant.MESSAGE_POSITION,
  //           closeButton: true
  //         });
  //       }
  //     },
  //     err => {
  //       console.log("register err", err);
  //       this.app.spinner.hide();
  //       this.toastr.error("Problem in update the data. Please try again.", "", {
  //         timeOut: Constant.ERROR_TIMEOUT,
  //         positionClass: Constant.MESSAGE_POSITION,
  //         closeButton: true
  //       });
  //     }
  //   );
  // }

  /**
   * Edit photo button click
   */
  editPhoto() {
    this.router.navigateByUrl("/helper/upload-image");
  }

  /**
   * See statement button click
   */
  seeStatement(button) {
    if (button == true) {
      this.statementButton = false;
    } else {
      this.statementButton = true;
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var currentDate = new Date();
      var month = monthNames[currentDate.getMonth()];
      this.monthDropdown = [
        monthNames[currentDate.getMonth() - 1],
        monthNames[currentDate.getMonth()],
        monthNames[currentDate.getMonth() + 1],
        monthNames[currentDate.getMonth() + 2]
      ];
      this.getDataBooking(month);
    }
  }

  /**
   * get booking data
   * @param month 
   */
  getDataBooking(month = null) {
    this.monthSelected = month;
    let data = [this.monthSelected];
    this.apiService.postData(Constant.SLUGS["getBookingData"], data).then(
      response => {
        if (response["resCode"] == 200) {
          this.bookingData = response["data"];
          if (this.bookingData[0]) {
            this.noBookingData = false;
          } else {
            this.noBookingData = true;
          }
        } else {
          this.toastr.warning(response["resMsg"], "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        }
      },
      err => {
        console.log("register err", err);
        this.app.spinner.hide();
        this.toastr.error(
          "Problem in fetching the data. Please try again.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
      }
    );
  }

  /**
   * set button to false
   */
  bookings() {
    this.statementButton = false;
  }

  /**
   * get booking order information
   */
  bookingOrderInfo() {
    let data = [localStorage.getItem("userId")];
    this.apiService.postData(Constant.SLUGS["getBookingOrder"], data).then(
      response => {
        if (response["resCode"] == 200) {
          this.bookingOrder = response["data"];
          if (this.bookingOrder[0]) {
            this.noBookingOrder = false;
          } else {
            this.noBookingOrder = true;
          }
        } else {
          this.toastr.warning(response["resMsg"], "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        }
      },
      err => {
        console.log("register err", err);
        this.app.spinner.hide();
        this.toastr.error(
          "Problem in fetching the data. Please try again.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
      }
    );
  }

  closePopover() {
    jQuery(this.calendarEle).popover('hide');
  }

  /**
   * load full calendar
   */
  selectedDate = new Date();
  loadFullCalendar() {
    let self = this;
    if (this.bookingOrder) {
      var mdate = [];
      this.bookingOrder.forEach(item => {
        if (this.eventAllData.length != 0){
          var length = this.eventAllData[this.eventAllData.length - 1].id;
        }
         else{
          length = 0;
         }
        let obj = {
          'title': "",
          'id': length + 1,
          'start': item.cleaning_date + "T00:00:00",
          'end': item.cleaning_date + "T23:59:00",
          'allDay': false,
          'color': "#66b5b5"

        }
        this.eventAllData.push(obj);
      });
    }
    $("#full-calendar").fullCalendar(
      {
        header: {
          left: "title",
          right: "agendaWeek,agendaDay"
        },
        defaultView: "agendaWeek",
        defaultDate: self.selectedDate,
        firstDay: 1,
        navLinks: true,
        editable: false,
        eventLimit: true,
        events: this.eventAllData,
        displayEventTime: false,
        locale: 'da',
        eventRender: function (event, element) {
          //var dStart = event.start.format("DD MMMM");
          var event_div = jQuery("#event_popover")[0].innerHTML;
          jQuery(element).popover({
            animation: true,
            placement: 'top',
            html: true,
            trigger: 'click',
            content: event_div,
            container: 'body'
          });

        },
        eventClick: function (calEvent, jsEvent, view) {
          jQuery(".popover .error-message").hide();
          self.eventData = calEvent;
          self.isCalendarAdd = false;
          // jQuery('.popover').popover('hide');
          if (calEvent.start > now) {
            self.pastEvent = false;
          }
          else {
            self.pastEvent = true;
          }

          if (self.calendarEle) {
            jQuery(self.calendarEle).popover("hide");
          }

          self.calendarEle = this;

          self.createCalendarForm();
          $('.popover-content').addClass('staticPositionPopover');
          ////jQuery('div.popover').css('min-width','385px');

          let $this = $(this);
          // setTimeout(() => {
          //   //self.myPopover.show();

          // }, 2000);
          // $this.popover({
          //     html:true,
          //     title:calEvent.title,
          //     content:calEvent.content,
          //     trigger:'click',
          //     placement:'top',
          //     container:'body'
          // }).popover();
          // return false;  
        }
      }
    );
    self.changeDate();
    setTimeout(() => {
      self.calendar._goToDateInView(new Date(), "month");
    }, 100);
  }

  changeDate() {
    const today = new Date();
    let future = new Date();
    future.setDate(today.getDate() + 31);
    this.calendar._goToDateInView(future, "month");
  }

  gotoDate(event) {
    $("#full-calendar").fullCalendar('gotoDate', event);
  }

  /**
   * after tab click
   * @param tab 
   */
  tabClick(tab) {
    this.selectedTab = tab.index;
    if (tab.index == 5) {
      this.getAllEvents();
    }

    if (jQuery('[data-toggle="tooltip"]').length > 0) {
      initTooltip();
    }
  }


  getStartValue(time) {
    if (time && !this.allDaySwitch) {
      var setTime = time.hour + '-' + time.minute;
      this.calendarForm.get('startInputTime').patchValue(setTime)
      return
    } else {
      var setTime = '-'
      this.calendarForm.get('startInputTime').patchValue(setTime)
      return
    }
  }

  getEndValue(time) {
    if (time && !this.allDaySwitch) {
      var setTime = time.hour + '-' + time.minute;
      this.calendarForm.get('endInputTime').patchValue(setTime)
      return
    } else {
      var setTime = '-'
      this.calendarForm.get('endInputTime').patchValue(setTime)
      return
    }
  }


  /**
   * create calendar event form
   */
  starttime: NgbTimeStruct = { hour: 9, minute: 0, second: 0 };
  endtime: NgbTimeStruct = { hour: 18, minute: 0, second: 0 };
  hourStep = 1;
  minuteStep = 5;
  isAllDay = false;
  isRepeat = false;
  repeatFor = [
    {
      "key": "Dag",
      "value": "Day"
    },
    {
      "key": "Uge",
      "value": "Week"
    },
    {
      "key": "Month",
      "value": "Month"
    }
  ];

  calendarEle: any;
  createCalendarForm() {
    var dates = new Date();
    if (this.datePickerDate != undefined) {
      let datePickerProperDate = new Date(this.datePickerDate['year'] + '-' + this.datePickerDate['month'] + '-' + this.datePickerDate['day']);
      if (datePickerProperDate >= dates) {
        var dates = datePickerProperDate;
      }
    }
    var datesArray = { year: dates.getFullYear(), month: dates.getMonth() + 1, day: dates.getDate() };
    this.datePickerConfig.minDate = {
      year: now.getFullYear(), month:
        now.getMonth() + 1, day: now.getDate()
    };
    // let starttime = { hour: 9, minute: 0 };
    // let endtime = { hour: 18, minute: 0};
    this.calendarForm = this._formBuilder.group({
      startdate: [datesArray, [Validators.required]],
      enddate: [datesArray, [Validators.required]],
      starttime: [this.starttime, [Validators.required]],
      startInputTime: ['', [Validators.required]],
      endtime: [this.endtime, [Validators.required]],
      endInputTime: ['', [Validators.required]],
      allDay: [false],
      repeat: [false],
      repeatEvery: [1],
      repeatSelect: ["Day"],
      endingDate: [this.model]
    });
    if (this.eventData) {
      if (this.eventData.id) {
        this.isCalendarAdd = false;
        this.apiService
          .getData(Constant.SLUGS["eventDetails"] + "/" + this.eventData.id)
          .then(
            response => {
              if (response["resCode"] == 200) {
                this.eventClickData = response['data'];
                let calendarData = response["data"];
                // assign value to formgroup
                this.calendarForm.patchValue({
                  startdate: calendarData["start_date"],
                  enddate: calendarData["end_date"],
                  starttime: calendarData["start_time"],
                  endtime: calendarData["end_time"],
                  allDay: calendarData["all_day"],
                  repeat: calendarData["repeat_value"],
                  repeatEvery: calendarData["repeat_every_value"],
                  repeatSelect: calendarData["repeat_every_select"],
                  endingDate: calendarData["event_ending_date"]
                });

                jQuery(this.calendarEle).popover("show");
                if (calendarData["start_time"] && calendarData["end_time"]) {
                  var startHour = ("0" + calendarData["start_time"]["hour"]).slice(-2);
                  var endHour = ("0" + calendarData["end_time"]["hour"]).slice(-2);
                  var startMinute = ("0" + calendarData["start_time"]["minute"]).slice(-2);
                  var endMinute = ("0" + calendarData["end_time"]["minute"]).slice(-2);


                  if (startHour == "00" && endHour == "00" && startMinute == "00" && endMinute == "00") {
                    jQuery(".popover #start-end-time-div").hide();
                  } else {
                    jQuery(".popover #start-end-time").text(startHour + ":" + startMinute + "-" + endHour + ":" + endMinute);
                    jQuery(".popover #start-end-time-div").show();
                  }
                } else {
                  jQuery(".popover #start-end-time-div").hide();
                }

                if (this.pastEvent) {
                  jQuery(".popover .error-message").show();
                  jQuery('.popover a.general').attr('disabled', 'disabled');
                } else {
                  jQuery(".error-message").hide();
                  jQuery('.popover a.general').removeAttr('disabled');
                }

                if (calendarData["repeat_value"] == true) {
                  if (calendarData["repeat_every_value"] && calendarData["repeat_every_select"]) {
                    var repeat_every = "Every " + calendarData["repeat_every_value"] + ' ' + calendarData["repeat_every_select"];
                    jQuery(".popover #repeat-every").text(repeat_every);
                    jQuery(".popover #repeat-every-div").show();
                  } else {
                    jQuery(".popover #repeat-every-div").hide();
                  }
                } else {
                  jQuery(".popover #repeat-every-div").hide();
                }

                if (calendarData["start_date"]) {
                  var startDate = calendarData["start_date"]["month"] + "/" + calendarData["start_date"]["day"] + "/" + calendarData["start_date"]["year"];
                  const datePipe = new DatePipe('en-US');
                  var origDate = datePipe.transform(startDate, 'd MMM');
                  jQuery(".popover #start-date").text(origDate);
                  jQuery(".popover #start-date-div").show();
                }
                else {
                  jQuery(".popover #start-date-div").hide();
                }

                if (calendarData["event_ending_date"]) {
                  var startDate = calendarData["event_ending_date"]["month"] + "/" + calendarData["event_ending_date"]["day"] + "/" + calendarData["event_ending_date"]["year"];
                  const datePipe = new DatePipe('en-US');
                  var origDate = datePipe.transform(startDate, 'd MMM');
                  jQuery(".popover #repeat-until-date").text(origDate);
                  jQuery(".popover #repeat-until-date-dic").show();
                }
                else {
                  jQuery(".popover #repeat-until-date-div").hide();
                }

                if (calendarData["repeat_value"] == true) {
                  this.repeatSwitch = true;
                } else {
                  this.repeatSwitch = false;
                }
                if (calendarData["all_day"] == true) {
                  this.allDaySwitch = true;
                } else {
                  this.allDaySwitch = false;
                }
                if (calendarData["start_time"]) {
                  if (
                    calendarData["start_time"]["hour"] == 0 &&
                    calendarData["start_time"]["minute"] == 0
                  ) {
                    this.calendarForm.get('startInputTime').patchValue('-');
                    this.calendarForm.get('endInputTime').patchValue('-');
                    this.dbEventStartTime = { hour: 9, minute: 0, second: 0 };
                  } else {
                    this.dbEventStartTime = calendarData["start_time"];
                  }
                }
                if (calendarData["end_time"]) {
                  if (
                    calendarData["end_time"]["hour"] == 0 &&
                    calendarData["end_time"]["minute"] == 0
                  ) {
                    this.dbEventEndTime = { hour: 18, minute: 0, second: 0 };
                  } else {
                    this.dbEventEndTime = calendarData["end_time"];
                  }
                }
                var this_ = this;
              } else {
                this.toastr.error(response["resMsg"], "", {
                  timeOut: Constant.ERROR_TIMEOUT,
                  positionClass: Constant.MESSAGE_POSITION,
                  closeButton: true
                });
              }
            },
            err => {
              this.toastr.error(
                "Probelm in fetching the data. Please try again",
                "",
                {
                  timeOut: Constant.ERROR_TIMEOUT,
                  positionClass: Constant.MESSAGE_POSITION,
                  closeButton: true
                }
              );
            }
          );
      }
    }
  }

  /**
   * calendar event form validate
   */
  calendarFormSubmit() {

    this.validationService.setForm(this.calendarForm);
    if (this.validationService.validate()) {
      this.onChange();

      //Call to save banner data
      let formData = this.calendarForm.value;
      if (!this.repeatSwitch) {
        formData["repeatEvery"] = null;
        formData["repeatSelect"] = null;
        formData["endingDate"] = null;
      }
      if (this.allDaySwitch) {
        formData["starttime"] = null;
        formData["endtime"] = null;
      }
      this.saveCalendar(formData);
    } else {
      this.validationService.removeErrors();
      this.validationService.displayErrors();
    }
  }

  saveCalendar(formData: object) {
    if (this.isCalendarAdd) {
      //Add new project
      this.saveDataCalendar(Constant.SLUGS["eventCreate"], formData, "added");
    } else {
      //Edit project
      this.saveDataCalendar(
        Constant.SLUGS["eventUpdate"] + "/" + this.eventData.id,
        formData,
        "updated"
      );
    }
  }

  /**
   * save data to database of calendar event
   * @param url 
   * @param formData 
   * @param action 
   */
  saveDataCalendar(url, formData, action) {
    this.apiService.postData(url, formData).then(
      response => {
        if (response["resCode"] == 200) {
          this.eventData = [];
          // this.loadFullCalendar();
          this.modalService.dismissAll();
          $("#full-calendar").fullCalendar("refetchEvents");
          $("#full-calendar").fullCalendar("rerenderEvents");
          $("#full-calendar").fullCalendar("renderEvent", formData, true);
          this.loadFullCalendar();
          localStorage.setItem('selectedIndex', '5');
          location.reload(true);
        }
        else if (response["resCode"] == 500) {
          this.toastr.error(response["resMsg"], "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        } else {
          this.toastr.error("Problem in adding event.", "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        }
      },
      err => {
        this.toastr.error(err["resMsg"], "", {
          timeOut: Constant.ERROR_TIMEOUT,
          positionClass: Constant.MESSAGE_POSITION,
          closeButton: true
        });
        this.modalService.dismissAll();
      }
    );
  }

  /**
   * calendar event delete
   */
  calendarFormDelete() {
    this.apiService
      .getData(Constant.SLUGS["eventDelete"] + "/" + this.eventData.id)
      .then(
        response => {
          if (response["resCode"] == 200) {
            this.eventData = [];
            this.modalService.dismissAll();
            // $('#full-calendar').fullCalendar( 'rerenderEvents' );
            setTimeout(() => {
              $("#full-calendar").fullCalendar(
                "addEventSource",
                this.eventAllData
              );
              $("#full-calendar").fullCalendar("refetchEvents");
            }, 1500);
            this.loadFullCalendar();
            localStorage.setItem('selectedIndex', '5');
            location.reload(true);
            // $('#full-calendar').fullCalendar( 'refetchEvents' );
          } else {
          }
        },
        err => {
          this.toastr.error("Problem in fetching data. Please try again", "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
          this.modalService.dismissAll();
        }
      );
  }

  /**
   * toggle all day switch in event form
   * @param value 
   */
  toggleAllDay(value) {
    if (value == false) {
      this.allDaySwitch = false;
    } else {
      this.allDaySwitch = true;
    }
    let starttime, endtime;
    if (this.allDaySwitch) {
      this.calendarForm.get('starttime').clearValidators();
      this.calendarForm.get('starttime').updateValueAndValidity();
      this.calendarForm.get('endtime').clearValidators();
      this.calendarForm.get('endtime').updateValueAndValidity();
      this.calendarForm.get('startInputTime').patchValue('-')
      this.calendarForm.get('endInputTime').patchValue('-')
      starttime = { hour: 0, minute: 0, second: 0 };
      endtime = { hour: 0, minute: 0, second: 0 };
    } else {
      this.calendarForm.get('starttime').setValidators(Validators.required);
      this.calendarForm.get('starttime').updateValueAndValidity();
      this.calendarForm.get('endtime').setValidators(Validators.required);
      this.calendarForm.get('endtime').updateValueAndValidity();
      if (this.isCalendarAdd) {
        starttime = { hour: 9, minute: 0, second: 0 };
        endtime = { hour: 18, minute: 0, second: 0 };
      } else {
        starttime = this.dbEventStartTime;
        endtime = this.dbEventEndTime;
      }
    }
    // this.calendarForm.patchValue({
    //   starttime: starttime,
    //   endtime: endtime
    // });
  }

  /**
   * toggle repeat switch in calendar event form
   * @param value 
   */
  toggleRepeat(value) {
    if (value == false) {
      this.repeatSwitch = false;
      this.calendarForm.get('repeatEvery').setValidators(Validators.required);
      this.calendarForm.get('repeatEvery').updateValueAndValidity();
      this.calendarForm.get('endingDate').setValidators(Validators.required);
      this.calendarForm.get('endingDate').updateValueAndValidity();
    } else {
      this.repeatSwitch = true;
      this.calendarForm.get('repeatEvery').clearValidators();
      this.calendarForm.get('repeatEvery').updateValueAndValidity();
      this.calendarForm.get('endingDate').clearValidators();
      this.calendarForm.get('endingDate').updateValueAndValidity();
    }
  }

  /**
   * date selected from date picker
   * @param event 
   */
  dateSelected(event) {
    let selectedDate = event.year + "-" + event.month + "-" + event.day;
    let selectedDateProper = new Date(selectedDate);
    $("#full-calendar").fullCalendar("gotoDate", selectedDateProper);
    // this.calendar.fullCalendar('refetchEvents');
  }

  /**
   * month chnage from datepicker
   * @param event 
   */
  monthChange(event) {
    let selectedDate = event.next.month + "-1-" + event.next.year;
    let selectedDateProper = new Date(selectedDate);
    $("#full-calendar").fullCalendar("gotoDate", selectedDateProper);
  }

  /**
   * open new modal
   */
  openNewModal() {
    this.repeatSwitch = false;
    this.allDaySwitch = false;
    this.isCalendarAdd = true;
    this.modalService.open(this.content);
    //jQuery("#eventModal").modal('show');7
    this.eventData = [];
    this.createCalendarForm();
  }

  /**
   * open edit event modal
   */
  editEventModal() {
    this.isCalendarAdd = false;
    this.modalService.open(this.content);
  }

  /**
   * open modal
   * @param content
   */
  open(content) {
    this.eventData = null;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  /**
   * close modal
   * @param reason 
   */
  private getDismissReason(reason: any): string {
    this.eventData = [];
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  /**
   * datepicker has task
   * @param date 
   */
  hasTask(date: NgbDate) {
    if (this.eventAllData) {
      for (let index = 0; index < this.eventAllData.length; index++) {
        var taskDate = new Date(this.eventAllData[index].start);
        var day: number = taskDate.getDate();
        var month: number = taskDate.getMonth() + 1;
        var year: number = taskDate.getFullYear();

        if (day === date.day && month === date.month && year === date.year) {
          return true;
        }
      }
    }
  }

  hideStatementButton = false;
  showHideStatementButton() {
    this.hideStatementButton = false;
    this.app.spinner.show();
    let token = localStorage.getItem("authToken");
    var url = 'get-booking-order';
    var param = {
      token: token
    }
    this.apiService.postData(url, param).then(
      response => {
        this.app.spinner.hide();
        if (response["resCode"] == 200) {
          var orderDetail = response["data"];
          if (orderDetail.length == 0) {
            this.hideStatementButton = true;
          }
        } else {
          this.toastr.error(response["resMsg"], "Error");
        }
      });
  }

  /***
   * Get Booking Order Detail
   */
  getBookingOrderDetail() {
    this.app.spinner.show();
    let token = localStorage.getItem("authToken");
    var url = 'get-booking-order';
    var param = {
      token: token,
      month: this.selectedMonth
    }
    this.apiService.postData(url, param).then(
      response => {
        this.app.spinner.hide();
        if (response["resCode"] == 200) {
          this.chatOrderDetail = response["data"];
          if (this.chatOrderDetail.length > 0) {
            this.showStatement = true;
          }
          this.totalAmount = response["totalAmount"];
        } else {
          this.toastr.error(response["resMsg"], "Error");
        }
      });
  }

  /** Switch Language */
  switchLanguage(isEnglish) {
    localStorage.setItem('Language', isEnglish);
    if (isEnglish) {
      this.router.navigateByUrl('/helper/profile00');
    } else {
      this.router.navigateByUrl('/helper/profile00-dk');
    }
  }

  public helpersCustomers = [];
  public review_comments = '';
  public selectedCustomer = '';
  /**
   * get helper's customer
   */
  getHelpersCustomer() {
    var url = Constant.SLUGS["getHelpersCustomer"] + "?helper_id=" + localStorage.getItem('userId') + "&token=" + localStorage.getItem('authToken');
    this.apiService.getData(url).then(
      response => {
        if (response["resCode"] == 200) {
          this.helpersCustomers = response["data"];
          this.helpersCustomers.unshift({ 'userId': '', 'userName': 'Vælg kunde' })
        } else if (response["resCode"] == 400 && response["resMsg"] == "nodata") {
          //this.toastr.info('No any customer found for you', localStorage.getItem('userName'));
          this.helpersCustomers = [];
          this.helpersCustomers.unshift({ 'userId': '', 'userName': 'Vælg kunde' })
        }
      },
      err => {
        this.helpersCustomers = [];
        this.toastr.error("Problem in fetching events. Please try again", "", {
          timeOut: Constant.ERROR_TIMEOUT,
          positionClass: Constant.MESSAGE_POSITION,
          closeButton: true
        });
      }
    );
  }

  saveReview() {
    if (!this.selectedCustomer) {
      this.toastr.error('Please select customer to add review.', 'Customer Name');
      return;
    }

    if (!this.review_comments) {
      this.toastr.error('Please add your comment to add review.', 'Comment');
      return;
    }

    var postData = {
      "review_id": this.selectedCustomer,
      "user_id": localStorage.getItem("userId"),
      "review_ratings": this.review_ratings,
      "review_comments": this.review_comments,
      "review_status": 1
    }

    var URL = "review-insert?token=" + localStorage.getItem("authToken");
    this.apiService.postData(URL, postData).then(
      response => {
        if (response["resCode"] == 200) {

          this.selectedCustomer = '';
          this.review_ratings = 0;
          this.review_comments = '';
          this.getUserReviews();
          this.getHelpersCustomer();

        } else {
          this.toastr.warning(response["resMsg"], "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        }
      },
      err => {
        console.log("register err", err);
        this.toastr.error(
          "Problem in fetching the data. Please try again.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
      }
    );
  }

  dateClass = (date: Date) => {
    var d = date;
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();
    var formattedDate = curr_date + "/" + curr_month + "/" + curr_year
    if (this.bookingOrder) {
      var mdate = [];
      this.bookingOrder.forEach(item => {
        if (item) {
          var bookingDate = new Date(item.cleaning_date);
          var bookingDate_curr_date = bookingDate.getDate();
          var bookingDate_curr_month = bookingDate.getMonth() + 1; //Months are zero based
          var bookingDate_curr_year = bookingDate.getFullYear();
          var bookingDate_formattedDate = bookingDate_curr_date + "/" + bookingDate_curr_month + "/" + bookingDate_curr_year
          if (formattedDate === bookingDate_formattedDate) {
            //return 'activeClass';
            mdate.push(formattedDate);
          }
        }
      });
      if ($.inArray(formattedDate, mdate) != -1) {
        return 'activeClass1';
      }
    }
    if ($.inArray(formattedDate, this.startDates) != -1) {
      return 'activeClass';
    }
    else if ($.inArray(formattedDate, this.endDates) != -1) {
      return 'activeClass';
    }
    return;
  }

  isSupportSelected = true;
  customerSelected = '';
  messagesWithSupport = [];
  messagesWithHelper = [];
  logginUserId = localStorage.getItem('userId');
  selectedCustomerName = '';
  getChatMessages(autoFetch = false) {
    if (!this.sentNewMessage) {
      if (this.isSupportSelected) {
        this.getSupportMessages(autoFetch);
      }
      if (!this.isSupportSelected) {
        this.getHelperMessages(autoFetch);
        this.isSupportSelected = false;
      }
    }
  }

  getSupportMessages(autoFetch = false) {
    var self = this;
    this.customerSelected = '';
    if (!autoFetch) {
      this.messages = [];
    }
    var alreadyCalled;
    if (this.isSupportSelected) {
      if (alreadyCalled) {
        alreadyCalled.abort();
      }
      var url = Constant.API_URL + 'admin-support-message-view?token=' + localStorage.getItem('authToken') + '&to_userid=' + 1;
      alreadyCalled = $.get(url, function (suppportMessages, status) {
        if (suppportMessages.resCode == 200) {
          // this.messagesWithSupport = suppoerMessages.data;
          if (!self.sentNewMessage) {
            self.messages = suppportMessages.data;
          }
        }
      }).done(function (data) {
        alreadyCalled = undefined;
      })
        .fail(function () {
          alreadyCalled = undefined;
        });
    }
  }

  getHelperMessages(autoFetch = false) {
    var self = this;
    var alreadyCalled;
    if (!autoFetch) {
      this.messages = [];
    }

    if (!this.isSupportSelected) {
      if (alreadyCalled) {
        alreadyCalled.abort();
      }
      var url = Constant.API_URL + 'user-support-messages-view?token=' + localStorage.getItem('authToken') + '&to_userid=' + this.customerSelected;
      alreadyCalled = $.get(url, function (helperMessages, status) {
        if (helperMessages.resCode == 200) {
          // this.messagesWithHelper = helperMessages.data;
          if (!self.sentNewMessage) {
            self.messages = helperMessages.data;
          }
        }
      }).done(function (data) {
        alreadyCalled = undefined;
      })
        .fail(function () {
          alreadyCalled = undefined;
        });
    }
  }

  sentNewMessage = false;
  submitMessage() {
    if (!this.message || this.message.trim() == '') {
      this.toastr.error('Please Enter a message.', 'Inbox');
      this.message = undefined;
      this.message = "";
      return false;
    }

    this.sentNewMessage = true;
    if (this.isSupportSelected) {
      this.sendMesagesToSupport();
    }
    else if (!this.isSupportSelected) {
      this.sendMesageToHelper();
    }
  }

  sendMesagesToSupport() {
    var self = this;
    var supportChatObject: any = {
      "from_id": localStorage.getItem('userId'),
      "to_id": 1,
      "message": this.message,
      'userName': 'You, ',
      'loadUniqId': Date.now(),
      'failUniqueId': Date.now() + 50,
      'messagenotsent': false
    }

    self.message = '';

    const datePipe = new DatePipe('en-US');
    this.app.spinner.hide();
    supportChatObject.created_at = datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    let token = localStorage.getItem("authToken");
    var url = Constant.API_URL + 'admin-support-messages-add?token=' + token;

    // this.messagesWithSupport.unshift(supportChatObject);
    this.messages.unshift(supportChatObject);
    setTimeout(() => { $("#" + supportChatObject.loadUniqId).show(); }, 100);
    setTimeout(() => {
      $.post(url, supportChatObject,
        function (data, status) {
          if (data.resCode == 200) {
            $("#" + supportChatObject.loadUniqId).hide();
          } else {
            supportChatObject.messagenotsent = true;
            $("#" + supportChatObject.loadUniqId).hide();
            $("#" + supportChatObject.failUniqueId).show();
            self.sentNewMessage = false;
          }
        }).done(function (data) {
          $("#" + supportChatObject.loadUniqId).hide();
          $("#" + supportChatObject.failUniqueId).hide();
          self.sentNewMessage = false;
        })
        .fail(function () {
          supportChatObject.messagenotsent = true;
          $("#" + supportChatObject.loadUniqId).hide();
          $("#" + supportChatObject.failUniqueId).show();
          self.sentNewMessage = false;
        });
    }, 1000);

  }

  sendMesageToHelper() {
    var self = this;
    if (!this.customerSelected) {
      self.toastr.error('Please select any customer', 'Inbox');
      return false;
    }
    var user = self.helpersCustomers.filter(function (item) {
      if (item.userId == self.customerSelected) {
        return item;
      }
    })

    if (user && user.length > 0) {
      self.selectedCustomerName = user[0].userName;
    }
    var helperChatObject: any = {
      "from_id": localStorage.getItem('userId'),
      "to_id": this.customerSelected,
      "message": this.message,
      'userName': 'You, ',
      'loadUniqId': Date.now(),
      'failUniqueId': Date.now() + 50,
      'messagenotsent': false
    }

    const datePipe = new DatePipe('en-US');
    this.app.spinner.hide();
    helperChatObject.created_at = datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    let token = localStorage.getItem("authToken");
    var url = Constant.API_URL + 'user-support-messages-add?token=' + token;

    // this.messagesWithHelper.unshift(helperChatObject);
    this.messages.unshift(helperChatObject);
    self.message = '';
    setTimeout(() => { $("#" + helperChatObject.loadUniqId).show(); }, 100);
    setTimeout(() => {
      $.post(url, helperChatObject,
        function (data, status) {
          if (data.resCode == 200) {
            $("#" + helperChatObject.loadUniqId).hide();
          } else {
            helperChatObject.messagenotsent = true;
            $("#" + helperChatObject.loadUniqId).hide();
            $("#" + helperChatObject.failUniqueId).show();
            self.sentNewMessage = false;
          }
        }).done(function (data) {
          $("#" + helperChatObject.loadUniqId).hide();
          $("#" + helperChatObject.failUniqueId).hide();
          self.sentNewMessage = false;
        })
        .fail(function () {
          helperChatObject.messagenotsent = true;
          $("#" + helperChatObject.loadUniqId).hide();
          $("#" + helperChatObject.failUniqueId).show();
          self.sentNewMessage = false;
        });
    }, 1000);
  }

  keyDownFunction(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.submitMessage();
      // rest of your code
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  public prebentClick() {
    return false;
  }

  openImageModal() {
    jQuery("#imageModal").modal('show');
    setTimeout(() => {
      jQuery('.modal-backdrop').remove()
    }, 10);
  }
  DateString = Number(new Date());


  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }
  myFileUser: Blob;
  isUserImage: boolean = false;
  uploadImage() {
    if (this.imageErr) {
      this.toastr.warning('Vælg et billede med en størrelse mindre end 2 MB.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    } else {
      // Build object
      let fileData: FormData = new FormData();
      this.myFileUser = this.dataURItoBlob(this.imgURL);
      fileData.append('image', this.myFileUser);
      fileData.append('user_id', localStorage.getItem('userId'));
      this.app.spinner.show();
      this.apiService.postDataWithAttachment(Constant.SLUGS['updateProfileImage'], fileData).then(
        response => {
          this.app.spinner.hide();
          if (response['resCode'] == 200) {
            console.log('success gardener');

          } else {
            console.log('failed gardener');
          }
        },
        err => {
          console.log('failed gardener');
          this.app.spinner.hide();
        }
      );
    }
  }
  isSeniorImage: boolean = false;
  myFileUserSenior: Blob;
  uploadSeniorImage() {
    if (this.seniorImageErr) {
      this.toastr.warning('Please select image with size less than 2MB.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    } else {
      // Build object
      let fileData: FormData = new FormData();
      this.myFileUserSenior = this.dataURItoBlob(this.seniorimgURL);
      fileData.append('image', this.myFileUserSenior);
      fileData.append('user_id', localStorage.getItem('userId'));
      this.app.spinner.show();
      this.apiService.postDataWithAttachment('update-helping-seniors-image', fileData).then(
        response => {
          this.app.spinner.hide();
          if (response['resCode'] == 200) {
            console.log('success senior');
          } else {
            console.log('failed senior');
          }
        },
        err => {
          console.log('failed senior');
          this.app.spinner.hide();
        }
      );
    }
  }
  isCleanerImage: boolean = false;
  myFileCleaner: Blob;
  uploadCleanerImage() {
    if (this.cleanerImageErr) {
      this.toastr.warning('Please select image with size less than 2MB.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    } else {
      // Build object
      let fileData: FormData = new FormData();
      this.myFileCleaner = this.dataURItoBlob(this.seniorimgURL);
      fileData.append('image', this.myFileCleaner);
      fileData.append('user_id', localStorage.getItem('userId'));
      this.app.spinner.show();
      this.apiService.postDataWithAttachment('update-helping-cleaning-image', fileData).then(
        response => {
          this.app.spinner.hide();
          if (response['resCode'] == 200) {
            console.log('success cleaner');
          } else {
            console.log('failed cleaner');
          }
        },
        err => {
          console.log('failed cleaner');
          this.app.spinner.hide();
        }
      );
    }
  }


  validDescriptionOnActive(jobtype) {
    if (jobtype == 'user') {
      if (this.status == 1) {
        if (this.profileFormGroup.get('userBio').value == null || this.profileFormGroup.get('userBio').value == "") {
          this.profileFormGroup.controls['userBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
        }
        else {
          this.profileFormGroup.controls['userBio'].clearValidators();
        }
      }
      if (this.status == 2) {
        this.profileFormGroup.controls['userBio'].setErrors(null);
        this.profileFormGroup.controls['userBio'].clearValidators();
        this.profileFormGroup.controls['userBio'].updateValueAndValidity();
      }
    }
    if (jobtype == 'senior') {
      if (this.seniorstatus == '1') {
        if (this.profileFormGroup.get('senioruserBio').value == null || this.profileFormGroup.get('senioruserBio').value == "") {
          this.profileFormGroup.controls['senioruserBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
        }
        else {
          this.profileFormGroup.controls['senioruserBio'].clearValidators();
        }
      }
      if (this.seniorstatus == 2) {
        this.profileFormGroup.controls['senioruserBio'].setErrors(null);
        this.profileFormGroup.controls['senioruserBio'].clearValidators();
        this.profileFormGroup.controls['senioruserBio'].updateValueAndValidity();
      }
    }
    if (jobtype == 'cleaner') {
      if (this.cleanerstatus == '1') {
        if (this.profileFormGroup.get('cleaneruserBio').value == null || this.profileFormGroup.get('cleaneruserBio').value == "") {
          this.profileFormGroup.controls['cleaneruserBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
        }
        else {
          this.profileFormGroup.controls['cleaneruserBio'].clearValidators();
        }
      }
      if (this.cleanerstatus == '2') {
        this.profileFormGroup.controls['cleaneruserBio'].setErrors(null);
        this.profileFormGroup.controls['cleaneruserBio'].clearValidators();
        this.profileFormGroup.controls['cleaneruserBio'].updateValueAndValidity();
        if (this.profileFormGroup.invalid == false) {
        }
      }
    }
  }

  // seniorBioCount:any;
  // cleanerBioCout:any;

  getCharCount(event, jobtype) {
    if (jobtype == 1) {
      if (event.target.value.length < 40) {
        this.less20userBio = true;
        this.profileFormGroup.controls['userBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
      }
      else {
        this.less20userBio = false;
        this.profileFormGroup.controls['userBio'].clearValidators();
      }
    }
    if (jobtype == 2) {
      if (event.target.value.length < 40) {
        this.less20seniorBio = true;
        this.profileFormGroup.controls['senioruserBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
      }
      else {
        this.less20seniorBio = false;
        this.profileFormGroup.controls['senioruserBio'].clearValidators();
      }
    }

    if (jobtype == 3) {
      if (event.target.value.length < 40) {
        this.less20cleanerBio = true;
        this.profileFormGroup.controls['cleaneruserBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
      }
      else {
        this.less20cleanerBio = false;
        this.profileFormGroup.controls['cleaneruserBio'].clearValidators();
      }
    }
  }
  

  //CAMERA CODE - JOB TYPE 1
  profile_pic: any
  public handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.profile_pic = this.webcamImage.imageAsDataUrl;
    var image = this.webcamImage.imageAsDataUrl;
    var image_name = 'Profile';
  }

  iscameraOn: boolean = false
  showUploadContent = false;
  showCameraContent = false;
  @ViewChild('userImage', { static: false }) userImage: any;
  @ViewChild('userImageSenior', { static: false }) userImageSenior: any;
  @ViewChild('userImageCleaner', { static: false }) userImageCleaner: any;
  @ViewChild('closebuttonUser', { static: false }) closebuttonUser;
  @ViewChild('closebuttonSenior', { static: false }) closebuttonSenior;
  @ViewChild('closebuttonCleaner', { static: false }) closebuttonCleaner;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageSenior: any = '';
  croppedImageCleaner: any = '';
  showCropper = false;
  transform: ImageTransform = {};
  scale = 1;

  cameraOn(type) {
    if (type == 'upload') {
      this.iscameraOn = false;
      this.showUploadContent = true;
      this.showCameraContent = false;
    }
    if (type == 'camera') {
      this.iscameraOn = true;
      //this.imgURL = null;
      this.showCameraContent = true;
      this.showUploadContent = false;
    }

  }

  clearCamera() {
    this.iscameraOn = false;
    //this.profile_pic = null;
    //this.croppedImage = null;
    //this.userImage.nativeElement.value = "";
  }

  uploadCameraPhoto() {
    if (this.profile_pic != null) {
      this.isUserImage = true;
      this.imgURL = this.profile_pic;
      //this.profile_pic = null;
      this.closebuttonUser.nativeElement.click();
    }
  }
  uploadCameraPhotoSenior() {
    if (this.profile_pic_senior != null) {
      this.isSeniorImage = true;
      this.seniorimgURL = this.profile_pic_senior;
      //this.profile_pic_senior = null;
      this.closebuttonSenior.nativeElement.click();
    }
  }
  uploadCameraPhotoCleaner() {
    if (this.profile_pic_cleaner != null) {
      this.isCleanerImage = true;
      this.cleanerimgURL = this.profile_pic_cleaner;
      //this.profile_pic_cleaner = null;
      this.closebuttonCleaner.nativeElement.click();
    }
  }

  uploadCropPhoto() {
    if (this.croppedImage != null) {
      this.isUserImage = true;
      this.imgURL = this.croppedImage;
      this.croppedImage = null;
      this.imageChangedEvent = '';
      this.userImage.nativeElement.value = "";
      this.scale = 1;
      this.closebuttonUser.nativeElement.click();
    }
  }
  uploadSeniorCropPhoto() {
    if (this.croppedImageSenior != null) {
      this.isSeniorImage = true;
      this.seniorimgURL = this.croppedImageSenior;
      this.croppedImageSenior = null;
      this.imageChangedEvent = '';
      this.userImageSenior.nativeElement.value = "";
      this.scale = 1;
      this.closebuttonSenior.nativeElement.click();
    }
  }
  uploadCleanerCropPhoto() {
    if (this.croppedImageCleaner != null) {
      this.isCleanerImage = true;
      this.cleanerimgURL = this.croppedImageCleaner;
      this.croppedImageCleaner = null;
      this.imageChangedEvent = '';
      this.userImageCleaner.nativeElement.value = "";
      this.scale = 1;
      this.closebuttonCleaner.nativeElement.click();
    }
  }
  dimenitinData: any;
  //IMAGE CROPPING 
  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
    this.dimenitinData = sourceImageDimensions;
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  fileChangeEventSenior(event: any): void {
    this.imageChangedEvent = event;
  }
  fileChangeEventCleaner(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
  }
  imageCroppedSenior(event: ImageCroppedEvent) {
    this.croppedImageSenior = event.base64;
    console.log(event, base64ToFile(event.base64));
  }
  imageCroppedCleaner(event: ImageCroppedEvent) {
    this.croppedImageCleaner = event.base64;
    console.log(event, base64ToFile(event.base64));
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }
  // CAMERA CODE - JOB TYPE 2
  profile_pic_senior: any
  public handleImageSenior(webcamImage: WebcamImage) {
    this.webcamImageSenior = webcamImage;
    this.profile_pic_senior = this.webcamImageSenior.imageAsDataUrl;
    var image = this.webcamImageSenior.imageAsDataUrl;
    var image_name = 'Profile';
  }

  iscameraOnSenior: boolean = false
  showUploadContentSenior: boolean = false;
  showCameraContentSenior: boolean = false;
  cameraOnSenior(type) {
    if (type == 'upload') {
      this.iscameraOnSenior = false;
      this.showUploadContentSenior = true;
      this.showCameraContentSenior = false;
    }
    if (type == 'camera') {
      this.iscameraOnSenior = true;
      this.showCameraContentSenior = true;
      this.showUploadContentSenior = false;
    }
  }

  clearCameraSenior() {
    this.iscameraOnSenior = false;
    //this.profile_pic_senior = null;
    //this.croppedImage = null;
    // this.closebuttonSenior.nativeElement.click();
    //this.userImageSenior.nativeElement.value = "";
  }



  // CAMERA CODE - JOB TYPE 3
  profile_pic_cleaner: any
  public handleImageCleaner(webcamImage: WebcamImage) {
    this.webcamImageCleaner = webcamImage;
    this.profile_pic_cleaner = this.webcamImageCleaner.imageAsDataUrl;
    var image = this.webcamImageCleaner.imageAsDataUrl;
    var image_name = 'Profile';
  }

  iscameraOnCleaner: boolean = false
  showUploadContentCleaner: boolean = false;
  showCameraContentCleaner: boolean = false;

  cameraOnCleaner(type) {
    if (type == 'upload') {
      this.iscameraOnCleaner = false;
      this.showUploadContentCleaner = true;
      this.showCameraContentCleaner = false;
    }
    if (type == 'camera') {
      this.iscameraOnCleaner = true;
      this.showCameraContentCleaner = true;
      this.showUploadContentCleaner = false;
    }
  }

  clearCameraCleaner() {
    this.iscameraOnCleaner = false;
    // this.profile_pic_cleaner = null;
    // this.croppedImage = null;
    // this.userImageCleaner.nativeElement.value = "";
  }
  buttonFB: string = 'Tilknyt';
  buttonTW: string = 'Tilknyt';
  buttonGL: string = 'Tilknyt';
  connectGoogle(): void {
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
    // 	(userData) => { // on success
    // 		// this will return user data from google. What you need is a user token which you will send it to the server
    // 		this.onSocialLogin("Google", userData['id'], userData['email']);
    // 	}
    // );

  }

  connectFacebook(): void {
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
    // 	(userData) => { // on success
    // 		// this will return user data from fb. What you need is a user token which you will send it to the server
    // 		this.onSocialLogin("Facebook", userData['id'], userData['email']);
    // 	}
    // );
  }

  connectTwitter() {
    // var oauthToken = '';
    // var self = this;
    // //To Do to call request_token
    // this.apiService.getData('twitter-request-token').then(
    // 	twtRes => {

    // 		this.app.spinner.hide();
    // 		if (twtRes["resCode"] == 200) {

    // 			var twitterData = twtRes["data"];
    // 			oauthToken = twitterData[0].oauth_token;
    // 			popTwitterSignIn(oauthToken, function (response) {

    // 				var accessTokenUrl = 'twitter-access-token?oauth_token=' + response.oauth_token + '&oauth_verifier=' + response.oauth_verifier;
    // 				console.log("##accessTokenUrl:  ", accessTokenUrl)
    // 				self.apiService.getData(accessTokenUrl).then(
    // 					accessTokenRes => {

    // 						self.app.spinner.hide();
    // 						if (accessTokenRes["resCode"] == 200) {
    // 							self.onSocialLogin("Twitter", accessTokenRes["data"][2].user_id, accessTokenRes["data"][2].user_email);
    // 						} else {
    // 							self.toastr.error('Something went wrong while authenticating your request!', 'Error')
    // 						}
    // 					});
    // 			});
    // 		}
    // 		else{
    // 			this.toastr.warning(twtRes['msg'].message, '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    // 		}
    // 	})
  }
  isEmailExist: any;
  errorMessages: any;
  onSocialLogin(socialtype, socialId, email) {
    this.apiService.getData(Constant.SLUGS['isEmailExist'] + '/' + email).then(
      response => {

        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          if (response['data'].social_type == socialtype) {
            var data = {
              socialId: socialId,
              type: "social"
            }
            //this.app.spinner.show();
            this.apiService.postData(Constant.SLUGS['login'], data).then(
              response => {
                this.isEmailExist = true;
                this.app.spinner.hide();
                if (response['resCode'] == 200) {
                  var userRole = response['data']['userRole'];

                  // Store value for login
                  localStorage.setItem('authToken', response['data']['token']);
                  localStorage.setItem("userId", response['data']['userId']);
                  localStorage.setItem("userName", response['data']['name']);
                  localStorage.setItem("role", userRole);

                  if (userRole == "client") {
                    this.router.navigateByUrl('/customer/profileC00');
                  } else {
                    this.router.navigateByUrl('/helper/profile00');
                  }

                }
                else {
                  this.errorMessages = response['resMsg'];
                }
              },
              err => {
                console.log('register err', err);
                this.app.spinner.hide();
                this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
              }
            );
          }
          else {
            this.isEmailExist = false;
          }
        }
        else {
          this.isEmailExist = false;
        }
      },
      err => {
        this.app.spinner.hide();
        console.log('email checking err', err)
      }
    );

  }
  pipe = new DatePipe('en-US');

  makePdf(booking): void {
    let popupWin;
    var date = this.pipe.transform(booking.cleaning_date, 'short');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    //popupWin.document.open();
    popupWin.document.write(`
  <html><head><style type="text/css">
    @page {
        size: A4;
        margin: 30px 50px;
    }
    @media print {
        html,
        body {
            width: 210mm;
            height: 297mm;
        }
    }
    * {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
    }
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
    }
    body {
        background: #fff;
        font-family: Arial, Helvetica, sans-serif font-size: 14px;
        line-height: 20px;
        color: #333;
        padding: 0
    }
    a {
        text-decoration: none;
    }
    b,
    strong {
        font-weight: 600
    }
    .pull-left {
        float: left
    }
    .pull-right {
        float: right
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    td,
    th {
        padding: 5px 10px;
        font-size: 14px;
        line-height: normal;
        text-align: left;
        vertical-align: top;
        font-family: Arial, Helvetica, sans-serif
    }
    .table-wrap {
        width: 100%;
        margin: 0 auto;
        padding: 0px 0;
    }
    td.spacer5,
    .spacer5 {
        height: 5px;
        padding: 0 !important;
        clear: both
    }
    td.spacer10,
    .spacer10 {
        height: 10px;
        padding: 0;
        clear: both
    }
    td.spacer15,
    .spacer15 {
        height: 15px;
        padding: 0;
        clear: both
    }
    td.spacer20,
    .spacer20 {
        height: 20px;
        padding: 0
    }
    td.spacer40,
    .spacer40 {
        height: 40px;
        padding: 0
    }
    td.spacer30,
    .spacer30 {
        height: 30px;
        padding: 0
    }
    .text-center {
        text-align: center
    }
    .text-left {
        text-align: left !important
    }
    .text-right {
        text-align: right
    }
    td.inner-table {
       padding: 0;
    }
    td.inner-table td,
    td.inner-table th {
        border: 1px solid #ccc;
        padding: 6px 10px 3px;
        font-size: 15px;
        line-height: normal;
        vertical-align: middle;
        border-left: none;
        border-right: none;
    }
    td.inner-table td strong {
        font-weight: 700
    }
    td.inner-table th {
        font-weight: 600;
        font-size: 15px;
        line-height: normal;
        border-right-color: rgba(255, 255, 255, 0.5);
        padding: 6px 10px 3px;
        vertical-align: baseline;
        border-left: none;
        border-right: none;
    }
    td.inner-table th:last-child {
        border-right-color: #ccc
    }
    td.inner-table td {
        border: none;
        padding: 3px 10px;
        line-height: 15px;
    }
    .heading-cell {
        padding: 0;
    }
    td.spacer60,
    .spacer60 {
        height: 60px;
        padding: 0
    }
    .invoice-subhead {
        padding: 0;
    }
    .invoice-subhead>table {
        display: inline-table;
        vertical-align: top;
        height: 100%;
        vertical-align: middle;
        border-collapse: separate;
    }
    .invoice-subhead table tr td,
    .invoice-subhead table tr th {
        padding-left: 0;
    }
    .invoice-left-table {
        margin-right: -4px;
        width: 70%;
        padding-right: 15px;
    }
    .invoice-right-table {
        width: 30%;
    }
    .invoice-subhead table tr th {
        padding-bottom: 10px;
        border-bottom: 1px solid #000;
        font-size: 18px;
        text-transform: uppercase;
    }
    .invoice-subhead table tr th {
        padding-left: 0;
    }
    .invoice-subhead table tr td {
        padding-left: 0;
    }
    .invoice-right-table th:after {
        content: ''
    }
    ul.leaders {
        max-width: 18em;
        padding: 0;
        overflow-x: hidden;
        list-style: none
    }
    ul.leaders li:before {
        float: left;
        width: 0;
        white-space: nowrap;
        content:
            ". . . . . . . . . . . . . . . . . . . . "
            " : "
    }
    ul.leaders li:after {
        float: left;
        width: 0;
        white-space: nowrap;
        content: ":"
    }
    ul.leaders span:first-child {
        padding-right: 0.33em;
        background: white
    }
    ul.leaders span+span {
        float: right;
        padding-left: 0.5em;
        background: white;
        width: 83px;
    }
    td.inner-table tfoot>tr>td,
    tfoot>tr>th {
        border-top: 1px solid #000;
        padding: 16px 4px;
        vertical-align: top;
    }
    td.inner-table tfoot .total-table th,td.inner-table tfoot .total-table td{
        border: none;
        font-weight: normal;
        padding: 0 4px 16px;
    }
    td.inner-table tfoot .total-table th {width: 55%;text-align: right;padding-right: 17px;}
    td.inner-table tfoot .total-table td {
        text-align: right;
        width: 45%;
    }
    td.inner-table tfoot .total-table .grand-total th, td.inner-table tfoot .total-table .grand-total td {
        border-bottom: 1px solid #000;
    }
    td.inner-table tfoot>tr>td:first-child {
        font-size: 12px;
    }
    .invoice-left-table th {
        height: 23px;
    }
    td.inner-table tfoot {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: table;
    }
    .table-wrap {
        position: relative;
        min-height: 100%;
        height: 100%;
    }
    html,body {
        height: 100%;
        min-height: 100%;
    }
    body {
        padding-bottom: 167px;
    }
    td.inner-table tfoot>tr {
        display: table-row;
        width: 100%;
    }
    </style>
      <title>Invoice</title>      
    </head>
    <body onload="window.print();window.close()">
    <div class="table-wrap" style="page-break-after: always;" id="contentPdF">
        <table class="main-table" cellpadding="0" border="0" cellspacing="0">
            <tr>
                <td align="left" class="heading-cell">
                    <table cellpadding="0" border="0" cellspacing="0">
                        <tr>
                            <th>
                            </th>
                            <th>
                                Leveringsadresss:
                            </th>
                        </tr>
                        <tr>
                            <td>
                               ${booking.cleaning_area} Valby
                            </td>
                            <td>
                                <br>
                                ${booking.cleaning_area} Valby
                            </td>
                </td>
            </tr>
        </table>
        </td>
        </tr>
        <tr>
            <td align="left" class="invoice-subhead">
                <table cellpadding="0" border="0" cellspacing="0" class="invoice-left-table">
                    <tr>
                        <th>
                        </th>
                    </tr>
                    <tr>
                        <td class="spacer20"></td>
                    </tr>
                    <tr>
                        <td>
                            <b>88888 Rengøring</b><br>
                            Rengøring udført af :  ${booking.first_name}<br>
                            CPR: Husk at fa oplyst cpr-nr. af din Helper <br>
                            TransactionID 00000000
                        </td>
                    </tr>
                </table>
                <table cellpadding="0" border="0" cellspacing="0" class="invoice-right-table">
                    <tr>
                        <td align="left">
                            <table cellpadding="0" border="0" cellspacing="0">
                                <tr>
                                    <th>
                                        FAKTURA
                                    </th>
                                <tr>
                                    <td class="spacer20"></td>
                                </tr>
                    </tr>
                    <tr>
                        <td>
                            <ul class=leaders>
                                <li><span>Fakturanr.</span>
                                    <span>888888888</span>
                                <li><span>Fakturadato</span>
                                    <span>${date}</span>
                                <li><span>Bookingdato</span>
                                    <span>${date}</span>
                                <li><span>Kundenr.</span>
                                    <span>1</span>
                            </ul>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        </table>
        </td>
        </tr>
        <tr>
            <td class="spacer60"></td>
        </tr>
        <tr class="bank-tbl">
            <td class="inner-table">
                <table cellpadding="0" border="0" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="text-right">Navn</th>
                            <th class="text-left">
                            Dato
                            </th>
                            <th class="text-right">
                            Telefon
                            </th>
                            <th class="text-left">
                            Timeløn
                            </th>
                            <th class="text-right">
                            Indtjening
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-right">
                            ${booking.first_name}
                            </td>
                            <td class="text-left">
                            ${date}
                            </td>
                            <td class="text-right">
                            ${booking.phone}
                            </td>
                            <td class="text-left">
                            ${booking.hourlyRate}
                            </td>
                            <td class="text-right">
                            ${booking.cleaning_amount}
                            </td>
                        </tr>                     
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4">
                                (Momsfrit belob: <b>225,00</b> - Momspligtigt belob: <b>0,00</b>)
                            </td>
                            <td colspan="2">
                                <table cellpadding="0" border="0" cellspacing="0" class="total-table">
                                  <tr class="grand-total">
                                        <th>
                                            <b>Total kr:</b>
                                        </th>
                                        <td>
                                            <b>${booking.cleaning_amount}</b>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </td>
        </tr>
        </table>
    </div>
</body>

   
  </html>`
    );
    
    //popupWin.document.close();
    var data =popupWin.document.documentElement; //document.getElementById('contentPdF');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  

      var myDate = new Date();
      var savedate = this.pipe.transform(myDate ,'short');
      
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save(booking.first_name+"_"+savedate); // Generated PDF   
    });  
    
  }
}
