import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataSharingService } from 'src/app/services/data.sharing.service';;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isMenuOpen: boolean = false;
  public isLoggedIn: boolean = false;
  public isUserCustomer:boolean = false;

  constructor(public auth: AuthenticationService,private dataSharingService: DataSharingService) { 
    // Subscribe here, this will automatically update 
        // "isUserLoggedIn" whenever a change to the subject is made.
        this.dataSharingService.isUserLoggedIn.subscribe( value => {
          this.isLoggedIn = value;
      });
  }

  ngOnInit() {
    console.log(this.isUserLoggedIn);
    this.isUserLoggedIn.subscribe( value => {
      console.log
  });
    this.isLoggedIn = this.auth.isUserLoggedIn();
    this.isUserCustomer = this.auth.isUserCustomer();
  }

}
