import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-introduction-dk',
  templateUrl: './introduction-dk.component.html',
  styleUrls: ['../introduction/introduction.component.css']
})
export class IntroductionDkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
