import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helper-profie-status-dk',
  templateUrl: './helper-profie-status-dk.component.html',
  styleUrls: ['../helper-profie-status/helper-profie-status.component.css']
})
export class HelperProfieStatusDkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
