import { Component, OnInit } from '@angular/core';
import { Constant } from 'src/app/settings/constants';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-booking-payment-dk',
    templateUrl: './booking-payment-dk.component.html',
    styleUrls: ['./booking-payment-dk.component.css'],
})
export class BookingPaymentDkComponent implements OnInit {
    public areaTime = 0;
    paymentModel: any = {
        customer_id: "",
        helper_id: "",
        cleaning_date: "",
        cleaning_duration: "",
        cleaning_time: "",
        cleaning_frequency: "",
        cleaning_amount: "",
        cleaning_service_fee: "",
        cleaning_admin_fee: "",
        discount_amount: "",
        booking_status: "",
        transaction_id: "",
        order_id: ""
    }
    time;
    public howOftenSelected = 1;
    public selectedBookie;
    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute
    ) {
      
        this.route.queryParams.subscribe(params => {
            let txnid = params['txnid'];
            if (txnid) {
                this.paymentModel.customer_id = localStorage.getItem('userId');
                var selected_helper = localStorage.getItem('selected_bookie');
                var areaModel: any = JSON.parse(localStorage.getItem("AreaDetails"));
                this.time = areaModel['time'];
                this.time = this.time.toString().replace('.', ':');
                if (selected_helper) {
                    var bookie = JSON.parse(selected_helper);
                    if (bookie) {
                        this.selectedBookie = bookie;
                        this.setHelperDetail(bookie);
                    }
                } else {
                    this.selectedBookie = {};
                    this.setHelperDetailWhenNothing();
                }
                this.paymentModel.helper_id = this.selectedBookie.userId;

                // let day = areaModel.date['day'];
                // if (day) {
                //     day = (Array(Math.max(2 - String(day).length + 1, 0)).join("0") + day).toString();
                // }
                // let month = areaModel.date['month'];
                // if (month) {
                //     month = (Array(Math.max(2 - String(month).length + 1, 0)).join("0") + month).toString();
                // }
                // let year = areaModel.date['year'];
                // let date = new Date(year + '-' + month + '-' + day);

                this.paymentModel.cleaning_date = new Date(areaModel.date);
                this.paymentModel.cleaning_duration = this.time;

                let pointtime = localStorage.getItem('pointtime');
                if (pointtime) {
                    this.paymentModel.cleaning_time = pointtime;//from popup
                }
                let frequency = localStorage.getItem('frequency');
                if (frequency) {
                    this.paymentModel.cleaning_frequency = frequency;//from popup
                }
                else {
                    this.paymentModel.cleaning_frequency = 1;//from popup
                }
                this.paymentModel.cleaning_amount = params['amount'];
                this.paymentModel.cleaning_service_fee = this.selectedBookie.serviceFee;
                this.paymentModel.cleaning_admin_fee = this.selectedBookie.administrationFee;
                //this.paymentModel.discount_amount="";
                this.paymentModel.booking_status = 1;//0 pending,1 success ,2 reject
                this.paymentModel.transaction_id = params['txnid'];
                this.paymentModel.order_id = params['orderid'];
                //#region api payment
                var URL = "customer-booking-order?token=" + localStorage.getItem("authToken");
                this.apiService.postData(URL, this.paymentModel).then(
                    response => {
                        if (response["resCode"] == 200) {
                            this.router.navigateByUrl('/customer/thankyou-dk');
                        } else {
                            this.toastr.warning(response["resMsg"], "", {
                                timeOut: Constant.ERROR_TIMEOUT,
                                positionClass: Constant.MESSAGE_POSITION,
                                closeButton: true
                            });
                        }
                    },
                    err => {
                        console.log("register err", err);
                        this.toastr.error(
                            "Problem in fetching the data. Please try again.",
                            "",
                            {
                                timeOut: Constant.ERROR_TIMEOUT,
                                positionClass: Constant.MESSAGE_POSITION,
                                closeButton: true
                            }
                        );
                    }
                );
                //#endregion
            }

        });
    }

    ngOnInit() {
    }
    setHelperDetailWhenNothing() {
        this.selectedBookie.serviceFee = 0;
        this.selectedBookie.paymentToUser = 0;
        this.selectedBookie.serviceFeePrice = 0;
        this.selectedBookie.VAT = 0;
        this.selectedBookie.administrationFee = 0;
        this.selectedBookie.totalServiceHelperInvoice = 0;
        this.selectedBookie.totalInvoice = 0;
        this.selectedBookie.duration = '-';
        // this.isBookieSelected = true;
      }
    setHelperDetail(valueItem) {
        valueItem.isBookieSelected = true;
        valueItem.chooseText = 'SELECTED';
        this.selectedBookie = valueItem;
        if (this.howOftenSelected == 1) {
          this.selectedBookie.serviceFee = 25;
        }
        else if (this.howOftenSelected == 2) {
          this.selectedBookie.serviceFee = 15;
        }
        else if (this.howOftenSelected == 3) {
          this.selectedBookie.serviceFee = 10;
        }
    
        this.selectedBookie.paymentToUser = valueItem.helperhourlyrate * this.areaTime;
        this.selectedBookie.serviceFeePrice = (this.selectedBookie.paymentToUser * this.selectedBookie.serviceFee) / 100;
        this.selectedBookie.VAT = (this.selectedBookie.serviceFeePrice * 25) / 100;
        this.selectedBookie.administrationFee = 5;
        this.selectedBookie.totalServiceHelperInvoice = this.selectedBookie.serviceFeePrice + this.selectedBookie.administrationFee;
        this.selectedBookie.totalInvoice = this.selectedBookie.paymentToUser + this.selectedBookie.totalServiceHelperInvoice;
        if (this.areaTime == 2.5) {
          this.selectedBookie.duration = '2t 30m';
        }
        else if (this.areaTime == 3) {
          this.selectedBookie.duration = '3t 00m';
        }
        else if (this.areaTime == 4) {
          this.selectedBookie.duration = '4t 00m';
        }
        else if (this.areaTime == 5) {
          this.selectedBookie.duration = '5t 00m';
        }
        else if (this.areaTime == 6) {
          this.selectedBookie.duration = '6t 00m';
        }
        else if (this.areaTime == 7) {
          this.selectedBookie.duration = '7t 00m';
        }
        else if (this.areaTime == 8) {
          this.selectedBookie.duration = '8t 00m';
        } else {
          var time = this.time.split(":");
          this.selectedBookie.duration = time[0] + 't ' + time[1] + 'm';
        }
        var deduction_perc_value = ((this.selectedBookie.paymentToUser * 25) / 100) + this.selectedBookie.totalServiceHelperInvoice;
    
        this.selectedBookie.deduction_rate = this.selectedBookie.totalInvoice - deduction_perc_value;
      }

}
