import { BrowserModule } from '@angular/platform-browser';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { RatingModule } from 'ng-starrating';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PopoverModule } from 'ngx-smart-popover';
import { ModalModule } from 'ngb-modal';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { ModalModule } from 'ngx-bootstrap/modal';
import { appRoutes } from './app.routes';
import { HomeComponent } from './front-module/home/home.component';
import { DemoMaterialModule } from './material-module';
import { TermsDanishComponent } from './dialog/terms-danish/terms-danish.component';
import { TermsEnglishComponent } from './dialog/terms-english/terms-english.component';
// materials
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSidenavModule, MatDatepickerModule } from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MatFormFieldAppearance,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions
} from '@angular/material';
// helper profile
import { Profile00Component } from './front-module/helper/profile00/profile00.component';
import { Profile00DkComponent } from './front-module/helper/profile00-dk/profile00-dk.component';
import { ProfileC00Component } from './front-module/customer/profile-c00/profile-c00.component';
// helper signup
import { Signup01JoinComponent } from './front-module/helper/signup01-join/signup01-join.component';
import { Signup01JoinDkComponent } from './front-module/helper/signup01-join-dk/signup01-join-dk.component';
// customer
import { OrdreComponent } from './front-module/customer/ordre/ordre.component';
import { OrdreDkComponent } from './front-module/customer/ordre-dk/ordre-dk.component';
// shared-module
import { LoginComponent } from './front-module/login/login.component';
import { LogoutComponent } from './front-module/logout/logout.component';
import { FaqComponent } from './shared-module/support/faq/faq.component';
import { SupportComponent } from './shared-module/support/support/support.component';
import { FooterComponent } from './shared-module/footer/footer.component';
import { MenuComponent } from './shared-module/menu/menu.component';
//booking address component
import { MenuDkComponent } from './shared-module/menu-dk/menu-dk.component';
// set default form field appearance as fill for this component
const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
// Constant variables
import { Constant } from './settings/constants';
import { FaqDkComponent } from './shared-module/support/faq-dk/faq-dk.component';
import { SupportDkComponent } from './shared-module/support/support-dk/support-dk.component';
import { FooterDkComponent } from './shared-module/footer-dk/footer-dk.component';
import { HomeDkComponent } from './front-module/home-dk/home-dk.component';
import { ProfileC00DkComponent } from './front-module/customer/profile-c00-dk/profile-c00-dk.component';
import { CreateTicketComponent } from './shared-module/support/create-ticket/create-ticket.component';
import { CreateTicketDkComponent } from './shared-module/support/create-ticket-dk/create-ticket-dk.component';
import { CustomDateParserFormatter } from './services/custom-date-format.service';

import { UploadImageComponent } from './front-module/helper/upload-image/upload-image.component';
import { AddPhotoComponent } from './front-module/helper/add-photo/add-photo.component';
import { ReviewModalComponent } from './front-module/helper/profile00/review-modal/review-modal.component';
import { ReviewModalDkComponent } from './front-module/helper/profile00-dk/review-modal-dk/review-modal-dk.component';
import { GetHelperComponent } from './front-module/get-helper/get-helper.component';
import { CustomerLoginComponent } from './front-module/booking/customer-login/customer-login.component';
import { PagerService } from './services/pagination.service';
import { IntroductionComponent } from './front-module/helper/introduction/introduction.component';
import { IntroductionDkComponent } from './front-module/helper/introduction-dk/introduction-dk.component';
import { ThankyouComponent } from './front-module/customer/thankyou/thankyou.component';
import { HelperProfieStatusComponent } from './shared-module/helper-profie-status/helper-profie-status.component';
import { PhotoApprovedRequirementComponent } from './shared-module/support/photo-approved-requirement/photo-approved-requirement.component';
import { PhotoApprovedRequirementDKComponent } from './shared-module/support/photo-approved-requirement-dk/photo-approved-requirement-dk.component';
import { CustomerCheckinComponent } from './front-module/customer/customer-checkin/customer-checkin.component';
import { CustomerCheckoutComponent } from './front-module/customer/customer-checkout/customer-checkout.component';
import { HelperProfieStatusDkComponent } from './shared-module/helper-profie-status-dk/helper-profie-status-dk.component';
import { LoginDkComponent } from './front-module/login-dk/login-dk.component';
import { ForgotPasswordComponent } from './shared-module/forgot-password/forgot-password.component';
import { ForgotPasswordDkComponent } from './shared-module/forgot-password-dk/forgot-password-dk.component';
import { ResetPasswordComponent } from './shared-module/reset-password/reset-password.component';
import { ResetPasswordDkComponent } from './shared-module/reset-password-dk/reset-password-dk.component';
import { BookingPaymentComponent } from './front-module/customer/booking-payment/booking-payment.component';
import { BookingPaymentDkComponent } from './front-module/customer/booking-payment-dk/booking-payment-dk.component';
import { ThankyouDkComponent } from './front-module/customer/thankyou-dk/thankyou-dk.component';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from './front-module/camera/camera.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { from } from 'rxjs';
import {NgxPrintModule} from 'ngx-print';
// Social login configuration
let config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('4678914852150835')
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(Constant.GOOGLE_PROVIDER_ID)
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LoginDkComponent,
    LogoutComponent,
    FooterComponent,
    FaqComponent,
    TermsDanishComponent,
    TermsEnglishComponent,
    Profile00Component,
    Profile00DkComponent,
    MenuComponent,
    MenuDkComponent,
    ProfileC00Component,
    SupportComponent,
    Signup01JoinComponent,
    Signup01JoinDkComponent,
    FaqDkComponent,
    SupportDkComponent,
    MenuDkComponent,
    FooterDkComponent,
    HomeDkComponent,
    ProfileC00DkComponent,
    CreateTicketComponent,
    CreateTicketDkComponent,
    UploadImageComponent,
    AddPhotoComponent,
    ReviewModalComponent,
    ReviewModalDkComponent,
    CustomerLoginComponent,
    GetHelperComponent,
    OrdreComponent,
    BookingPaymentComponent,
    BookingPaymentDkComponent,
    OrdreDkComponent,
    IntroductionComponent,
    IntroductionDkComponent,
    ThankyouComponent,
    ThankyouDkComponent,
    HelperProfieStatusComponent,
    HelperProfieStatusDkComponent,
    PhotoApprovedRequirementComponent,
    PhotoApprovedRequirementDKComponent,
    CustomerCheckinComponent,
    CustomerCheckoutComponent,
    ForgotPasswordComponent,
    ForgotPasswordDkComponent,
    ResetPasswordComponent,
    ResetPasswordDkComponent,
    CameraComponent,
  ],

  entryComponents: [
    TermsDanishComponent,
    ReviewModalComponent,
    ReviewModalDkComponent,
    GetHelperComponent
  ],

  imports: [
    BrowserModule,
    //NgxFullCalendarModule,
    HttpClientModule,
    MatStepperModule,
    FormsModule,
    NgbModule,
    PopoverModule,
    ReactiveFormsModule,
    DemoMaterialModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    TooltipModule.forRoot(),
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot(),
    SocialLoginModule,
    RatingModule,
    ModalModule,
    WebcamModule,
    ImageCropperModule,
    NgxPrintModule
  ],
  providers: [
    PagerService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
