import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import 'rxjs';
import 'rxjs/add/operator/do';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let user;
    user = localStorage.getItem('authToken');
    if (user && user != '') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user}`
        }
      });
    }

    let req = next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.body.code === 403) {
          // redirect to the login route
          localStorage.removeItem('authToken');
          localStorage.removeItem('userId');
          localStorage.removeItem('userName');
          this.router.navigateByUrl('/login');
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 403) {
          // redirect to the login route
          localStorage.removeItem('authToken');
          localStorage.removeItem('userId');
          localStorage.removeItem('userName');
          this.router.navigateByUrl('/login');
        }
        if (err.status === 405) {
          // redirect to the login route
          localStorage.removeItem('authToken');
          localStorage.removeItem('userId');
          localStorage.removeItem('userName');
          this.router.navigateByUrl('/login');
        }
      }
    });
    return req;
  }
}