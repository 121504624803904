import { environment } from '../../environments/environment';
export class Constant {

  public static API_URL: string = environment.apiUrl;
  public static ERROR_TIMEOUT: number = 7000;
  public static MESSAGE_POSITION: string = 'toast-top-full-width';
  public static FACEBOOK_PROVIDER_ID: string = environment.facebookProviderId;
  public static GOOGLE_PROVIDER_ID: string = environment.googleProvideId;
  public static TWITTER_PROVIDER_ID: string = environment.twitterProviderId;
  public static SMS_API_KEY: string = environment.smsApiKey;
  public static SMS_API: string = environment.smsApi;

  public static SLUGS: object = {
    'getRegions': 'getregions',
    'getCities': 'getcities',
    'register': 'register',
    'getCity': 'get-city',
    'getAreas': 'get-areas',
    'sendCode': 'send-code',
    'login': 'login',
    'logout': 'logout',
    'isEmailExist': 'is-email-exist',
    'getProfile': 'get-profile',
    'updateProfile': 'update-profile',
    'updateAreas': 'update-areas',
    'removeArea': 'remove-areas',
    'userReviews': 'user-reviews',
    'updateReviews': 'update-reviews',

    'updateProfileImage': 'update-profile-image',
    'getProfileImage': 'get-profile-image',
    'removeProfileImage': 'remove-profile-image',
    'getBookingData': 'get-booking-data',
    'getBookingOrder': 'get-booking-order',
    'getAllEvents': 'get-events',
    'eventCreate': 'event-create',
    'eventUpdate': 'event-update',
    'eventDelete': 'event-delete',
    'eventDetails': 'event-edit',

    'getAvailableHelpersFromPostcode': 'get-helpers-by-postcode',
    'getAvailablePostalCode': 'get-postal-code',
    'getParticularHelperInfo': 'get-only-helper-info',
    'getHelpersCustomer': 'helper-customer',

    'forgetpassword': 'forgetpassword',
    'resetpassword':'forgot_password'
  }
}