import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { Constant } from 'src/app/settings/constants';

@Component({
  selector: 'app-customer-checkout',
  templateUrl: './customer-checkout.component.html',
  styleUrls: ['./customer-checkout.component.css']
})
export class CustomerCheckoutComponent implements OnInit {

  userId;
  bookingId;
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private toastr: ToastrService,
    public app: AppComponent,
    private router: Router) {
    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('userid');
      this.bookingId = paramMap.get('bookingid');
      console.log(paramMap);
    })
  }

  ngOnInit() {
    if(this.userId && this.bookingId){
      this.app.spinner.show();
      var data= {
        "type": "checkout",
        "bookingId": this.bookingId
        }
    this.apiService.postData('status-confimation', data).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          this.toastr.success('Successfully checked out.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          this.router.navigateByUrl('dk');
        } else {
          this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
        }
      },
      err => {
        this.app.spinner.hide();
        this.toastr.error('Error While Checkin process. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
      }
    );
    }
  }

}
