import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validation.service';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../../../app.component';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { Constant } from '../../../settings/constants';

@Component({
  selector: 'app-add-photo',
  templateUrl: './add-photo.component.html',
  styleUrls: ['./add-photo.component.css']
})
export class AddPhotoComponent implements OnInit {
  
  public uploadImage: FormGroup;
  public fileToUpload: File = null;
  public imageErr: boolean = false;
  public imgURL: any;
  public imagePath;
  public imageDb: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    private validationService: ValidationService,
    private toastr: ToastrService,
    public app: AppComponent,
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
    this.getImageDb();
  }

  /**
   * Create form
   */
  createForm(){
    this.uploadImage = this._formBuilder.group({
      image: ['', [Validators.required]]
    });
  }

  /**
   * Get profile image from database
   */
  getImageDb(){
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getProfileImage']).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          if(response['data']['image']){
            this.imgURL = response['data']['imageUrl'];
            this.imageDb = true;
          }
          else{
            this.imgURL = null;
            this.imageDb = false;
          }
        } else {
          this.imgURL = null;
          this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
        }
      },
      err => {
        this.app.spinner.hide();
        this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
      }
    );
  }

  /**
   * Handle uploading photo
   * @param files 
   */
  handleFileInput(files: FileList) {
    if (files.item(0)) {
      this.onChange('image');
      if (files.item(0).size < 5242880) {
        this.uploadImage.controls['image'].setErrors(null);
        this.fileToUpload = files.item(0);
        this.imageErr = false;
        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(this.fileToUpload); 
        reader.onload = (_event) => { 
          this.imgURL = reader.result; 
        }
      } else {
        this.uploadImage.controls['image'].setErrors({ bigImage: { maxSize: '2MB' } });
        this.toastr.warning('Please select image with size less than 2MB.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
        this.imageErr = true;
      }
    } else {
      this.uploadImage.controls['image'].setErrors(null);
      this.imageErr = false;
    }
  }

  /**
   * Remove validation errors
   * @param field 
   */
  onChange(field = null): void {
    this.validationService.removeErrors(field);
  }

  /**
   * Delete photo on remove click
   */
  removePhoto(){
    this.imgURL = null;
    this.uploadImage.reset();
    if(this.imageDb === true){
      this.app.spinner.show();
      this.apiService.getData(Constant.SLUGS['removeProfileImage']).then(
        response => {
          this.app.spinner.hide();
          if (response['resCode'] == 200) {
            this.imgURL = null;
            this.imageDb = false;
          } else {
            this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          }
        },
        err => {
          this.app.spinner.hide();
          this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
        }
      );
    }
  }

  /**
   * Submit uploaded photo
   */
  onSubmit(){
    this.validationService.setForm(this.uploadImage);
    if (this.validationService.validate()) {
      this.onChange();
      if (this.imageErr) {
        this.toastr.warning('Please select image with size less than 2MB.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
      } else {
        // Build object
        let fileData: FormData = new FormData();
        fileData.append('image', this.fileToUpload);
        fileData.append('user_id', localStorage.getItem('userId'));
        this.app.spinner.show();
        this.apiService.postDataWithAttachment(Constant.SLUGS['updateProfileImage'], fileData).then(
          response => {
            this.app.spinner.hide();
            if (response['resCode'] == 200) {
              this.uploadImage.reset();
              this.toastr.success(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
              this.router.navigateByUrl('/helper/profile00');
            } else {
              this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
            }
          },
          err => {
            this.app.spinner.hide();
            this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          }
        );
      }
    } else {
      this.validationService.removeErrors();
      this.validationService.displayErrors('profileFrom');
      this.validationService.setFoucsOfForm(this.uploadImage.controls);
      this.router.navigateByUrl('/helper/profile00');
    }
  }

}
