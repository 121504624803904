import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { ToastrService } from 'ngx-toastr';
import { Constant } from 'src/app/settings/constants';
import { ApiService } from 'src/app/services/api.service';
import { AppComponent } from 'src/app/app.component';
import { DatePipe } from '@angular/common';
import { NgbTimeStruct, NgbModal, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { min } from 'moment';
import { empty, Observable } from "rxjs";
import 'rxjs/add/observable/interval';
import { utc } from 'moment';
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
declare function popTwitterSignIn(outhToken, callback): any;
const now = new Date();
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas'; 
@Component({
  selector: 'app-profile-c00',
  templateUrl: './profile-c00.component.html',
  styleUrls: ['./profile-c00.component.css']
})
export class ProfileC00Component implements OnInit {
  @ViewChild("content", { static: true }) content: ElementRef;
  @ViewChild('contentPdF', { static: true }) contentPdF: ElementRef;
  chatDate = utc;
  status: any;
  statusMsg: any;
  userName: any;
  public isEnglish: boolean = true;
  accountNumber: any;
  public reviews: any = [];
  public data: any = [];

  /** FormGroup Variables */
  public personalInformation: FormGroup;
  public updateBookingForm: FormGroup;

  constructor(
    private router: Router
    , private authenticationService: AuthenticationService
    , private _formBuilder: FormBuilder
    , private validationService: ValidationService
    , private apiService: ApiService
    , public app: AppComponent
    , private toastr: ToastrService
    , private modalService: NgbModal
    , private datePickerConfig: NgbDatepickerConfig,
    private authService: AuthService
  ) {
    Window["ProfileC00Component"] = this;
  }


  selectedTab = 0;
  ngOnInit() {
    this.initializeForms();
    this.getChatMessages();
    this.getProfile();
    this.getYourReviewes();
    this.getCustomersHelper();
    this.getBookings();

    this.selectedTab = 0;
    var sub = Observable.interval(3000)
      .subscribe((val) => {
        if (this.selectedTab == 0) {
          this.getChatMessages(true);
        }
        // console.log('7 seconds done');

      });

    if (this.authenticationService.isUserCustomer()) {
      this.userName = localStorage.getItem("userName");
      var isEnglishLanguage = localStorage.getItem('Language');
      if (isEnglishLanguage == "false") {
        this.router.navigateByUrl('/customer/profileC00-dk');
      }
    } else {
      this.router.navigateByUrl('/helper/profile00');
    }
  }

  
  getProfile(): void {
    this.app.spinner.show();
    let userId = localStorage.getItem("userId");
    this.apiService.getData(Constant.SLUGS["getProfile"] + "/" + userId).then(
      response => {
        this.app.spinner.hide();
        if (response["resCode"] == 200) {
          console.log("profile ", response);
          this.data = response["data"];
          this.bindProfileDetail(this.data);
        } else {
          this.toastr.error(response["resMsg"], "User Profile");
        }
      })
  }

  /**
   * initiazlies forms
   */
  time: NgbTimeStruct = { hour: 18, minute: 0, second: 0 };
  hourStep = 1;
  minuteStep = 5;
  secondStep = 1;

  initializeForms() {
    this.personalInformation = this._formBuilder.group({
      firstname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      lastname: [''],
      street: ['', [Validators.required, Validators.minLength(3)]],
      address1: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(4), Validators.pattern('^[a-zA-Z0-9]*$')]],
      address2: ['', [Validators.minLength(1), Validators.maxLength(20)]],
      postalCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      city: ['', [Validators.required, Validators.minLength(3)]],
      phone_code: ['+45'],
      phone: ['', [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(8),
      Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      email: ['', [Validators.required, this.validationService.emailValidator, Validators.maxLength(100)]],
      cvr: ['', [Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],

      oldPassword: ['', [Validators.minLength(8), Validators.maxLength(30), this.validationService.passwordCriteria()]],
      password: ['', [Validators.minLength(8), Validators.maxLength(30), this.validationService.passwordCriteria()]],
      confirmPassword: ['', [Validators.minLength(8), Validators.maxLength(30)]],
      regNo: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      //regNo: ['', [Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      //acNo: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      acNo: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(10), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      emailReminder: [false, [Validators.required]],
      smsReminder: [false, [Validators.required]],

      notification: ["0"]
    }, {
      validator: this.validationService.matchValue(
        "password",
        "confirmPassword"
      )
    });

    var dates = new Date();
    var datesArray = { year: dates.getFullYear(), month: dates.getMonth() + 1, day: dates.getDate() };
    this.datePickerConfig.minDate = {
      year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()
    };


    this.updateBookingForm = this._formBuilder.group({
      cleaning_date: [datesArray, [Validators.required]],
      cleaning_time: [this.time, [Validators.required]],
      booking_id: [0]
    })
  }

  /** Switch Language */
  switchLanguage(isEnglish) {
    localStorage.setItem('Language', isEnglish);
    if (isEnglish) {
      this.router.navigateByUrl('/customer/profileC00');
    } else {
      this.router.navigateByUrl('/customer/profileC00-dk');
    }
  }


  /**
   * Get city name by postal code
   * @param event 
   */
  cityName = '';
  getCityByPostalCode(event: any): void {
    let postalCode = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getCity'] + '/' + postalCode).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          this.cityName = response['data'];
          this.onChange('city');
        } else {
          this.cityName = '';
        }
      },
      err => {
        this.app.spinner.hide();
        console.log('get city by postal code err', err)
      }
    );
  }

  /**
   * Remove validation errors
   * @param field
   */
  onChange(field = null): void {
    this.validationService.removeErrors(field);
  }

  saveFormDisabled = true;
  personalInformationChange(fieldName: string): void {
    this.validationService.setForm(this.personalInformation);
    if (this.validationService.validate()) {
      this.saveFormDisabled = false;
      this.validationService.removeError(fieldName);
    } else {
      this.validationService.removeErrors();
      if (this.personalInformation.get(fieldName).status == "INVALID") {
        this.validationService.displayErrors('customerProfileFrom', fieldName);
      } else {
        this.validationService.removeError(fieldName);
      }
      this.saveFormDisabled = true;
    }
  }

  changeCardDetail() {
    var regNo = this.personalInformation.get('regNo').value;
    var acNo = this.personalInformation.get('acNo').value;

    if (!regNo || !acNo) {
      this.toastr.error('Please add these two fields and try again', 'Card Detail Notification');
      return;
    }

    if (this.personalInformation.get("regNo").status == "INVALID") {
      this.validationService.displayErrors('customerProfileFrom', "regNo");
      return;
    }

    if (this.personalInformation.get("acNo").status == "INVALID") {
      this.validationService.displayErrors('customerProfileFrom', "acNo");
      return;
    }

    var data = {
      reg_no: regNo,
      ac_no: acNo
    }
    var URL = "update-bank-account?token=" + localStorage.getItem("authToken");
    this.app.spinner.show();
    this.apiService.postData(URL, data).then(
      response => {
        this.app.spinner.hide();
        if (response["resCode"] == 200) {
          this.toastr.success("Successfully updated bank info.", "Bank Info");
        } else {
          this.toastr.warning(response["resMsg"], "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        }
      },
      err => {
        console.log("back update error", err);
        this.app.spinner.hide();
        this.toastr.error(
          "Problem in updating the data. Please try again.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
      }
    );
  }

  public user_reviewes = [];
  public customer_reviewes = [];

  getYourReviewes() {
    //var URL = Constant.SLUGS["userReviews"] + "?token=" + localStorage.getItem('authToken');
    this.apiService.getData(Constant.SLUGS["userReviews"]).then(
      response => {
        if (response["resCode"] == 200 || !response["resCode"]) {
          this.customer_reviewes = response["customer_reviews"];
          this.user_reviewes = response["user_reviews"];
        }
        else {
          this.customer_reviewes = [];
          this.user_reviewes = [];
          // this.toastr.error(response["resMsg"], 'Reviews');
        }
      },
      err => {
        this.customer_reviewes = [];
        this.user_reviewes = [];
        this.toastr.error(err.message, "", {
          timeOut: Constant.ERROR_TIMEOUT,
          positionClass: Constant.MESSAGE_POSITION,
          closeButton: true
        });
        console.log("region err", err);
      }
    );
  }

  public helpersCustomers = [];
  public selectedHelper = '';
  public review_comments = '';

  /**
   * get helper's customer
   */
  getCustomersHelper() {
    var url = Constant.SLUGS["getHelpersCustomer"] + "?customer_id=" + localStorage.getItem('userId') + "&token=" + localStorage.getItem('authToken');
    this.apiService.getData(url).then(
      response => {
        if (response["resCode"] == 200) {
          this.helpersCustomers = response["data"];
          this.helpersCustomers.unshift({ 'userId': '', 'userName': 'Select Helper' })
        } else if (response["resCode"] == 400 && response["resMsg"] == "nodata") {
          //this.toastr.info('No any helper found for you', localStorage.getItem('userName'));
          this.helpersCustomers = [];
          this.helpersCustomers.unshift({ 'userId': '', 'userName': 'Select Helper' })
        }
      },
      err => {
        this.helpersCustomers = [];
        this.toastr.error("Problem in fetching events. Please try again", "", {
          timeOut: Constant.ERROR_TIMEOUT,
          positionClass: Constant.MESSAGE_POSITION,
          closeButton: true
        });
      }
    );
  }

  bookings = [];
  getBookings() {
    var URL = "get-customer-booking-order?token=" + localStorage.getItem("authToken");
    this.apiService.getData(URL).then(
      response => {
        console.log(response);
        if (response["resCode"] == 200) {
          this.bookings = response["data"];
          for (var i = 0; i < this.bookings.length; i++) {
            var booking = this.bookings[i];
            var cleanDate = booking.cleaning_date + ' ' + booking.cleaning_time;
            const datePipe = new DatePipe('en-US');
            const cleanFormattedDate = datePipe.transform(cleanDate, 'yyyy-MM-dd HH:mm');
            const currentFormattedDate = datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm');

            const diffInMs = Date.parse(currentFormattedDate) - Date.parse(cleanFormattedDate);
            const diffInHours = diffInMs / 1000 / 60 / 60;
            // console.log(diffInHours);
            if (diffInHours < 24) {
              booking.isDisabled = false;
            } else {
              booking.isDisabled = true;
            }

          }
        } else if (response["resCode"] == 400 && response["resMsg"] == "nodata") {
          console.log('no helper found for you');
          this.bookings = [];
        }
      },
      err => {
        this.bookings = [];
        this.toastr.error("Problem in fetching events. Please try again", "", {
          timeOut: Constant.ERROR_TIMEOUT,
          positionClass: Constant.MESSAGE_POSITION,
          closeButton: true
        });
      }
    );
  }

  review_ratings = 0;
  onRate($event: { oldValue: number, newValue: number }) {
    console.log($event);
    this.review_ratings = $event.newValue;
  }

  saveReview() {
    if (!this.selectedHelper) {
      this.toastr.error('Please select helper to add review.', 'Helper Name');
      return;
    }

    if (!this.review_comments) {
      this.toastr.error('Please add your comment to add review.', 'Comment');
      return;
    }

    var postData = {
      "review_id": this.selectedHelper,
      "user_id": localStorage.getItem("userId"),
      "review_ratings": this.review_ratings,
      "review_comments": this.review_comments,
      "review_status": 1
    }

    var URL = "review-insert?token=" + localStorage.getItem("authToken");
    this.apiService.postData(URL, postData).then(
      response => {
        if (response["resCode"] == 200) {
          this.selectedHelper = '';
          this.review_ratings = 0;
          this.review_comments = '';

          this.getYourReviewes();
          this.getCustomersHelper();

        } else {
          this.toastr.warning(response["resMsg"], "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        }
      },
      err => {
        console.log("register err", err);
        this.toastr.error(
          "Problem in fetching the data. Please try again.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
      }
    );
  }

  public dbAreas: any = [];
  notification = "0";
  bindProfileDetail(data: any) {
    if (data.chooseAreas.length > 0) {
      this.dbAreas = this.data.chooseAreas;
    }
    this.userName = this.data.first_name;
    this.status = this.data.status;
    if (this.status == 0) {
      this.statusMsg = "Your profile is not approved yet.";
    } else if (this.status == 3) {
      this.statusMsg = "Your profile is rejected now.";
    }

    // Check reminders value
    if (this.data.reminder_by == 0) {
      this.personalInformation.get("emailReminder").patchValue(true);
    } else if (this.data.reminder_by == 1) {
      this.personalInformation.get("smsReminder").patchValue(true);
    } else {
      this.personalInformation.get("emailReminder").patchValue(true);
      this.personalInformation.get("smsReminder").patchValue(true);
    }

    this.notification = this.data.notification_by;

    this.personalInformation.get("notification").patchValue(this.data.notification_by)

    this.personalInformation.get("firstname").patchValue(data.first_name);
    // this.personalInformation.get("lastname").patchValue(data.last_name);
    this.personalInformation.get("street").patchValue(data.street_line == null ? "" : data.street_line);
    this.personalInformation.get("address1").patchValue(data.address_near);
    this.personalInformation.get("address2").patchValue(data.address_floor == "null" ? '' : data.address_floor);
    this.personalInformation.get("postalCode").patchValue(data.postal_code);
    this.personalInformation.get("city").patchValue(data.city);
    this.personalInformation.get("phone").patchValue(data.phone.replace("+45", ""));
    this.personalInformation.get("email").patchValue(data.email);
    this.personalInformation.get("cvr").patchValue(data.cvr);
    this.personalInformation.get("regNo").patchValue(data.reg_no);
    this.personalInformation.get("acNo").patchValue(data.ac_no);
  }


  submitProfileDetail() {
    let notification_email = "false";
    let notification_sms = "false";

    if (this.personalInformation.get("notification").value == '0') {
      notification_email = "true";
      notification_sms = "false";
    }
    else if (this.personalInformation.get("notification").value == '1') {
      notification_email = "false";
      notification_sms = "true";
    }

    let fileData: FormData = new FormData();

    fileData.append("first_name", this.personalInformation.get('firstname').value);
    fileData.append("last_name", '');
    fileData.append("street_line", this.personalInformation.get('street').value);
    fileData.append("addressNear", this.personalInformation.get('address1').value);
    fileData.append("addressFloor", this.personalInformation.get('address2').value);
    fileData.append("postal_code", this.personalInformation.get('postalCode').value);
    fileData.append("city", this.personalInformation.get('city').value);
    fileData.append("phone_code", this.personalInformation.get('phone_code').value);
    fileData.append("phone", this.personalInformation.get('phone').value);
    fileData.append("email", this.personalInformation.get('email').value);
    fileData.append("cvr", this.personalInformation.get('cvr').value);
    fileData.append("old_password", this.personalInformation.get('oldPassword').value);
    fileData.append("password", this.personalInformation.get('password').value);
    fileData.append("confirm_password", this.personalInformation.get('confirmPassword').value);
    fileData.append("reg_no", this.personalInformation.get("regNo").value);
    fileData.append("ac_no", this.personalInformation.get("acNo").value);
    fileData.append("status", this.status.toString());
    fileData.append("reminder_email", this.personalInformation.get("emailReminder").value);
    fileData.append("reminder_sms", this.personalInformation.get("smsReminder").value);
    fileData.append("notification_email", notification_email); // new field
    fileData.append("notification_sms", notification_sms); // new field

    // // fileData.append("hourly_rate",'');
    // // fileData.append("image", null);
    // fileData.append("cpr", cpr);
    // fileData.append("user_bio", this.profileFormGroup.get("userBio").value);
    this.changeCardDetail();
    this.app.spinner.show();
    this.apiService
      .postDataWithAttachment(Constant.SLUGS["updateProfile"], fileData)
      .then(
        response => {
          this.app.spinner.hide();
          if (response["resCode"] == 200) {
            this.personalInformation.reset();
            this.personalInformation.get("phone_code").patchValue("+45");
            this.toastr.success(response["resMsg"], "", {
              timeOut: Constant.ERROR_TIMEOUT,
              positionClass: Constant.MESSAGE_POSITION,
              closeButton: true
            });
            this.getProfile();
          } else {
            this.toastr.warning(response["resMsg"], "", {
              timeOut: Constant.ERROR_TIMEOUT,
              positionClass: Constant.MESSAGE_POSITION,
              closeButton: true
            });
          }
        },
        err => {
          // console.log("update profile err", err);
          this.app.spinner.hide();
          this.toastr.error(
            "Problem in fetching the data. Please try again.",
            "",
            {
              timeOut: Constant.ERROR_TIMEOUT,
              positionClass: Constant.MESSAGE_POSITION,
              closeButton: true
            }
          );
        }
      );
  }

  bookingDetail;
  getBookingDetail(id) {
    var URL = "get-booking-edit?token=" + localStorage.getItem('token') + '&id=' + id;
    this.apiService.getData(URL).then(
      response => {
        this.app.spinner.hide();
        if (response["resCode"] == 200) {
          console.log("profile ", response);
          this.bookingDetail = response["data"];
          this.bindBookingDetail(this.bookingDetail[0]);
          this.modalService.open(this.content);
        } else {
          this.toastr.error(response["resMsg"], "User Profile");
        }
      })
  }

  bindBookingDetail(bookingDetail) {
    var date = new Date(bookingDetail.cleaning_date);

    var cleanigDate = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    }

    this.updateBookingForm.get('cleaning_date').patchValue(cleanigDate);
    var arrTime = bookingDetail.cleaning_time.split(":");

    var hour = arrTime[0];
    var minute = arrTime[1];
    var second = arrTime[2];

    this.time.hour = parseInt(hour);
    this.time.minute = parseInt(minute);
    this.time.second = parseInt(second);

    this.updateBookingForm.get('cleaning_time').patchValue(this.time);
    this.updateBookingForm.get('booking_id').patchValue(bookingDetail.booking_id);
  }

  updateBookingHelper() {
    let formData = this.updateBookingForm.value;
    var bookPost = {
      "booking_id": formData.booking_id,
      "cleaning_time": ("0" + this.time.hour).slice(-2) + ":" + ("0" + this.time.minute).slice(-2) + ":" + ("0" + this.time.second).slice(-2),
      "cleaning_date": formData.cleaning_date.year + "-" + formData.cleaning_date.month + "-" + formData.cleaning_date.day
    }
    var URL = "update-booking-data?token=" + localStorage.getItem("authToken");
    this.app.spinner.show();
    this.apiService.postData(URL, bookPost).then(
      response => {
        this.app.spinner.hide();
        if (response["resCode"] == 200) {
          this.modalService.dismissAll();
          this.getBookings();
          this.toastr.success("Successfully updated bookings.", "Bookings");
        } else {
          this.toastr.warning(response["resMsg"], "", {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          });
        }
      },
      err => {
        ////console.log("back update error", err);
        this.app.spinner.hide();
        this.toastr.error(
          "Problem in updating the data. Please try again.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
      }
    );
  }


  // chat Section/

  isSupportSelected = true;
  helperSelected = '';
  messagesWithSupport = [];
  messagesWithHelper = [];
  messages = [];
  message = '';
  logginUserId = localStorage.getItem('userId');
  selectedCustomerName = '';
  getChatMessages(autoFetch = false) {
    if (!this.sentNewMessage) {
      if (this.isSupportSelected) {
        this.getSupportMessages(autoFetch);
      }
      if (!this.isSupportSelected) {
        this.getHelperMessages(autoFetch);
        this.isSupportSelected = false;
      }
    }
  }

  getSupportMessages(autoFetch = false) {
    var self = this;
    this.helperSelected = '';
    if (!autoFetch) {
      this.messages = [];
    }

    var alreadyCalled;
    if (this.isSupportSelected) {
      if (alreadyCalled) {
        alreadyCalled.abort();
      }
      var url = Constant.API_URL + 'admin-support-message-view?token=' + localStorage.getItem('authToken') + '&to_userid=' + 1;
      alreadyCalled = $.get(url, function (suppportMessages, status) {
        if (suppportMessages.resCode == 200) {
          // this.messagesWithSupport = suppoerMessages.data;
          if (!self.sentNewMessage) {
            self.messages = suppportMessages.data;
          }
        }
      }).done(function (data) {
        alreadyCalled = undefined;
      })
        .fail(function () {
          alreadyCalled = undefined;
        });
    }
  }

  getHelperMessages(autoFetch = false) {
    var self = this;
    var alreadyCalled;
    if (!autoFetch) {
      this.messages = [];
    }

    if (!this.isSupportSelected) {
      if (alreadyCalled) {
        alreadyCalled.abort();
      }
      var url = Constant.API_URL + 'user-support-messages-view?token=' + localStorage.getItem('authToken') + '&to_userid=' + this.helperSelected;
      alreadyCalled = $.get(url, function (helperMessages, status) {
        if (helperMessages.resCode == 200) {
          // this.messagesWithHelper = helperMessages.data;
          if (!self.sentNewMessage) {
            self.messages = helperMessages.data;
          }

        }
      }).done(function (data) {
        alreadyCalled = undefined;
      })
        .fail(function () {
          alreadyCalled = undefined;
        });
    }
  }

  sentNewMessage = false;
  submitMessage() {
    if (!this.message || this.message.trim() == '') {
      this.toastr.error('Please Enter a message.', 'Inbox');
      this.message = undefined;
      this.message = "";
      return false;
    }
    this.sentNewMessage = true;
    if (this.isSupportSelected) {
      this.sendMesagesToSupport();
    }
    else if (!this.isSupportSelected) {
      this.sendMesageToHelper();
    }
  }

  sendMesagesToSupport() {
    var self = this;
    var supportChatObject: any = {
      "from_id": localStorage.getItem('userId'),
      "to_id": 1,
      "message": this.message,
      'userName': 'You, ',
      'loadUniqId': Date.now(),
      'failUniqueId': Date.now() + 50,
      'messagenotsent': false
    }

    const datePipe = new DatePipe('en-US');
    this.app.spinner.hide();
    supportChatObject.created_at = datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    let token = localStorage.getItem("authToken");
    var url = Constant.API_URL + 'admin-support-messages-add?token=' + token;

    // this.messagesWithSupport.unshift(supportChatObject);
    this.messages.unshift(supportChatObject);
    self.message = '';
    setTimeout(() => { $("#" + supportChatObject.loadUniqId).show(); }, 100);
    setTimeout(() => {
      $.post(url, supportChatObject,
        function (data, status) {
          if (data.resCode == 200) {
            $("#" + supportChatObject.loadUniqId).hide();
          } else {
            supportChatObject.messagenotsent = true;
            $("#" + supportChatObject.loadUniqId).hide();
            $("#" + supportChatObject.failUniqueId).show();
            self.sentNewMessage = false;
          }
        }).done(function (data) {
          $("#" + supportChatObject.loadUniqId).hide();
          $("#" + supportChatObject.failUniqueId).hide();
          self.sentNewMessage = false;
        })
        .fail(function () {
          supportChatObject.messagenotsent = true;
          $("#" + supportChatObject.loadUniqId).hide();
          $("#" + supportChatObject.failUniqueId).show();
          self.sentNewMessage = false;
        });
    }, 1000);

  }

  sendMesageToHelper() {
    var self = this;
    if (!this.helperSelected) {
      self.toastr.error('Please select any helper', 'Inbox');
      return false;
    }
    var user = self.helpersCustomers.filter(function (item) {
      if (item.userId == self.helperSelected) {
        return item;
      }
    })

    if (user && user.length > 0) {
      self.selectedCustomerName = user[0].userName;
    }
    var helperChatObject: any = {
      "from_id": localStorage.getItem('userId'),
      "to_id": this.helperSelected,
      "message": this.message,
      'userName': 'You, ',
      'loadUniqId': Date.now(),
      'failUniqueId': Date.now() + 50,
      'messagenotsent': false
    }

    const datePipe = new DatePipe('en-US');
    this.app.spinner.hide();
    helperChatObject.created_at = datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    let token = localStorage.getItem("authToken");
    var url = Constant.API_URL + 'user-support-messages-add?token=' + token;

    // this.messagesWithHelper.unshift(helperChatObject);
    this.messages.unshift(helperChatObject);
    self.message = '';
    setTimeout(() => { $("#" + helperChatObject.loadUniqId).show(); }, 100);
    setTimeout(() => {
      $.post(url, helperChatObject,
        function (data, status) {
          if (data.resCode == 200) {
            $("#" + helperChatObject.loadUniqId).hide();
          } else {
            helperChatObject.messagenotsent = true;
            $("#" + helperChatObject.loadUniqId).hide();
            $("#" + helperChatObject.failUniqueId).show();
            self.sentNewMessage = false;
          }
        }).done(function (data) {
          $("#" + helperChatObject.loadUniqId).hide();
          $("#" + helperChatObject.failUniqueId).hide();
          self.sentNewMessage = false;
        })
        .fail(function () {
          helperChatObject.messagenotsent = true;
          $("#" + helperChatObject.loadUniqId).hide();
          $("#" + helperChatObject.failUniqueId).show();
          self.sentNewMessage = false;
        });
    }, 1000);
  }

  keyDownFunction(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.submitMessage();
      // rest of your code
    }
  }

  /**
   * after tab click
   * @param tab 
   */
  tabClick(tab) {
    this.selectedTab = tab.index;
  }
  // accontNo = [];
  // /**mask account number */
  // maskAccountNumber(ele){
  //   if(ele.data){
  //     this.accontNo.push(ele.data);
  //   }
  //   this.accountNumber = this.accountNumber.replace(/\d(?=\d{4})/g, "*");
  // }

  buttonFB: string = 'connect';
  buttonTW: string = 'connect';
  buttonGL: string = 'connect';

  connectGoogle(): void {
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
    // 	(userData) => { // on success
    // 		// this will return user data from google. What you need is a user token which you will send it to the server
    // 		this.onSocialLogin("Google", userData['id'], userData['email']);
    // 	}
    // );

  }

  connectFacebook(): void {
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
    // 	(userData) => { // on success
    // 		// this will return user data from fb. What you need is a user token which you will send it to the server
    // 		this.onSocialLogin("Facebook", userData['id'], userData['email']);
    // 	}
    // );
  }

  connectTwitter() {
    // var oauthToken = '';
    // var self = this;
    // //To Do to call request_token
    // this.apiService.getData('twitter-request-token').then(
    // 	twtRes => {

    // 		this.app.spinner.hide();
    // 		if (twtRes["resCode"] == 200) {

    // 			var twitterData = twtRes["data"];
    // 			oauthToken = twitterData[0].oauth_token;
    // 			popTwitterSignIn(oauthToken, function (response) {

    // 				var accessTokenUrl = 'twitter-access-token?oauth_token=' + response.oauth_token + '&oauth_verifier=' + response.oauth_verifier;
    // 				console.log("##accessTokenUrl:  ", accessTokenUrl)
    // 				self.apiService.getData(accessTokenUrl).then(
    // 					accessTokenRes => {

    // 						self.app.spinner.hide();
    // 						if (accessTokenRes["resCode"] == 200) {
    // 							self.onSocialLogin("Twitter", accessTokenRes["data"][2].user_id, accessTokenRes["data"][2].user_email);
    // 						} else {
    // 							self.toastr.error('Something went wrong while authenticating your request!', 'Error')
    // 						}
    // 					});
    // 			});
    // 		}
    // 		else{
    // 			this.toastr.warning(twtRes['msg'].message, '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    // 		}
    // 	})
  }
  isEmailExist: any;
  errorMessages: any;
  onSocialLogin(socialtype, socialId, email) {
    this.apiService.getData(Constant.SLUGS['isEmailExist'] + '/' + email).then(
      response => {

        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          if (response['data'].social_type == socialtype) {
            var data = {
              socialId: socialId,
              type: "social"
            }
            //this.app.spinner.show();
            this.apiService.postData(Constant.SLUGS['login'], data).then(
              response => {
                this.isEmailExist = true;
                this.app.spinner.hide();
                if (response['resCode'] == 200) {
                  var userRole = response['data']['userRole'];

                  // Store value for login
                  localStorage.setItem('authToken', response['data']['token']);
                  localStorage.setItem("userId", response['data']['userId']);
                  localStorage.setItem("userName", response['data']['name']);
                  localStorage.setItem("role", userRole);

                  if (userRole == "client") {
                    this.router.navigateByUrl('/customer/profileC00');
                  } else {
                    this.router.navigateByUrl('/helper/profile00');
                  }

                }
                else {
                  this.errorMessages = response['resMsg'];
                }
              },
              err => {
                console.log('register err', err);
                this.app.spinner.hide();
                this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
              }
            );
          }
          else {
            this.isEmailExist = false;
          }
        }
        else {
          this.isEmailExist = false;
        }
      },
      err => {
        this.app.spinner.hide();
        console.log('email checking err', err)
      }
    );

  }
  pipe = new DatePipe('en-US');
  
  makePdf(booking): void {
    let popupWin;
    var date = this.pipe.transform(booking.cleaning_date, 'short');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    
    //popupWin.document.open();
    popupWin.document.write(`
  <html><head><style type="text/css">
    @page {
        size: A4;
        margin: 30px 50px;
    }
    @media print {
        html,
        body {
            width: 210mm;
            height: 297mm;
        }
    }
    * {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
    }
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
    }
    body {
        background: #fff;
        font-family: Arial, Helvetica, sans-serif font-size: 14px;
        line-height: 20px;
        color: #333;
        padding: 0
    }
    a {
        text-decoration: none;
    }
    b,
    strong {
        font-weight: 600
    }
    .pull-left {
        float: left
    }
    .pull-right {
        float: right
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    td,
    th {
        padding: 5px 10px;
        font-size: 14px;
        line-height: normal;
        text-align: left;
        vertical-align: top;
        font-family: Arial, Helvetica, sans-serif
    }
    .table-wrap {
        width: 100%;
        margin: 0 auto;
        padding: 0px 0;
    }
    td.spacer5,
    .spacer5 {
        height: 5px;
        padding: 0 !important;
        clear: both
    }
    td.spacer10,
    .spacer10 {
        height: 10px;
        padding: 0;
        clear: both
    }
    td.spacer15,
    .spacer15 {
        height: 15px;
        padding: 0;
        clear: both
    }
    td.spacer20,
    .spacer20 {
        height: 20px;
        padding: 0
    }
    td.spacer40,
    .spacer40 {
        height: 40px;
        padding: 0
    }
    td.spacer30,
    .spacer30 {
        height: 30px;
        padding: 0
    }
    .text-center {
        text-align: center
    }
    .text-left {
        text-align: left !important
    }
    .text-right {
        text-align: right
    }
    td.inner-table {
       padding: 0;
    }
    td.inner-table td,
    td.inner-table th {
        border: 1px solid #ccc;
        padding: 6px 10px 3px;
        font-size: 15px;
        line-height: normal;
        vertical-align: middle;
        border-left: none;
        border-right: none;
    }
    td.inner-table td strong {
        font-weight: 700
    }
    td.inner-table th {
        font-weight: 600;
        font-size: 15px;
        line-height: normal;
        border-right-color: rgba(255, 255, 255, 0.5);
        padding: 6px 10px 3px;
        vertical-align: baseline;
        border-left: none;
        border-right: none;
    }
    td.inner-table th:last-child {
        border-right-color: #ccc
    }
    td.inner-table td {
        border: none;
        padding: 3px 10px;
        line-height: 15px;
    }
    .heading-cell {
        padding: 0;
    }
    td.spacer60,
    .spacer60 {
        height: 60px;
        padding: 0
    }
    .invoice-subhead {
        padding: 0;
    }
    .invoice-subhead>table {
        display: inline-table;
        vertical-align: top;
        height: 100%;
        vertical-align: middle;
        border-collapse: separate;
    }
    .invoice-subhead table tr td,
    .invoice-subhead table tr th {
        padding-left: 0;
    }
    .invoice-left-table {
        margin-right: -4px;
        width: 70%;
        padding-right: 15px;
    }
    .invoice-right-table {
        width: 30%;
    }
    .invoice-subhead table tr th {
        padding-bottom: 10px;
        border-bottom: 1px solid #000;
        font-size: 18px;
        text-transform: uppercase;
    }
    .invoice-subhead table tr th {
        padding-left: 0;
    }
    .invoice-subhead table tr td {
        padding-left: 0;
    }
    .invoice-right-table th:after {
        content: ''
    }
    ul.leaders {
        max-width: 18em;
        padding: 0;
        overflow-x: hidden;
        list-style: none
    }
    ul.leaders li:before {
        float: left;
        width: 0;
        white-space: nowrap;
        content:
            ". . . . . . . . . . . . . . . . . . . . "
            " : "
    }
    ul.leaders li:after {
        float: left;
        width: 0;
        white-space: nowrap;
        content: ":"
    }
    ul.leaders span:first-child {
        padding-right: 0.33em;
        background: white
    }
    ul.leaders span+span {
        float: right;
        padding-left: 0.5em;
        background: white;
        width: 83px;
    }
    td.inner-table tfoot>tr>td,
    tfoot>tr>th {
        border-top: 1px solid #000;
        padding: 16px 4px;
        vertical-align: top;
    }
    td.inner-table tfoot .total-table th,td.inner-table tfoot .total-table td{
        border: none;
        font-weight: normal;
        padding: 0 4px 16px;
    }
    td.inner-table tfoot .total-table th {width: 55%;text-align: right;padding-right: 17px;}
    td.inner-table tfoot .total-table td {
        text-align: right;
        width: 45%;
    }
    td.inner-table tfoot .total-table .grand-total th, td.inner-table tfoot .total-table .grand-total td {
        border-bottom: 1px solid #000;
    }
    td.inner-table tfoot>tr>td:first-child {
        font-size: 12px;
    }
    .invoice-left-table th {
        height: 23px;
    }
    td.inner-table tfoot {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: table;
    }
    .table-wrap {
        position: relative;
        min-height: 100%;
        height: 100%;
    }
    html,body {
        height: 100%;
        min-height: 100%;
    }
    body {
        padding-bottom: 167px;
    }
    td.inner-table tfoot>tr {
        display: table-row;
        width: 100%;
    }
    </style>
      <title>Invoice</title>      
    </head>
    <body onload="window.print();window.close()">
    <div class="table-wrap" style="page-break-after: always;" id="contentPdF">
        <table class="main-table" cellpadding="0" border="0" cellspacing="0">
            <tr>
                <td align="left" class="heading-cell">
                    <table cellpadding="0" border="0" cellspacing="0">
                        <tr>
                            <th>
                            </th>
                            <th>
                            Delivery address:
                            </th>
                        </tr>
                        <tr>
                            <td>
                               ${booking.cleaning_area} Valby
                            </td>
                            <td>
                                <br>
                                ${booking.cleaning_area} Valby
                            </td>
                </td>
            </tr>
        </table>
        </td>
        </tr>
        <tr>
            <td align="left" class="invoice-subhead">
                <table cellpadding="0" border="0" cellspacing="0" class="invoice-left-table">
                    <tr>
                        <th>
                        </th>
                    </tr>
                    <tr>
                        <td class="spacer20"></td>
                    </tr>
                    <tr>
                        <td>
                            <b>88888 Cleaning</b><br>
                            Cleaning performed by :  ${booking.first_name}<br>
                            CPR: Remember to be given a civil registration number. of your Helper <br>
                            TransactionID 00000000
                        </td>
                    </tr>
                </table>
                <table cellpadding="0" border="0" cellspacing="0" class="invoice-right-table">
                    <tr>
                        <td align="left">
                            <table cellpadding="0" border="0" cellspacing="0">
                                <tr>
                                    <th>
                                    INVOICE
                                    </th>
                                <tr>
                                    <td class="spacer20"></td>
                                </tr>
                    </tr>
                    <tr>
                        <td>
                            <ul class=leaders>
                                <li><span>Invoice number.</span>
                                    <span>888888888</span>
                                <li><span>Invoice date</span>
                                    <span>${date}</span>
                                <li><span>Booking date</span>
                                    <span>${date}</span>
                                <li><span>Customer no.</span>
                                    <span>1</span>
                            </ul>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        </table>
        </td>
        </tr>
        <tr>
            <td class="spacer60"></td>
        </tr>
        <tr class="bank-tbl">
            <td class="inner-table">
                <table cellpadding="0" border="0" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="text-right">Name</th>
                            <th class="text-left">
                            Date
                            </th>
                            <th class="text-right">
                            Telephone
                            </th>
                            <th class="text-left">
                            Hourly rate
                            </th>
                            <th class="text-right">
                            Earnings
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-right">
                            ${booking.first_name}
                            </td>
                            <td class="text-left">
                            ${date}
                            </td>
                            <td class="text-right">
                            ${booking.phone}
                            </td>
                            <td class="text-left">
                            ${booking.hourlyRate}
                            </td>
                            <td class="text-right">
                            ${booking.cleaning_amount}
                            </td>
                        </tr>                     
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4">
                                (Momsfrit belob: <b>225,00</b> - Momspligtigt belob: <b>0,00</b>)
                            </td>
                            <td colspan="2">
                                <table cellpadding="0" border="0" cellspacing="0" class="total-table">
                                  <tr class="grand-total">
                                        <th>
                                            <b>Total DKK:</b>
                                        </th>
                                        <td>
                                            <b>${booking.cleaning_amount}</b>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </td>
        </tr>
        </table>
    </div>
</body>

   
  </html>`
    );
    //popupWin.close();

    var data =popupWin.document.documentElement; //document.getElementById('contentPdF');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  
      
      var myDate = new Date();
      var savedate = this.pipe.transform(myDate ,'short');

      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save(booking.first_name+"_"+savedate); // Generated PDF   
    });  
   
    //setTimeout ( popupWin.close(), 10000);
  }

}
