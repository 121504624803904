import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from '../../services/validation.service';
import { Constant } from '../../settings/constants';
import { ApiService } from '../../services/api.service';
import { AppComponent } from '../../app.component';
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
declare function popTwitterSignIn(outhToken, callback): any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	isEmailExist: any;
	isEmailWrong:any;
	errorMessages: any;
	public loginForm: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private validationService: ValidationService,
		private apiService: ApiService,
		private toastr: ToastrService,
		public app: AppComponent,
		private authService: AuthService
	) { }
	public isEnglish: boolean = true;
	ngOnInit() {

		// var userId = localStorage.getItem("userId");
		// if (userId) {
		// 	var userRole = localStorage.getItem("role");
		// 	var isEnglishLanguage = localStorage.getItem('Language');
		// 	if (isEnglishLanguage == "false") {
		// 		if (userId && userRole == "client") {
		// 			this.router.navigateByUrl('/customer/profileC00-dk');
		// 		} else {
		// 			this.router.navigateByUrl('/helper/profile00-dk');
		// 		}
		// 	} else {
		// 		if (userId && userRole == "client") {
		// 			this.router.navigateByUrl('/customer/profileC00');
		// 		} else {
		// 			this.router.navigateByUrl('/helper/profile00');
		// 		}
		// 	}
		// }
		// else {
		// 	this.router.navigateByUrl('/login');
		// }

		localStorage.removeItem('authToken');
		localStorage.removeItem("userId");
		localStorage.removeItem("userName");
		localStorage.removeItem("role");
		localStorage.removeItem("isSocialLogin");

		// Create form group
		this.createForm();
	}


	/**
	 * Create lgoin form
	 */
	createForm() {
		this.loginForm = this.formBuilder.group({
			email: ['', (Validators.required)],
			password: ['', (Validators.required)]
		})
	}

	/**
   * Submit form value
   */
    /** Switch Language */
	switchLanguage(isEnglish) {
		localStorage.setItem('Language', isEnglish);
		if (isEnglish) {
		  this.router.navigateByUrl('/login');
		} else {
		  this.router.navigateByUrl('/login-dk');
		}
	  }
	onSubmit() {
		this.validationService.setForm(this.loginForm);
		if (this.validationService.validate()) {
			var data = {
				email: this.loginForm.controls['email'].value,
				password: this.loginForm.controls['password'].value
			}
			this.app.spinner.show();
			this.apiService.postData(Constant.SLUGS['login'], data).then(
				response => {
					this.app.spinner.hide();
					if (response['resCode'] == 200) {
						//	this.toastr.success(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
						var userRole = response['data']['userRole'];

						// Store value for login
						localStorage.setItem('authToken', response['data']['token']);
						localStorage.setItem("userId", response['data']['userId']);
						localStorage.setItem("userName", response['data']['name']);
						localStorage.setItem("role", userRole);

						if (userRole == "client") {
							this.router.navigateByUrl('/customer/profileC00');
						} else {
							this.router.navigateByUrl('/helper/profile00');
						}

					} else {
						this.isEmailWrong = true;
						this.errorMessages = response['resMsg'];
						//this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
					}
				},
				err => {
					console.log('register err', err);
					this.app.spinner.hide();
					this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
				}
			);
		} else {
			this.validationService.removeErrors();
			this.validationService.displayErrors();
		}
	}

	/**
	 * Remove error message
	 * @param field 
	 */
	onChange(field = null) {
		this.validationService.removeErrors(field);
	}

	signInWithGoogle(): void {
		this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
			(userData) => { // on success
				// this will return user data from google. What you need is a user token which you will send it to the server
				this.onSocialLogin("Google", userData['id'], userData['email']);
			}
		);

	}

	signInWithFB(): void {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
			(userData) => { // on success
				// this will return user data from fb. What you need is a user token which you will send it to the server
				this.onSocialLogin("Facebook", userData['id'], userData['email']);
			}
		);
	}

	signInWithTwitter() {
		var oauthToken = '';
		var self = this;
		//To Do to call request_token
		this.apiService.getData('twitter-request-token').then(
			twtRes => {

				this.app.spinner.hide();
				if (twtRes["resCode"] == 200) {

					var twitterData = twtRes["data"];
					oauthToken = twitterData[0].oauth_token;
					popTwitterSignIn(oauthToken, function (response) {

						var accessTokenUrl = 'twitter-access-token?oauth_token=' + response.oauth_token + '&oauth_verifier=' + response.oauth_verifier;
						console.log("##accessTokenUrl:  ", accessTokenUrl)
						self.apiService.getData(accessTokenUrl).then(
							accessTokenRes => {

								self.app.spinner.hide();
								if (accessTokenRes["resCode"] == 200) {
									self.onSocialLogin("Twitter", accessTokenRes["data"][2].user_id, accessTokenRes["data"][2].user_email);
								} else {
									self.toastr.error('Something went wrong while authenticating your request!', 'Error')
								}
							});
					});
				}
				else{
					this.toastr.warning(twtRes['msg'].message, '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
				}
			})
	}

	onSocialLogin(socialtype, socialId, email) {
		this.apiService.getData(Constant.SLUGS['isEmailExist'] + '/' + email).then(
			response => {

				this.app.spinner.hide();
				if (response['resCode'] == 200) {					
					if (response['data'].social_type == socialtype) {
						var data = {
							socialId: socialId,
							type: "social"
						}
						//this.app.spinner.show();
						this.apiService.postData(Constant.SLUGS['login'], data).then(
							response => {
								this.isEmailExist = true;
								this.app.spinner.hide();
								if (response['resCode'] == 200) {
									//this.toastr.success(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
									var userRole = response['data']['userRole'];

									// Store value for login
									localStorage.setItem('authToken', response['data']['token']);
									localStorage.setItem("userId", response['data']['userId']);
									localStorage.setItem("userName", response['data']['name']);
									localStorage.setItem("role", userRole);

									if (userRole == "client") {
										this.router.navigateByUrl('/customer/profileC00');
									} else {
										this.router.navigateByUrl('/helper/profile00');
									}

								}
								else {
									this.errorMessages = response['resMsg'];
									//this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
								}
							},
							err => {
								console.log('register err', err);
								this.app.spinner.hide();
								this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
							}
						);
					}
					else {
						this.isEmailExist = false;
						//this.errorMessages = "Before you can log in, you must have an account with us. You can create a profile as a customer by ordering service. If you want to work as a helper, you can create a profile here.";
						//this.toastr.warning("Before you can log in, you must have an account with us. You can create a profile as a customer by ordering service. If you want to work as a helper, you can create a profile here.", '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
					}
				}
				else {
					this.isEmailExist = false;
					//this.errorMessages = "Before you can log in, you must have an account with us. You can create a profile as a customer by ordering service. If you want to work as a helper, you can create a profile here.";
					//this.toastr.warning("Before you can log in, you must have an account with us. You can create a profile as a customer by ordering service. If you want to work as a helper, you can create a profile here.", '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
				}
			},
			err => {
				this.app.spinner.hide();
				console.log('email checking err', err)
			}
		);

	}

}
