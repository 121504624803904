import { Injectable, Component } from '@angular/core';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateStruct,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomDateFormatService {

  constructor() { }
}
/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {
      let date = value.split(this.DELIMITER);
      result = {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return result;
  }

  format(date: NgbDateStruct): string {
    var now: any = new Date();
  
    let result: string = null;
    if (date) {
      result = date.month + this.DELIMITER + date.day + this.DELIMITER + date.year;
    }
    else{
      result = now.getMonth()+1 + this.DELIMITER + now.getDate()+ this.DELIMITER + now.getFullYear()
    }
    return result;
  }
}
