import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helper-profie-status',
  templateUrl: './helper-profie-status.component.html',
  styleUrls: ['./helper-profie-status.component.css']
})
export class HelperProfieStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
