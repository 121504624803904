import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../services/validation.service';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.css']
})
export class CustomerLoginComponent implements OnInit {

  public loginFormGroup: FormGroup;

  constructor(
    private formBuider: FormBuilder,
    private validationService: ValidationService,
  ) { }

  ngOnInit() {
    this.createLoginForm();
  }

  createLoginForm(): void {
    this.loginFormGroup = this.formBuider.group({
      email: ['', [Validators.required, this.validationService.emailValidator, Validators.maxLength(100)]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit(form) {
    this.validationService.setForm(this.loginFormGroup);
    if(this.validationService.validate()){
      this.validationService.removeErrors();
    }else{
      this.validationService.removeErrors();
      this.validationService.displayErrors();
    }
  }

}
