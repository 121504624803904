import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../settings/constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public baseUrl: string = Constant.API_URL;

  constructor(
    private http: HttpClient
  ) { }

  /**
     * Get data using Rest Api GET Method
     * @param string urlString Url End Point
     */
  getData(urlString: string) {
    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.get(apiUrl).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Get data using Rest Api GET Method
   * @param string urlString Url End Point
   */
  getExternalData(urlString: string) {
    let apiUrl = urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.get(apiUrl).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Post data using Rest Api POST Method
   * @param string urlString Url End Point
   * @param object data
   */
  postData(urlString: string, data: object) {
    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.post(apiUrl, data, httpOptions).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }
  /**
   * Post data using External api
   * @param string urlString Url End Point
   * @param object data
   */
  postExternalApi(urlString: string, data: object) {
    let apiUrl = urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.post(apiUrl, data, httpOptions).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Post data & file using Rest Api POST Method
   * @param string urlString Url End Point
   * @param object data
   */
  postDataWithAttachment(urlString: string, data: object) {
    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.post(apiUrl, data).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Post data using Rest Api PUT Method
   * @param string urlString Url End Point
   * @param object data
   */
  putData(urlString: string, data: object) {
    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.put(apiUrl, data, httpOptions).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Delete data using Rest Api Delete Method
   * @param string urlString Url End Point
   */
  deleteData(urlString: string) {
    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.delete(apiUrl).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  

}
