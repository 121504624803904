import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { AppComponent } from '../../app.component';
import { ApiService } from 'src/app/services/api.service';
import { Constant } from '../../settings/constants';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public isEnglish: boolean = true;
  token:any;
  email:any

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    public app: AppComponent,
    private apiService: ApiService,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute
  ) { 
    this.activeRoute.queryParams.subscribe(params => {
      this.token = params['token']
      this.email = params['email']
    })
  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30), this.validationService.passwordCriteria()]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]]
    },
      {
        validator: this.validationService.matchValue(
          "password",
          "confirmPassword"
        )

      })
  }
  saveFormDisabled = true;
  personalInformationChange(fieldName: string): void {
    debugger
    this.validationService.setForm(this.resetPasswordForm);
    if (this.validationService.validate()) {
      this.saveFormDisabled = false;
      this.validationService.removeError(fieldName);
    } else {
      this.validationService.removeErrors();
      if (this.resetPasswordForm.get(fieldName).status == "INVALID") {
        this.validationService.displayErrors('customerProfileFrom', fieldName);
      } else {
        this.validationService.removeError(fieldName);
      }
      this.saveFormDisabled = true;
    }
  }
  onChange(field = null) {
    this.validationService.removeErrors(field);
  }
   /** Switch Language */
   switchLanguage(isEnglish) {
    localStorage.setItem('Language', isEnglish);
    if (isEnglish) {
      this.router.navigateByUrl('/reset-password');
    } else {
      this.router.navigateByUrl('/reset-password-dk');
    }
  }
  onSubmit() {
    this.validationService.setForm(this.resetPasswordForm);
    if (this.validationService.validate()) {
      var data = {
        password: this.resetPasswordForm.get("password").value,
        email:this.email,
        token:this.token
      }
      this.app.spinner.show();
      this.apiService
        .postDataWithAttachment(Constant.SLUGS["resetpassword"], data)
        .then(
          response => {
            this.app.spinner.hide();
            if (response["resCode"] == 200) {
              this.resetPasswordForm.reset();
              this.toastr.success(response["resMsg"], "", {
                timeOut: Constant.ERROR_TIMEOUT,
                positionClass: Constant.MESSAGE_POSITION,
                closeButton: true
              });
              this.router.navigateByUrl('/login');
            } else {
              this.toastr.warning(response["resMsg"], "", {
                timeOut: Constant.ERROR_TIMEOUT,
                positionClass: Constant.MESSAGE_POSITION,
                closeButton: true
              });
            }
          },
          err => {
            // console.log("update profile err", err);
            this.app.spinner.hide();
            this.toastr.error(
              "Problem in fetching the data. Please try again.",
              "",
              {
                timeOut: Constant.ERROR_TIMEOUT,
                positionClass: Constant.MESSAGE_POSITION,
                closeButton: true
              }
            );
          }
        );
    } else {
      this.validationService.removeErrors();
      this.validationService.displayErrors("profileFrom");
      this.validationService.setFoucsOfForm(this.resetPasswordForm.controls);
    }
  }
}
