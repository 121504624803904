import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerVisibilityService, Spinkit } from 'ng-http-loader';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  public spinkit = Spinkit;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public spinner: SpinnerVisibilityService
  ) {
    translate.addLangs(['en', 'dk']);
    translate.setDefaultLang('dk');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|dk/) ? browserLang : 'en');
    // Subscribe navigation events
    router.events.subscribe((event: RouterEvent) => {
      this._navigationInterceptor(event);
    });
  }

  /**
   * If require anything so can manage between navigation start to end
   * @param event 
   */
  _navigationInterceptor(event: RouterEvent): void {

    if (event instanceof NavigationStart) {
      this.spinner.show();
    }

    if (event instanceof NavigationEnd) {
      this.spinner.hide();
    }

    if (event instanceof NavigationCancel) {
      this.spinner.hide();
    }

    if (event instanceof NavigationError) {
      this.spinner.hide();
    }
  }

}