import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photo-approved-requirement-dk',
  templateUrl: './photo-approved-requirement-dk.component.html',
  styleUrls: ['../photo-approved-requirement/photo-approved-requirement.component.css']
})
export class PhotoApprovedRequirementDKComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
