import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { AppComponent } from '../../app.component';
import { ApiService } from 'src/app/services/api.service';
import { Constant } from '../../settings/constants';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forgot-password-dk',
  templateUrl: './forgot-password-dk.component.html',
  styleUrls: ['../forgot-password/forgot-password.component.css']
})
export class ForgotPasswordDkComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public isEnglish: boolean = false;
  successMessage: string;
  errorMessage: string;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    public app: AppComponent,
    private apiService: ApiService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, this.validationService.emailValidator, Validators.maxLength(100)]],
    })
  }

  onChange(field = null) {
    this.validationService.removeErrors(field);
  }

  saveFormDisabled = true;
  checkValidation(fieldName: string): void {
    this.validationService.setForm(this.forgotPasswordForm);
    if (this.validationService.validate()) {
      this.saveFormDisabled = false;
      this.validationService.removeError(fieldName);
    } else {
      this.validationService.removeErrors();
      if (this.forgotPasswordForm.get(fieldName).status == "INVALID") {
        this.validationService.displayErrors('customerProfileFrom', fieldName);
      } else {
        this.validationService.removeError(fieldName);
      }
      this.saveFormDisabled = true;
    }
  }
  /** Switch Language */
  switchLanguage(isEnglish) {
    localStorage.setItem('Language', isEnglish);
    if (isEnglish) {
      this.router.navigateByUrl('/forgot-password');
    } else {
      this.router.navigateByUrl('/forgot-password-dk');
    }
  }
  onSubmit() {
    this.apiService.getData(Constant.SLUGS['isEmailExist'] + '/' + this.forgotPasswordForm.controls['email'].value).then(
      response => {
        if (response['resCode'] == 200) {
          this.validationService.setForm(this.forgotPasswordForm);
          if (this.validationService.validate()) {
            var data = {
              email: this.forgotPasswordForm.controls['email'].value,
              lang: 'dk'
            }
            this.app.spinner.show();
            this.apiService.postData(Constant.SLUGS['forgetpassword'], data).then(
              response => {
                if (response['resCode'] == 200) {
                  this.app.spinner.hide();
                  this.successMessage = "Vi har med succes sendt link til nyt kodeord til dig";
                  this.errorMessage = null;
                  //this.toastr.success("Successfully sent Reset password Link.", "Reset Password");
                }
                else {
                  this.successMessage = null;
                  this.toastr.error('Problemet med at hente dataene. Prøv igen.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
                }
              },
              err => {
                this.successMessage = null;
                console.log('forgot password err', err);
                this.app.spinner.hide();
                this.toastr.error('Problemet med at hente dataene. Prøv igen.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
              }
            );
          } else {
            this.successMessage = null;
            this.validationService.removeErrors();
            this.validationService.displayErrors();
          }
        }
        else {
          this.successMessage = null;
          this.errorMessage = "Denne email er ikke registeret i vores system";
          // this.toastr.error('Denne email er ikke registeret i vores system', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
        }
      },
      err => {
        this.app.spinner.hide();
        console.log('email checking err', err)
      }
    );
  }
}
