import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Constant } from '../../settings/constants';
import { ApiService } from '../../services/api.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
    public app: AppComponent
  ) { }

  ngOnInit() {
    
    this.apiService.getData(Constant.SLUGS['logout']).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          //this.toastr.success(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          // Store value for login
          localStorage.removeItem('authToken');
          localStorage.removeItem("userId");
          localStorage.removeItem("userName");
          localStorage.removeItem("role");
          localStorage.removeItem("isSocialLogin");
          localStorage.removeItem("selected_bookie");
          localStorage.removeItem("passFormGroup");
          localStorage.removeItem("address_group");
          localStorage.removeItem("AreaDetails");
          localStorage.removeItem("condition_group");
          localStorage.removeItem("selected_areas");
          localStorage.removeItem("selected_helper");
          
          this.router.navigateByUrl('/');
        } else {
          this.toastr.warning("Logout failed. Please try again.", '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          this.router.navigateByUrl('/helper/profile00');
        }
      },
      err => {
        console.log('logout err', err);
        this.app.spinner.hide();
        this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
      }
    );
  }

}
