import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { ValidationService } from '../../../services/validation.service';
import { Constant } from '../../../settings/constants';
import { ApiService } from '../../../services/api.service';
import { AppComponent } from '../../../app.component';
import { environment } from 'src/environments/environment';
import { MatStepper } from '@angular/material/stepper/typings/public-api';
declare var $: any;
declare function initTooltip(): any;
declare function sendMessage(apiUrl, apiKey, messageObj, callback): any;
declare function initMap(callback): any;
declare function removeFeatureFromMap(callback): any;
import { WebcamImage } from 'ngx-webcam';
import { Dimensions, ImageCroppedEvent, ImageTransform, base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'app-signup01-join',
  templateUrl: './signup01-join.component.html',
  styleUrls: ['./signup01-join.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: false }
  }]
})
export class Signup01JoinComponent implements OnInit {

  public isMapSelected: boolean = false;
  public conditionFormGroup: FormGroup;
  public personalFormGroup: FormGroup;
  public addressFormGroup: FormGroup;
  public fbFormGroup: FormGroup;
  public areaFormGroup: FormGroup;
  public contractFormGroup: FormGroup;
  public confirmFormGroup: FormGroup;

  public isEnglish: boolean = true;

  public isStep1Disable: boolean = true;
  public isStep2Disable: boolean = true;
  public isStep3Disable: boolean = true;
  public isStep4Disable: boolean = true;
  public isStep5Disable: boolean = true;
  public isStep6Disable: boolean = true;
  public isStep7Disable: boolean = true;
  public isStep8Disable: boolean = true;
  public isCprDisable: boolean = false;
  public isCvrDisable: boolean = false;
  public cityName: string = '';
  public fbLoginEnable: boolean = true;
  public smsCode: number;

  public selectedLanguage: string = 'en'
  public regions: any = [];
  public cities: any = [];
  public areas: any = [];
  public selectedAreas: any = [];

  public webcamImage: WebcamImage = null;
  public webcamImageSenior: WebcamImage = null;
  public webcamImageCleaner: WebcamImage = null;
  constructor(
    private _formBuilder: FormBuilder,
    public translate: TranslateService,
    private validationService: ValidationService,
    private apiService: ApiService,
    private toastr: ToastrService,
    public app: AppComponent,
    private authService: AuthService,
    private router: Router
  ) {
    translate.addLangs(['en', 'dk']);
    translate.setDefaultLang('dk');
    const browserLang = translate.getBrowserLang();
    console.log('lang', browserLang);
    translate.use(browserLang.match(/en|dk/) ? browserLang : 'en');
  }


  ngOnInit() {
    this.createJoinForms();
    // var isEnglishLanguage = localStorage.getItem('Language');
    // if (isEnglishLanguage == "false") {
    //   this.router.navigateByUrl('/helper/signup01-join-dk');
    // }
  }

  mapAreas = []
  initalizationMap() {
    this.isMapSelected = !this.isMapSelected;
    var self = this;
    self.mapAreas = [];
    initMap(function (res) {
      var arr = self.mapAreas.filter(function (resposne) {
        return resposne.postal_code == res.postal_code;
      });
      if (arr.length == 0) {
        self.mapAreas.push(res);
        self.isStep6Disable = false;
      } else {
        $.each(self.mapAreas, function (i) {
          if (self.mapAreas[i] === arr[0]) {
            self.mapAreas.splice(i, 1);
            return false;
          }
        });
      }
    });
  }

  /**
   * Create join stepper forms
   */
  status = "2";
  seniorstatus = "2";
  cleanerstatus = "2";
  notification = "0";
  reminder="2";
  createJoinForms(): void {
    // Join step 1 form
    this.conditionFormGroup = this._formBuilder.group({
      yes18: [false, [Validators.required]],
      haveCprNumber: [false, [Validators.required]],
      haveDanishBankAc: [false, [Validators.required]],
      havePermission: [false, [Validators.required]],
      haveCleanRecord: [false, [Validators.required]],
      acceptedTerms: [false, [Validators.required]]
    });

    // Join step 2 form
    this.personalFormGroup = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      lastName: [''],
      email: ['', [Validators.required, this.validationService.emailValidator, Validators.maxLength(100)]],
      cpr: ['', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      cvr: ['', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      streetLine: ['', [Validators.required, Validators.minLength(3)]],
      addressNear: ["", [Validators.minLength(1), Validators.maxLength(4), Validators.pattern('^[a-zA-Z0-9]*$')]],
      addressFloor: ["", [Validators.minLength(1), Validators.maxLength(20)]],
      postalCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      city: ['', [Validators.required, Validators.minLength(3)]],
      phone_code: ['+45'],
      phone: ['', [Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30), this.validationService.passwordCriteria()]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]],
    }, {
      validator: this.validationService.matchValue('password', 'confirmPassword')
    });

    // Join step 3 form
    this.addressFormGroup = this._formBuilder.group({
      userBio: [''],
      senioruserBio: [""],
      cleaneruserBio: [''],
      regNo: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      acNo: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(10), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      image: [""],
      seniorimage: [""],
      cleanerimage: [""],
      status: [""],
      seniorstatus: [""],
      cleanerstatus: [""],
      hourlyRate: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      // emailReminder: [false, [Validators.required]],
      // smsReminder: [false, [Validators.required]],
      reminder: ["2"],
      notification: ["0"]
    });

    // Join step 5 form
    this.fbFormGroup = this._formBuilder.group({
      fbUserName: [''],
      fbUserId: ['']
    });

    // Join step 6 form
    this.areaFormGroup = this._formBuilder.group({
      region: [''],
      regionCity: ['']
    });
    this.contractFormGroup = this._formBuilder.group({
      signedContract: [false, [Validators.required]]
    });

    this.confirmFormGroup = this._formBuilder.group({
      smsCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });

    // Get regions
    this.getRegion();
  }

  /**
   * If all checkboxes are checked so next button will be enabled
   */
  checkValue(): void {
    if (this.conditionFormGroup.get('yes18').value && this.conditionFormGroup.get('haveCprNumber').value && this.conditionFormGroup.get('haveDanishBankAc').value && this.conditionFormGroup.get('havePermission').value && this.conditionFormGroup.get('haveCleanRecord').value && this.conditionFormGroup.get('acceptedTerms').value) {
      this.isStep1Disable = false;
    } else {
      this.isStep1Disable = true;
    }
  }

  public imgURL: any;
  public fileToUpload: File = null;
  public imageErr: boolean = false;
  handleFileInput(files: FileList) {
    if (files.item(0)) {
      this.onChange("image");
      if (files.item(0).size < 5242880) {
        this.addressFormGroup.controls["image"].setErrors(null);
        this.fileToUpload = files.item(0);
        this.imageErr = false;
        var reader = new FileReader();
        reader.readAsDataURL(this.fileToUpload);
        reader.onload = (_event) => {
          this.imgURL = reader.result;
        }
      } else {
        this.addressFormGroup.controls["image"].setErrors({
          bigImage: { maxSize: "2MB" }
        });
        this.toastr.warning(
          "Please select image with size less than 2MB.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
        this.imageErr = true;
      }
    } else {
      this.addressFormGroup.controls["image"].setErrors(null);
      this.imageErr = false;
    }
  }

  public seniorimgURL: any;
  public seniorFileToUpload: File = null;
  public seniorImageErr: boolean = false;
  handleSeniorFileInput(files: FileList) {
    if (files.item(0)) {
      this.onChange("seniorimage");
      if (files.item(0).size < 5242880) {
        this.addressFormGroup.controls["seniorimage"].setErrors(null);
        this.seniorFileToUpload = files.item(0);
        this.seniorImageErr = false;
        var reader = new FileReader();
        reader.readAsDataURL(this.seniorFileToUpload);
        reader.onload = (_event) => {
          this.seniorimgURL = reader.result;
        }
      } else {
        this.addressFormGroup.controls["seniorimage"].setErrors({
          bigImage: { maxSize: "2MB" }
        });
        this.toastr.warning(
          "Please select image with size less than 2MB.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
        this.seniorImageErr = true;
      }
    } else {
      this.addressFormGroup.controls["seniorimage"].setErrors(null);
      this.seniorImageErr = false;
    }
  }

  public cleanerimgURL: any;
  public cleanerFileToUpload: File = null;
  public cleanerImageErr: boolean = false;
  handleCleanerFileInput(files: FileList) {

    if (files.item(0)) {
      this.onChange("cleanerimage");
      if (files.item(0).size < 5242880) {
        this.addressFormGroup.controls["cleanerimage"].setErrors(null);
        this.cleanerFileToUpload = files.item(0);
        this.cleanerImageErr = false;
        var reader = new FileReader();
        reader.readAsDataURL(this.cleanerFileToUpload);
        reader.onload = (_event) => {
          this.cleanerimgURL = reader.result;
        }
      } else {
        this.addressFormGroup.controls["cleanerimage"].setErrors({
          bigImage: { maxSize: "2MB" }
        });
        this.toastr.warning(
          "Vælg et billede med en størrelse mindre end 2 MB.",
          "",
          {
            timeOut: Constant.ERROR_TIMEOUT,
            positionClass: Constant.MESSAGE_POSITION,
            closeButton: true
          }
        );
        this.cleanerImageErr = true;
      }
    } else {
      this.addressFormGroup.controls["cleanerimage"].setErrors(null);
      this.cleanerImageErr = false;
    }
  }

  /**
   * Check personal info is vaid or not
   */
  personalInfoChange(fieldName: string): void {
    let isEmailValid = true;
    // Check email is existing or not
    if (fieldName == 'email') {
      this.app.spinner.show();
      let email = this.personalFormGroup.get('email').value;
      this.apiService.getData(Constant.SLUGS['isEmailExist'] + '/' + email).then(
        response => {
          this.app.spinner.hide();
          if (response['resCode'] == 200) {
            isEmailValid = false;
            document.getElementsByName('email')[0].style.border = '2px solid #e01e5c';
            document.getElementsByName('email')[0].style.borderRadius = '5px';
            this.toastr.warning("This email address has already been used. Please login or use another email address", '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          }
        },
        err => {
          this.app.spinner.hide();
          console.log('email checking err', err)
        }
      );
    }
    if (isEmailValid) {
      this.validationService.setForm(this.personalFormGroup);
      if (this.validationService.validate()) {
        this.validationService.removeErrors();
        this.validationService.removeError(fieldName);

        document.getElementsByName('cvr')[0].style.border = '';
        document.getElementsByName('cvr')[0].style.borderRadius = '';

        document.getElementsByName('cpr')[0].style.border = '';
        document.getElementsByName('cpr')[0].style.borderRadius = '';

        if (this.personalFormGroup.get('cpr').value.length == 10 || this.personalFormGroup.get('cvr').value.length == 8) {
          this.isStep2Disable = false;
          this.validationService.removeError(fieldName);
        } else {
          this.isStep2Disable = true;
          if (this.personalFormGroup.get('cvr').value.length == 0 && this.personalFormGroup.get('cpr').value.length == 0) {
            if (this.personalFormGroup.get('cvr').value.length == 0) {
              document.getElementsByName('cvr')[0].style.border = '2px solid red';
              document.getElementsByName('cvr')[0].style.borderRadius = '5px';
            }
            else if (this.personalFormGroup.get('cpr').value.length == 0) {
              document.getElementsByName('cpr')[0].style.border = '2px solid red';
              document.getElementsByName('cpr')[0].style.borderRadius = '5px';
            }
          }

          else if ((this.personalFormGroup.get('cvr').value.length > 0 && this.personalFormGroup.get('cvr').value.length < 8)) {
            document.getElementsByName('cvr')[0].style.border = '2px solid red';
            document.getElementsByName('cvr')[0].style.borderRadius = '5px';
          } else if ((this.personalFormGroup.get('cpr').value.length > 0 && this.personalFormGroup.get('cpr').value.length < 10)) {
            document.getElementsByName('cpr')[0].style.border = '2px solid red';
            document.getElementsByName('cpr')[0].style.borderRadius = '5px';
          }
          return;
        }
        if (parseInt(this.addressFormGroup.get('hourlyRate').value) < 120 || parseInt(this.addressFormGroup.get('hourlyRate').value) > 1400) {
          this.validationService.displayErrors('signupform', 'hourlyRate');
          this.isStep2Disable = true;
        } else {
          this.isStep2Disable = false;
        }
      } else {
        this.validationService.removeErrors();
        if (this.personalFormGroup.get(fieldName).status == "INVALID") {
          this.validationService.displayErrors('signupform', fieldName);
        } else {
          this.validationService.removeError(fieldName);
        }
        this.isStep2Disable = true;
      }
    }
  }

  /**
   * Check address info is vaid or not
   */
  addressInfoChange(fieldName: string): void {
    this.validationService.setForm(this.addressFormGroup);
    if (this.validationService.validate()) {
      if (parseInt(this.addressFormGroup.get('hourlyRate').value) < 120 || parseInt(this.addressFormGroup.get('hourlyRate').value) > 1400) {
        this.validationService.displayErrors('signupform', 'hourlyRate');
        this.isStep3Disable = true;
      } else {
        this.isStep3Disable = false;
        this.validationService.removeError(fieldName);
      }
    } else {
      this.validationService.removeErrors();
      if (this.addressFormGroup.get(fieldName).status == "INVALID") {
        this.validationService.displayErrors('signupform', fieldName);
      } else {
        this.validationService.removeError(fieldName);
      }
      this.isStep3Disable = true;
    }
  }


  /**
   * Check password info is vaid or not
   */
  areaInfoChange(fieldName: string): void {
    this.validationService.setForm(this.areaFormGroup);
    if (this.validationService.validate() && this.selectedAreas.length > 0) {
      this.isStep6Disable = false;
    } else {
      this.validationService.removeErrors();
      if (this.areaFormGroup.get(fieldName).status == "INVALID") {
        this.validationService.displayErrors('stepFrom', fieldName);
      }
      this.isStep6Disable = true;
    }
  }

  /**
   * Sign contract
   * Send 6 digit sms on phone number
   */
  signContract(stepper: MatStepper): void {

    let phoneNumber = this.personalFormGroup.get('phone_code').value + this.personalFormGroup.get('phone').value;
    // Generate random 6 digits code
    let code = Math.floor(100000 + Math.random() * 900000);
    // Temporary set fixed till integrate sms api
    // // code = 123456;
    let text = code + " is your code. Thank you, Helper Service.";
    let message = {
      "message": {
        "recipients": phoneNumber,
        "sender": 'Service',
        "message": text
      }
    };

    this.smsCode = code;
    // Integrate sms api
    this.app.spinner.show();
    var self = this;

    sendMessage(environment.smsApi, environment.smsApiKey, message, function (response) {
      if (response.status == 201) {
        self.app.spinner.hide();
        self.isStep7Disable = false;
        stepper.selectedIndex = 5;
        $('#contractModal').modal('show');
      } else if (response.status == 400) {
        self.isStep7Disable = true;
        self.app.spinner.hide();
        var msg = JSON.parse(response.responseText);
        self.toastr.error(msg.message, 'Error');
      }

      else {
        self.contractFormGroup.controls['signedContract'].setValue(false);
        self.isStep7Disable = true;
        self.app.spinner.hide();
        self.toastr.error('Problem in sending the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
      }
    });

    // // let smsApi = "http://api.zumosms.com/v2/message.json?apikey=5557ac25b7c4545e59b7690004c526ea3b2f605a570467b9fa47a8e982453483"//Constant.SMS_API + Constant.SMS_API_KEY;
    // // this.apiService.postExternalApi(smsApi, message).then(
    // //   response => {
    // //     this.app.spinner.hide();
    // //     console.log('sms api response', response)
    // //   },
    // //   err => {
    // //     this.app.spinner.hide();
    // //     this.contractFormGroup.controls['signedContract'].setValue(false);
    // //     this.isStep7Disable = true;
    // //     console.log('sms api response', err);
    // //     this.toastr.error('Problem in sending the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    // //   }
    // // );

  }

  /**
   * Resend code on phone number
   */
  resendCode(stepper: MatStepper): void {
    this.confirmFormGroup.controls['smsCode'].setValue('');
    // Call to resend code
    this.signContract(stepper);
  }

  /**
   * Vefiry sms code which sent to phone number
   */
  verifySMSCode(): void {
    this.validationService.setForm(this.confirmFormGroup);
    if (this.validationService.validate()) {
      if (this.smsCode == this.confirmFormGroup.get('smsCode').value) {
        this.isStep8Disable = false;
      } else {
        this.isStep8Disable = true;
        document.getElementsByName('smsCode')[0].parentElement.parentElement.style.border = '2px solid #e01e5c';
        document.getElementsByName('smsCode')[0].parentElement.parentElement.style.borderRadius = '5px';
      }
    } else {
      this.validationService.removeErrors();
      this.validationService.displayErrors('stepFrom', 'smsCode');
      this.isStep8Disable = true;
    }
  }

  /**
   * Close dialog box
   */
  dialogClose(): void {
    // Refresh value
    this.contractFormGroup.controls['signedContract'].setValue(false);
  }

  /**
   * Remove validation errors
   * @param field 
   */
  onChange(field = null): void {
    //disable cvr
    if (field == 'cpr') {
      if (this.personalFormGroup.get('cpr').value != '') {
        this.personalFormGroup.controls['cvr'].clearValidators();
        //this.personalFormGroup.controls['cvr'].setErrors(null);
        this.personalFormGroup.get('cvr').disable();
      } else {
        this.personalFormGroup.get('cvr').enable();
        //this.personalFormGroup.controls['cvr'].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]);
        //this.personalFormGroup.controls['cvr'].setErrors({ required: true });
      }
    }
    //disable cpr
    if (field == 'cvr') {
      if (this.personalFormGroup.get('cvr').value != '') {
        this.personalFormGroup.controls['cpr'].clearValidators();
        //this.personalFormGroup.controls['cpr'].setErrors(null);
        this.personalFormGroup.get('cpr').disable();
      } else {
        this.personalFormGroup.get('cpr').enable();
        //this.personalFormGroup.controls['cpr'].setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]);
        //this.personalFormGroup.controls['cpr'].setErrors({ required: true });
      }
    }
    this.validationService.removeErrors(field);
  }

  /**
   * Get city name by postal code
   * @param event 
   */
  getCityByPostalCode(event: any): void {
    let postalCode = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getCity'] + '/' + postalCode).then(
      response => {
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          this.cityName = response['data'];
          this.onChange('city');
        } else {
          this.cityName = '';
          this.isStep3Disable = true;
        }
      },
      err => {
        this.app.spinner.hide();
        this.isStep3Disable = true;
        console.log('get city by postal code err', err)
      }
    );
  }

  /**
   * Facebook login
   */
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (userData) => { // on success
        // this will return user data from fb. What you need is a user token which you will send it to the server
        console.log('fb user data', userData)
        let fbName = userData['firstName'] + userData['lastName'];
        let fbId = userData['id'];
        this.fbFormGroup.controls['fbUserName'].setValue(fbName);
        this.fbFormGroup.controls['fbUserId'].setValue(fbId);
        // Now facebook login disabled
        this.fbLoginEnable = false;
      }
    );
  }

  /**
   * Sign out from facebook
   */
  signOutFromFB(): void {
    this.fbFormGroup.controls['fbUserName'].setValue('');
    this.fbFormGroup.controls['fbUserId'].setValue('');
    // Now facebook login enabled
    this.fbLoginEnable = true;
    this.authService.signOut();
  }

  /**
   * Submit form value
   */
  isFinalSave = false;
  onSubmit() {
    console.log('selected areas', this.selectedAreas);
    for (var m = 0; m < this.mapAreas.length; m++) {
      var mapArea = this.mapAreas[m];
      var alreadyData = this.selectedAreas.filter(function (resposne) {
        return resposne.postal_code == mapArea.postal_code;
      });

      if (alreadyData.length == 0) {
        var obj = {
          area_name: mapArea.area_name,
          id: 0,
          postal_code: mapArea.postal_code
        }
        this.selectedAreas.push(obj);
      }
    }
    let cpr, cvr, phoneNumber;
    cpr = (this.personalFormGroup.get('cpr').value) ? this.personalFormGroup.get('cpr').value : '';
    cvr = (this.personalFormGroup.get('cvr').value) ? this.personalFormGroup.get('cvr').value : '';
    let notification_email = "false";
    let notification_sms = "false";

    if (this.addressFormGroup.get("notification").value == '0') {
      notification_email = "true";
      notification_sms = "false";
    }
    else if (this.addressFormGroup.get("notification").value == '1') {
      notification_email = "false";
      notification_sms = "true";
    }

    let reminder_email = "false";
    let reminder_sms = "false";
    let reminder_nothanks = "false";

    if (this.addressFormGroup.get("reminder").value == '0') {
      reminder_email = "true";
      reminder_sms = "false";
      reminder_nothanks = "false";
    }
    else if (this.addressFormGroup.get("reminder").value == '1') {
      reminder_email = "false";
      reminder_sms = "true";
      reminder_nothanks = "false";
    }
    else if (this.addressFormGroup.get("reminder").value == '2') {
      reminder_email = "false";
      reminder_sms = "false";
      reminder_nothanks = "true";
    }
    // Concate phone code
    phoneNumber = this.personalFormGroup.get('phone_code').value + this.personalFormGroup.get('phone').value;
    var data = {
      yes_18: this.conditionFormGroup.get('yes18').value,
      have_cpr_number: this.conditionFormGroup.get('haveCprNumber').value,
      have_danish_bank_ac: this.conditionFormGroup.get('haveDanishBankAc').value,
      have_permission: this.conditionFormGroup.get('havePermission').value,
      have_clean_record: this.conditionFormGroup.get('haveCleanRecord').value,
      accepted_terms: this.conditionFormGroup.get('acceptedTerms').value,
      first_name: this.personalFormGroup.get('firstName').value,
      last_name: this.personalFormGroup.get('lastName').value,
      email: this.personalFormGroup.get('email').value,
      reg_no: this.addressFormGroup.get('regNo').value,
      ac_no: this.addressFormGroup.get('acNo').value,
      cpr: cpr,
      cvr: cvr,
      phone: phoneNumber,
      user_bio: this.addressFormGroup.get('userBio').value,
      password: this.personalFormGroup.get('password').value,
      confirm_password: this.personalFormGroup.get('confirmPassword').value,
      facebook_name: this.fbFormGroup.get('fbUserName').value,
      facebook_id: this.fbFormGroup.get('fbUserId').value,
      street_line: this.personalFormGroup.get('streetLine').value,
      postal_code: this.personalFormGroup.get('postalCode').value,
      city: this.personalFormGroup.get('city').value,
      hourly_rate: this.addressFormGroup.get('hourlyRate').value,
      signed_contract: this.contractFormGroup.get('signedContract').value,
      selected_areas: this.selectedAreas,
      role: 'helper',
      status: this.status,
      address_near: this.personalFormGroup.get('addressNear').value,
      address_floor: this.personalFormGroup.get('addressFloor').value,
      helping_seniors_status: this.seniorstatus,
      helping_seniros_description: this.addressFormGroup.get('senioruserBio').value,
      reminder_email: reminder_email,//this.addressFormGroup.get('emailReminder').value,
      reminder_sms: reminder_sms, //this.addressFormGroup.get('smsReminder').value,
      reminder_nothanks: reminder_nothanks,
      notification_email: notification_email,
      notification_sms: notification_sms,
      helping_cleaner_status: this.cleanerstatus,
      helping_cleaner_description: this.addressFormGroup.get('cleaneruserBio').value,
    }
    this.app.spinner.show();
    this.apiService.postData(Constant.SLUGS['register'], data).then(
      response => {
        console.log('registration success', response['resMsg']);
        this.app.spinner.hide();
        if (response['resCode'] == 200) {
          $('#contractModal').modal('hide');
          this.toastr.success(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          // Store value for login
          let userName = this.personalFormGroup.get('firstName').value + ' ' + this.personalFormGroup.get('lastName').value;
          // Store value for login
          localStorage.setItem('authToken', response['data']['token']);
          localStorage.setItem("userId", response['data']['userId']);
          localStorage.setItem("userName", userName);
          localStorage.setItem("role", "helper");
          this.isFinalSave = true;
          if (this.myFileUser) {
            this.uploadImage();
          }
          if (this.myFileUserSenior) {
            this.uploadSeniorImage();
          }
          if (this.myFileCleaner) {
            this.uploadCleanerImage();
          }

        } else {
          this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
        }
      },
      err => {
        console.log('register err', err);
        this.app.spinner.hide();
        this.toastr.error('Problem in fetching the data. Please try again.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
      }
    );
  }
  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }
  myFileUser: Blob;
  uploadImage() {
    if (this.imageErr) {
      this.toastr.warning('Please select image with size less than 2MB.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    } else {
      // Build object
      let fileData: FormData = new FormData();
      this.myFileUser = this.dataURItoBlob(this.imgURL);
      fileData.append('image', this.myFileUser);
      fileData.append('user_id', localStorage.getItem('userId'));
      this.app.spinner.show();
      this.apiService.postDataWithAttachment(Constant.SLUGS['updateProfileImage'], fileData).then(
        response => {
          this.app.spinner.hide();
          if (response['resCode'] == 200) {
            this.isFinalSave = true;
            console.log('success gardener');
            ////this.toastr.success(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
            //this.router.navigateByUrl('/helper/profile00');
          } else {
            console.log('failed gardener');
            ////this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
            //this.router.navigateByUrl('/helper/profile00');
          }
        },
        err => {
          console.log('failed gardener');
          this.app.spinner.hide();
          ////this.toastr.error('Error while uploading image.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          //this.router.navigateByUrl('/helper/profile00');
        }
      );
    }
  }

  myFileUserSenior: Blob;
  uploadSeniorImage() {
    if (this.seniorImageErr) {
      this.toastr.warning('Please select image with size less than 2MB.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    } else {
      // Build object
      let fileData: FormData = new FormData();
      this.myFileUserSenior = this.dataURItoBlob(this.seniorimgURL);
      fileData.append('image', this.myFileUserSenior);
      fileData.append('user_id', localStorage.getItem('userId'));
      this.app.spinner.show();
      this.apiService.postDataWithAttachment('Please select image with size less than 2MB.', fileData).then(
        response => {
          this.app.spinner.hide();
          if (response['resCode'] == 200) {
            this.isFinalSave = true;
            console.log('success senior');
            ////this.toastr.success(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
            // this.router.navigateByUrl('/helper/profile00');
          } else {
            console.log('failed senior');
            ////this.toastr.warning(response['resMsg'], '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
            ////this.router.navigateByUrl('/helper/profile00');
          }
        },
        err => {
          console.log('failed senior');
          this.app.spinner.hide();
          ////this.toastr.error('Error while uploading image.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
          ////this.router.navigateByUrl('/helper/profile00');
        }
      );
    }
  }

  myFileCleaner: Blob;
  uploadCleanerImage() {
    if (this.cleanerImageErr) {
      this.toastr.warning('Please select image with size less than 2MB.', '', { timeOut: Constant.ERROR_TIMEOUT, positionClass: Constant.MESSAGE_POSITION, closeButton: true });
    } else {
      // Build object
      let fileData: FormData = new FormData();
      this.myFileCleaner = this.dataURItoBlob(this.cleanerimgURL);
      fileData.append('image', this.myFileCleaner);
      fileData.append('user_id', localStorage.getItem('userId'));
      this.app.spinner.show();
      this.apiService.postDataWithAttachment('update-helping-cleaning-image', fileData).then(
        response => {
          this.app.spinner.hide();
          if (response['resCode'] == 200) {
            this.isFinalSave = true;
            console.log('success cleaner');
          }
          else {
            console.log('failed cleaner');
          }
        },
        err => {
          console.log('failed cleaner');
          this.app.spinner.hide();
        }
      );
    }
  }
  /**
   * Get areas of city
   * @param event 
   */
  getAreaName(event: any): void {
    let cityName = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getAreas'] + '/' + cityName).then(
      response => {
        this.app.spinner.hide();
        console.log('area res', response)
        if (response['resCode'] == 200) {
          this.areas = response['data'];
          console.log('areas', this.areas)
        }
      },
      err => {
        this.app.spinner.hide();
        console.log('area err', err)
      }
    );
  }


  /**
   * Change language
   */
  changeLanguage() {
    this.selectedLanguage = (this.selectedLanguage == 'en') ? 'dk' : 'en';
    this.translate.use(this.selectedLanguage);
  }

  /**
   * Get regions
   */
  getRegion(): void {
    // Empty selected areas
    this.selectedAreas = [];
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getRegions']).then(
      response => {
        this.app.spinner.hide();
        this.regions = response;
      },
      err => {
        this.app.spinner.hide();
        console.log('region err', err)
      }
    );
  }

  /**
   * Get cities of region
   * @param event 
   */
  getCities(event: any): void {
    // Empty selected areas
    this.selectedAreas = [];
    this.areas = [];
    let regionName = event.target.value;
    this.app.spinner.show();
    this.apiService.getData(Constant.SLUGS['getCities'] + '/' + regionName).then(
      response => {
        this.app.spinner.hide();
        this.cities = response;
      },
      err => {
        this.app.spinner.hide();
        console.log('cities err', err)
      }
    );
  }

  /**
   * Choose all areas of city
   */
  chooseAllAreas(): void {
    // Empty selected areas
    this.selectedAreas = [];
    this.areas.forEach(item => {
      this.selectedAreas.push(item);
    });
    // // this.mapAreas.forEach(item => {
    // //   this.selectedAreas.push(item);
    // // });
    console.log('selected area', this.selectedAreas)
    this.isStep6Disable = false;
  }

  totalLength() {
    return this.areas.length;
    // + this.mapAreas.length;
  }
  /**
   * Remove all areas of city
   */
  removeAllAreas(): void {
    this.selectedAreas = [];
    if (this.mapAreas.length == 0 && this.selectedAreas.length == 0) {
      this.isStep6Disable = true;
    } else {
      this.isStep6Disable = false;
    }
  }
  /**
   * Choose area
   * @param area 
   */
  addArea(area): void {
    if (this.selectedAreas.length > 0) {
      let keepContinue = true;
      this.selectedAreas.forEach(item => {
        if (keepContinue) {
          // If area has alread added
          if (item == area) {
            keepContinue = false;
          } else {
            this.selectedAreas.push(area);
            keepContinue = false;
          }
        }
      });
    } else {
      this.selectedAreas.push(area);
    }
    this.isStep6Disable = false;
    console.log('added area', this.selectedAreas)
  }

  removeAreaFromMap(mapArea) {
    removeFeatureFromMap(mapArea.feature);
    var self = this;
    $.each(this.mapAreas, function (i) {
      if (this.postal_code === mapArea.postal_code) {
        self.mapAreas.splice(i, 1);
        return false;
      }
    });

    if (self.mapAreas.length == 0 && self.selectedAreas.length == 0) {
      self.isStep6Disable = true;
    } else {
      self.isStep6Disable = false;
    }
  }

  /**
   * Remove area from selection
   * @param area 
   */
  removeArea(area): void {
    this.selectedAreas.forEach((item, index) => {
      let keepContinue = true;
      if (keepContinue) {
        // Remove selected area
        if (item == area) {
          this.selectedAreas.splice(index, 1);
          keepContinue = false;
        }
      }
    });
    this.isStep6Disable = (this.selectedAreas.length > 0) ? false : true;
    console.log('remove area', this.selectedAreas)
  }

  /**
   * Check is area already selected or not
   * @param area 
   */
  isAreaChoosed(area): boolean {
    let keepContinue = false;
    this.selectedAreas.forEach(item => {
      if (!keepContinue) {
        if (item == area) {
          keepContinue = true;
        }
      }
    });
    return keepContinue;
  }

  /** Switch Language */
  switchLanguage(isEnglish) {
    localStorage.setItem('Language', isEnglish);
    if (isEnglish) {
      this.router.navigateByUrl('/helper/signup01-join');
    } else {
      this.router.navigateByUrl('/helper/signup01-join-dk');
    }
  }

  mapLoaded = false;
  public onStepChange(event: any): void {
    if (event.selectedIndex == 3 && this.mapLoaded == false) {
      this.mapLoaded = true;
      this.initalizationMap();
    }
    if ($('[data-toggle="tooltip"]').length > 0) {
      initTooltip();
    }
  }

  public prebentClick() {
    return false;
  }

  isMessageUser: any
  isMessageSenior:any
  isMessageCleaner: any
  validDescriptionOnActive(jobtype) {
    if (jobtype == 'user') {
      if (this.status == '1') {
        if (this.addressFormGroup.get('userBio').value == null || this.addressFormGroup.get('userBio').value == "") {
          this.isStep3Disable = true;
          this.addressFormGroup.controls['userBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
          this.isMessageUser = "Profile description. Tell the customer why they should choose you for gardening.";
        }
        else {
          this.isMessageUser = "";
          this.addressFormGroup.controls['userBio'].clearValidators();
        }
      }
      if (this.status == '2') {
        this.addressFormGroup.controls['userBio'].setErrors(null);
        this.addressFormGroup.controls['userBio'].clearValidators();
        this.addressFormGroup.controls['userBio'].updateValueAndValidity();
        if (this.addressFormGroup.invalid == false) {
          this.isStep3Disable = false;
        }
      }
    }
    if (jobtype == 'senior') {
      if (this.seniorstatus == '1') {
        if (this.addressFormGroup.get('senioruserBio').value == null || this.addressFormGroup.get('senioruserBio').value == "") {
          this.isStep3Disable = true;
          this.addressFormGroup.controls['senioruserBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
          this.isMessageSenior = "Profile description. Tell the customer why they should choose you for homecare.";
        }
        else {
          this.isMessageSenior = "";
          this.addressFormGroup.controls['senioruserBio'].clearValidators();
        }
      }
      if (this.seniorstatus == '2') {
        this.addressFormGroup.controls['senioruserBio'].setErrors(null);
        this.addressFormGroup.controls['senioruserBio'].clearValidators();
        this.addressFormGroup.controls['senioruserBio'].updateValueAndValidity();
        if (this.addressFormGroup.invalid == false) {
          this.isStep3Disable = false;
        }
      }
    }
    if (jobtype == 'cleaner') {
      if (this.cleanerstatus == '1') {
        if (this.addressFormGroup.get('cleaneruserBio').value == null || this.addressFormGroup.get('cleaneruserBio').value == "") {
          this.isStep3Disable = true;
          this.addressFormGroup.controls['cleaneruserBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
          this.isMessageCleaner = "Profile description. Tell the customers why they should choose you for cleaning.";
        }
        else {
          this.isMessageCleaner = "";
          this.addressFormGroup.controls['cleaneruserBio'].clearValidators();
        }
      }
      if (this.cleanerstatus == '2') {
        this.addressFormGroup.controls['cleaneruserBio'].setErrors(null);
        this.addressFormGroup.controls['cleaneruserBio'].clearValidators();
        this.addressFormGroup.controls['cleaneruserBio'].updateValueAndValidity();
        if (this.addressFormGroup.invalid == false) {
          this.isStep3Disable = false;
        }
      }
    }
  }

  less40userBio: boolean = true;
  less40seniorBio: boolean = true;
  less40cleanerBio: boolean = true;
  getCharCount(event, jobtype) {
    if (jobtype == 1) {
      document.getElementById('counterDisplay').innerHTML = event.target.value.length + ' of 300. Profile description between 40-300 characters.';
      this.isMessageUser = document.getElementById('counterDisplay').innerHTML;
      if (event.target.value.length < 40) {
        this.isStep3Disable = true;
        this.less40userBio = true;
        this.addressFormGroup.controls['userBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
      }
      else {
        this.less40userBio = false;
        this.addressFormGroup.controls['userBio'].clearValidators();
      }
    }
    if (jobtype == 2) {
      document.getElementById('counterDisplay2').innerHTML = event.target.value.length + ' of 300. Profile description between 40-300 characters.';
      this.isMessageSenior = document.getElementById('counterDisplay2').innerHTML;
      if (event.target.value.length < 40) {
        this.addressFormGroup.controls['senioruserBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);
        this.less40seniorBio = true;
        this.isStep3Disable = true;
      }
      else {
        this.less40seniorBio = false;
        this.addressFormGroup.controls['senioruserBio'].clearValidators();
      }
    }

    if (jobtype == 3) {
      document.getElementById('counterDisplay3').innerHTML = event.target.value.length + ' of 300. Profile description between 40-300 characters.';
      this.isMessageCleaner = document.getElementById('counterDisplay3').innerHTML;
      if (event.target.value.length < 40) {
        this.isStep3Disable = true;
        this.less40cleanerBio = true;
        this.addressFormGroup.controls['cleaneruserBio'].setValidators([Validators.required, Validators.minLength(40), Validators.maxLength(300)]);

      }
      else {
        this.less40cleanerBio = false;
        this.addressFormGroup.controls['cleaneruserBio'].clearValidators();
      }
    }
  }

  isstep2btn: boolean = true;
  validNearAddressPhone(type) {
    if (type == "next") {
      if (this.personalFormGroup.get('addressNear').value == null || this.personalFormGroup.get('addressNear').value == "") {
        this.isstep2btn = true;
        this.personalFormGroup.controls['addressNear'].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(4)]);
        document.getElementsByName('addressNear')[0].style.border = '2px solid red';
        document.getElementsByName('addressNear')[0].style.borderRadius = '5px';
      }
      else {
        //this.isStep2Disable = false;
        document.getElementsByName('addressNear')[0].style.border = '';
        document.getElementsByName('addressNear')[0].style.borderRadius = '';
        this.personalFormGroup.controls['addressNear'].clearValidators();
        this.isstep2btn = false;
      }
      this.validPhone();
    }
  }
  validPhone() {
    if (this.personalFormGroup.get('phone').value == null || this.personalFormGroup.get('phone').value == "") {
      this.personalFormGroup.controls['phone'].setValidators([Validators.required]);
      document.getElementsByName('phone')[0].style.border = '2px solid red';
      document.getElementsByName('phone')[0].style.borderRadius = '5px';
      this.isstep2btn = true;
    }
    else {
      this.isStep2Disable = false;
      document.getElementsByName('phone')[0].style.border = '';
      document.getElementsByName('phone')[0].style.borderRadius = '';
      this.personalFormGroup.controls['phone'].clearValidators();
      this.isstep2btn = false;
    }
  }
  //CAMERA CODE - JOB TYPE 1
  profile_pic: any
  public handleImage(webcamImage: WebcamImage) {

    this.webcamImage = webcamImage;
    this.profile_pic = this.webcamImage.imageAsDataUrl;
    var image = this.webcamImage.imageAsDataUrl;
    var image_name = 'Profile';
  }

  iscameraOn: boolean = false
  showUploadContent = false;
  showCameraContent = false;
  @ViewChild('userImage', { static: false }) userImage: any;
  @ViewChild('userImageSenior', { static: false }) userImageSenior: any;
  @ViewChild('userImageCleaner', { static: false }) userImageCleaner: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageSenior: any = '';
  croppedImageCleaner: any = '';
  showCropper = false;
  transform: ImageTransform = {};
  scale = 1;

  cameraOn(type) {
    if (type == 'upload') {
      this.iscameraOn = false;
      this.showUploadContent = true;
      this.showCameraContent = false;
    }
    if (type == 'camera') {
      this.iscameraOn = true;
      this.imgURL = null;
      this.showCameraContent = true;
      this.showUploadContent = false;
    }

  }

  clearCamera() {
    this.iscameraOn = false;
    this.profile_pic = null;
    this.croppedImage = null;
    this.userImage.nativeElement.value = "";
  }

  uploadCameraPhoto() {
    if (this.profile_pic != null) {
      this.imgURL = this.profile_pic;
      this.profile_pic = null;
      $('#cameraOpenModal').modal("hide");
    }
  }
  uploadCropPhoto() {
    if (this.croppedImage != null) {
      this.imgURL = this.croppedImage;
      this.croppedImage = null;
      $('#cameraOpenModal').modal("hide");
      this.imageChangedEvent = '';
      this.userImage.nativeElement.value = "";
      this.scale = 1;
    }
  }
  uploadSeniorCropPhoto() {
    if (this.croppedImageSenior != null) {
      this.seniorimgURL = this.croppedImageSenior;
      this.croppedImageSenior = null;
      $('#cameraOpenModalSenior').modal("hide");
      this.imageChangedEvent = '';
      this.userImageSenior.nativeElement.value = "";
      this.scale = 1;
    }
  }
  uploadCleanerCropPhoto() {
    if (this.croppedImageCleaner != null) {
      this.cleanerimgURL = this.croppedImageCleaner;
      this.croppedImageCleaner = null;
      $('#cameraOpenModalCleaner').modal("hide");
      this.imageChangedEvent = '';
      this.userImageCleaner.nativeElement.value = "";
      this.scale = 1;
    }
  }
  dimenitinData: any;
  //IMAGE CROPPING 
  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
    this.dimenitinData = sourceImageDimensions;
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  fileChangeEventSenior(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
  }
  imageCroppedSenior(event: ImageCroppedEvent) {
    this.croppedImageSenior = event.base64;
    console.log(event, base64ToFile(event.base64));
  }
  imageCroppedCleaner(event: ImageCroppedEvent) {
    this.croppedImageCleaner = event.base64;
    console.log(event, base64ToFile(event.base64));
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }
  // CAMERA CODE - JOB TYPE 2
  profile_pic_senior: any
  public handleImageSenior(webcamImage: WebcamImage) {
    this.webcamImageSenior = webcamImage;
    this.profile_pic_senior = this.webcamImageSenior.imageAsDataUrl;
    var image = this.webcamImageSenior.imageAsDataUrl;
    var image_name = 'Profile';
  }

  iscameraOnSenior: boolean = false
  showUploadContentSenior: boolean = false;
  showCameraContentSenior: boolean = false;
  cameraOnSenior(type) {
    if (type == 'upload') {
      this.iscameraOnSenior = false;
      this.showUploadContentSenior = true;
      this.showCameraContentSenior = false;
    }
    if (type == 'camera') {
      this.iscameraOnSenior = true;
      this.showCameraContentSenior = true;
      this.showUploadContentSenior = false;
    }
  }

  clearCameraSenior() {
    this.iscameraOnSenior = false;
    this.profile_pic_senior = null;
    this.croppedImage = null;
    this.userImageSenior.nativeElement.value = "";
  }

  uploadCameraPhotoSenior() {
    if (this.profile_pic_senior != null) {
      this.seniorimgURL = this.profile_pic_senior;
      this.profile_pic_senior = null;
      $('#cameraOpenModalSenior').modal("hide");
    }
  }

  // CAMERA CODE - JOB TYPE 3
  profile_pic_cleaner: any
  public handleImageCleaner(webcamImage: WebcamImage) {
    this.webcamImageCleaner = webcamImage;
    this.profile_pic_cleaner = this.webcamImageCleaner.imageAsDataUrl;
    var image = this.webcamImageCleaner.imageAsDataUrl;
    var image_name = 'Profile';
  }

  iscameraOnCleaner: boolean = false
  showUploadContentCleaner: boolean = false;
  showCameraContentCleaner: boolean = false;

  cameraOnCleaner(type) {
    if (type == 'upload') {
      this.iscameraOnCleaner = false;
      this.showUploadContentCleaner = true;
      this.showCameraContentCleaner = false;
    }
    if (type == 'camera') {
      this.iscameraOnCleaner = true;
      this.showCameraContentCleaner = true;
      this.showUploadContentCleaner = false;
    }
  }

  clearCameraCleaner() {
    this.iscameraOnCleaner = false;
    this.profile_pic_cleaner = null;
    this.croppedImage = null;
    this.userImageCleaner.nativeElement.value = "";
  }

  uploadCameraPhotoCleaner() {
    if (this.profile_pic_cleaner != null) {
      this.cleanerimgURL = this.profile_pic_cleaner;
      this.profile_pic_cleaner = null;
      $('#cameraOpenModalCleaner').modal("hide");
    }
  }
}

