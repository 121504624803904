import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor() { }

  isUserLoggedIn(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('userId'));
    if (currentUser && currentUser != '') {
      return true;
    } else {
      return false;
    }
  }

  isUserCustomer():boolean{
    let userRole = localStorage.getItem("role");
    return userRole == "client";
  }

}
